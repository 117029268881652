import { InfoCircleFilled } from "@ant-design/icons";
import { Input, Select } from "antd";
import "./StyledField.scss";

const StyledField = ({
  label,
  required,
  type,
  field,
  meta,
  helpers,
  ...props
}) => {
  const error = meta.error && meta.touched;

  const commonProps = {
    className: "input-styled",
    ...field,
    ...props,
    status: error && "error",
    placeholder: props.placeholder || label,
  };

  const handleChange = (value) => {
    helpers.setValue(value);
  };

  const getFieldComponent = () => {
    switch (type) {
      case "select":
        return (
          <Select
            {...commonProps}
            onChange={handleChange}
            onBlur={() => helpers.setTouched(true)}
          />
        );
      case "textarea":
        return <Input.TextArea {...commonProps} />;
      case "password":
        return <Input.Password {...commonProps} />;
      default:
        return <Input {...commonProps} />;
    }
  };

  return (
    <div className="styled-input-container">
      <div className={`label ${required && "required"}`}>{label}</div>
      {getFieldComponent()}
      {error && (
        <span className="form-error-message">
          <InfoCircleFilled className="error-info-icon" />{" "}
          <span>{meta.error}</span>
        </span>
      )}
    </div>
  );
};

export default StyledField;
