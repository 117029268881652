export const reviewPageInfoText = (
  <>
    <div>
      <strong>Segment Looping ON/OFF : </strong> Ctrl + L <br />
      <strong>Play/Pause Video : </strong> Ctrl + Spacebar <br />
      <strong>Mute/Unmute : </strong> Ctrl + M <br />
      <strong>Open/Close Shot Data Drawer : </strong> Shift + Space <br />
      <strong>Select Next Shot Type : </strong> Shift + S <br />
      <strong>Select Previous Shot Type : </strong> Ctrl + Shift + S <br />
      <strong>Select Next Quality : </strong> Shift + Q <br />
      <strong>Select Previous Quality : </strong> Ctrl + Shift + Q <br />
      <strong>Select Next Tightness : </strong> Shift + E <br />
      <strong>Select Previous Tightness : </strong> Ctrl + Shift + E <br />
      <strong>Select Next Speed : </strong> Shift + D <br />
      <strong>Select Previous Speed : </strong> Ctrl + Shift + D <br />
      <strong>Select Next Risk Factor : </strong> Shift + R <br />
      <strong>Select Previous Risk Factor : </strong> Ctrl + Shift + R <br />
      <strong>Select Next Receiver Foot Position : </strong> Shift + F <br />
      <strong>Select Previous Receiver Foot Position : </strong> Ctrl + Shift + F
      <br />
      <strong>Select Next Result : </strong> Shift + Z <br />
      <strong>Select Previous Result : </strong> Ctrl + Shift + Z <br />
      <strong>Select Annotation Span : </strong> Ctrl + Shift + A <br />
    </div>
  </>
);