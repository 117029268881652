import {
  ADD_BULK_SELECT,
  CLEAR_BULK_SELECT,
  DELETE_BULK_SELECT,
  DELETE_MODAL_OPEN,
  SET_BULK_SELECT,
} from "../actionTypes/BulkSelectionType";

const DEFAULT_BULK_SELECT_STATE = {
  is_delete_modal_open: false,
};

export const bulkSelectReducer = (
  state = DEFAULT_BULK_SELECT_STATE,
  action
) => {
  switch (action.type) {
    case ADD_BULK_SELECT:
      return {
        ...state,
        [action.payload.id]: {
          bulkSelect: true,
          values: [
            ...(state[action.payload.id]?.values || []),
            action.payload.value,
          ],
        },
      };
    case DELETE_BULK_SELECT:
      const { id, valueToRemove } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          values:
            state[id]?.values.filter((value) => value !== valueToRemove) || [],
        },
      };
    case CLEAR_BULK_SELECT:
      return {
        ...state,
        [action.payload.id]: {
          bulkSelect: false,
          values: [],
        },
      };
    case DELETE_MODAL_OPEN:
      return {
        ...state,
        is_delete_modal_open: action.payload,
      };
    case SET_BULK_SELECT:
      return {
        ...state,
        [action.payload.id]: {
          bulkSelect: true,
          values: [
            ...action.payload.value,
          ],
        },
      };
    default:
      return state;
  }
};
