import React from "react";
import SquashImage from "../../assets/images/loginImage.jpg";
import { Image } from "semantic-ui-react";

const LandingImage = () => {
  return (
    <>
      <Image src={SquashImage} className="squash-landing-image" />
    </>
  );
};

export default LandingImage;
