import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/selectors/User";
import { logoutUser } from "../../../store/actions/User";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import "./UserProfile.scss";

const UserDetail = ({ userData }) => (
    <div className="profile-dropdown-container">
        <div className="pdc-photo-container">
            <Avatar
                style={{ backgroundColor: "#87d068" }}
                icon={!userData?.username ? <UserOutlined /> : null}
            >
                {userData?.username ? userData?.username[0]?.toUpperCase() : ""}
            </Avatar>
        </div>
        <div className="pdc-user-detail">
            <div className="pdc-user-name">
                {userData?.name || userData?.username}
            </div>
            <div className="pdc-user-email">{userData?.email}</div>
        </div>
    </div>
);

const UserProfile = () => {
    const userData = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("token");
        dispatch(logoutUser());
        navigate("/login");
    };

    const items = [
        {
            label: <UserDetail userData={userData} />,
        },
        {
            type: "divider",
        },
        // {
        //     key: "1",
        //     label: "Export Data to Excel",
        //     icon: <FileExcelOutlined />,
        //     // onClick: () => downloadExcel(),
        // },
        {
            key: "2",
            label: "logout",
            icon: <LogoutOutlined />,
            onClick: () => handleLogout(),
        },
    ];

    return (
        <div className="profile-photo-container">
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
                <Avatar
                    style={{ backgroundColor: "#87d068" }}
                    icon={!userData?.username ? <UserOutlined /> : null}
                >
                    {userData?.username ? userData?.username[0]?.toUpperCase() : ""}
                </Avatar>
            </Dropdown>
        </div>
    );
};

export default UserProfile;
