 /* eslint-disable */
import { useSelector } from "react-redux";
import {
  SelectPlayer1Stats,
  SelectPlayer2Stats,
} from "../../store/selectors/Charts";
import { selectSelectedVideo } from "../../store/selectors/Match";
import ChartTopNavigation from "./ChartTopNavigation.js/ChartTopNavigation";
import CourtChart from "./CourtChart/CourtChart";
import PreviewChartsModal from "./PreviewChartsModal";
import { useEffect, useState,} from "react";
import {  Tabs } from "antd";
import ScoreGraph from "./ScoreGraph/ScoreGraph";
import ShotCountAndQualityGraph from "./ShotCharts/ShotCountAndQualityGraph";
import ShotQuality from "./ShotCharts/ShotQuality";
const ChartPage = () => {
 
  const player1Stats = useSelector(SelectPlayer1Stats);
  const player2Stats = useSelector(SelectPlayer2Stats);
  const selectedMatch = useSelector(selectSelectedVideo);
  const [showPreview,setShowPreview]=useState("");
  const [selectedPlayer, setSelectedPlayer] = useState(1);
  const [selectedPlayerStats, setSelectedPlayerStats] = useState(null);
  const [disableSetsButton, setDisableSetsButton] = useState([
    true,
    true,
    true,
    true,
    true,
  ]);

  const updateState = (selectedPlayerStats) => {
    const setData = selectedPlayerStats?.analysed_data?.set_data;
    const newDisableSets = [...disableSetsButton];
    const length = Math.min(setData?.length, disableSetsButton.length); // Get the minimum length
    for (let i = 0; i < length; i++) {
      newDisableSets[i] = !newDisableSets[i]; // Reverse the value
    }
    setDisableSetsButton(newDisableSets);
  };

  const handleSelectedPlayer = (value) => {
    setSelectedPlayer(value);
    setSelectedPlayerStats(value === 1 ? player1Stats : player2Stats);
    updateState(value === 1 ? player1Stats : player2Stats);
  };

  // to disable the set button for which we don't have data
  useEffect(() => {
    updateState(selectedPlayerStats);
  }, [selectedPlayerStats]);

  // setting the default selected player stats
  useEffect(() => {
    if (selectedPlayer === 1) {
      setSelectedPlayerStats(player1Stats);
    } else {
      setSelectedPlayerStats(player2Stats);
    }
  }, [player1Stats, player2Stats]);
  const handleGeneratePreview = () => {
    setShowPreview("filter");
  }
  const closeModal = (value) =>{
    setShowPreview(value);
  }
  return (
    <>
      <ChartTopNavigation
        handleSelectedPlayer={handleSelectedPlayer}
        selectedPlayer={selectedPlayer}
        showPreviewFnc={handleGeneratePreview}
      />
    
      <Tabs type="card">
        <Tabs.TabPane tab="Shot Quality" key="1">
          <ShotQuality
            selectedPlayerStats={selectedPlayerStats}
            disableSetsButton={disableSetsButton}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Court" key="2">
          <CourtChart
            selectedPlayerStats={selectedPlayerStats}
            selectedPlayer={selectedPlayer}
            disableSetsButton={disableSetsButton}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Score" key="3">
          <ScoreGraph
            data={selectedPlayerStats?.analysed_data?.set_data}
            disableSetsButton={disableSetsButton}
          />
        </Tabs.TabPane>

        {/* <Tabs.TabPane tab="Winning Shot" key="4">
          <WiningShotGraph data={selectedPlayerStats} />
          <Divider></Divider>
          <WiningShotAnalysisGraph selectedPlayerStats={selectedPlayerStats} />
        </Tabs.TabPane> */}
        <Tabs.TabPane tab="Shot Count & Quality" key="5">
          <ShotCountAndQualityGraph
            data={selectedPlayerStats}
            disableSetsButton={disableSetsButton}
          />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Stats Tables" key="6">
          <StatsTables selectedPlayerStats={selectedPlayerStats} />
        </Tabs.TabPane> */}
      </Tabs>
      {showPreview && 
      <PreviewChartsModal selectedPlayerStats ={selectedPlayerStats} open={showPreview} modalcls={closeModal} selectedPlayerName={selectedPlayer === 1 ? selectedMatch?.player1.split(" ")[0] :selectedMatch?.player2.split(" ")[0]} setsValues={disableSetsButton}/>}
    </>
  );
};

export default ChartPage;
