import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { UpOutlined, DownOutlined, CheckOutlined } from "@ant-design/icons";
import "./SelectDropdown.scss";
import { selectedGridIndex } from "../../../../store/actions/GridConfig";
import { useKeyboardNavigation } from "./utils/useKeyboardNavigation";

const SelectDropdown = ({
  options,
  placeholder,
  value,
  onChange,
  renderDropdown,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState("");

  const dropDownRef = useRef();
  
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setHighlightedIndex(null);
  };

  const handleChange = (newValue, index) => {
    onChange(newValue);
    dispatch(selectedGridIndex(null));
    setIsOpen(false);
    setHighlightedIndex(null);
    setSelectedIndex(index + 1);
  };

  useKeyboardNavigation({
    isOpen,
    highlightedIndex,
    setHighlightedIndex,
    options,
    handleChange,
  });

  const showDropdown = () => (
    <div className="dropdown-menu"  ref={dropDownRef}>
      {options?.map((option, index) => (
        <div
          key={option.id}
          className={`dropdown-option ${
            value?.id === option.id ? "selected" : ""
          } ${index === highlightedIndex ? "highlighted" : ""}`}
          onClick={() => handleChange(option, index)}
        >
          <span>{`Grid ${index + 1}`}</span>
          {option.id.includes("new") && (
            <div className="not-saved-indicator"></div>
          )}
          {value?.id === option.id && <CheckOutlined />}
        </div>
      ))}
      {renderDropdown && renderDropdown()}
    </div>
  );

  return (
    <div className="select-dropdown" style={{ width: "100%" }}>
      <div className="selected-value" onClick={toggleDropdown}>
        {!value?.id ? placeholder : `Grid ${selectedIndex}`}
        <div className="dropdown-action">
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
      </div>
      {isOpen && showDropdown()}
    </div>
  );
};

export default SelectDropdown;
