import React from "react";
import { Field, Form, Formik } from "formik";
import { credLoginAction } from "../../store/actions/User";
import { useDispatch } from "react-redux";
import { StyledInputField } from "./StyledInputField";
import { Button } from "antd";
import { useState } from "react";
import "./LoginPage.scss";
import { useNavigate } from "react-router";
import { listVideos } from "../../store/actions/Match";

const SignInWithCredential = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const callback = () => {
    setSubmitting(false);
    navigate("/matches");
    dispatch(listVideos());
  };
  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.username) {
          errors.username = "Required";
        } else if (!/^[a-zA-Z0-9_]{3,20}$/i.test(values.username)) {
          errors.username = "Invalid username";
        } else if (!values.password) {
          errors.password = "Password is required";
        }
        return errors;
      }}
      onSubmit={(values) => {
        dispatch(credLoginAction(values, callback));
        setSubmitting(true);
      }}
    >
      {({ setFieldValue, setFieldTouched, isValid, dirty }) => (
        <Form className="cred-login-form">
          <Field
            label="Username"
            type="email"
            name="username"
            component={StyledInputField}
            placeholder="Enter Your Username"
            onChange={(e) => {
              setFieldTouched("username", true, true);
              setFieldValue("username", e.target.value);
            }}
          />
          <Field
            label="Password"
            type="password"
            name="password"
            component={StyledInputField}
            placeholder="Enter Your Password"
            onChange={(e) => {
              setFieldTouched("password", true, true);
              setFieldValue("password", e.target.value);
            }}
          />
          <Button
            type="primary"
            size="large"
            block="true"
            loading={isSubmitting}
            htmlType="submit"
            disabled={!isValid || !dirty}
            className="sign-in-button"
          >
            Sign In
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignInWithCredential;
