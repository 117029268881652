import { message } from "antd";
import { actionFactory } from "../../libs/actionFactory";
import {
  GENERATE_DERIVE,
  GET_PLAYER_1_CHART_STATS,
  GET_PLAYER_2_CHART_STATS,
} from "../actionTypes/Charts";

export const deleteOldDerive = (matchId, callback, chartView = false) => {
  return actionFactory({
    api: `delete/api/matches/${matchId}/annotations/derive/clear_all/`,
    failureToast: true,
    actionBase: GENERATE_DERIVE,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(generateDerive(matchId, callback, chartView));
        })
        .catch((err) => {
          dispatch(generateDerive(matchId, callback, chartView));
        });
    },
  });
};

export const generateDerive = (matchId, callback, chartView) => {
  return actionFactory({
    api: `post/api/matches/${matchId}/annotations/derive/`,
    failureToast: true,
    actionBase: GENERATE_DERIVE,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          chartView && dispatch(getChartStats(matchId, 1, callback));
          chartView && dispatch(getChartStats(matchId, 2, callback));
          callback(false, false);
        })
        .catch((err) => {
          callback(false, false);
          chartView && dispatch(getChartStats(matchId, 1, callback));
          chartView && dispatch(getChartStats(matchId, 2, callback));
        });
    },
  });
};

export const setPlayer1ChartStats = (stats) => ({
  type: GET_PLAYER_1_CHART_STATS,
  payload: stats,
});

export const setPlayer2ChartStats = (stats) => ({
  type: GET_PLAYER_2_CHART_STATS,
  payload: stats,
});

export const getChartStats = (matchId, player = 1, callback) => {
  return actionFactory({
    api: `get/api/matches/${matchId}/annotations/compute/?player=${player}`,
    failureToast: false,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          message.success("Stats generated successfully.");
          if (player === 1) {
            dispatch(setPlayer1ChartStats(resp.data));
          } else if (player === 2) {
            dispatch(setPlayer2ChartStats(resp.data));
          }
          callback(false, true);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.status?.status_message;
          message.error(`Error: ${errorMessage}`, 5);
          callback(false, false);
        });
    },
  });
};
