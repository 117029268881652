import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Select } from "antd";
import { updateUserGroups } from "../../store/actions/Admin";
import { sharedProps } from "./adminConstant";

const UserGroupsUpdater = ({ data }) => {
  // State to keep track of updated groups
  const [updatedGroups, setUpdatedGroups] = useState(data?.groups);

  // Initialize dispatch function
  const dispatch = useDispatch();

  // Reset the groups to the initial data
  const handleReset = () => {
    setUpdatedGroups(data?.groups);
  };

  // Dispatch action to update the groups
  const handleUpdate = () => {
    dispatch(updateUserGroups(data?.id, { groups: updatedGroups }));
  };

  return (
    <div className="user-groups">
      {/* Select component for updating user groups */}
      <Select
        value={updatedGroups}
        {...sharedProps}
        onChange={(newGroups) => setUpdatedGroups(newGroups)}
      />
      {/* Display buttons only if groups have changed */}
      {JSON.stringify([...data?.groups].sort()) !== JSON.stringify([...updatedGroups]?.sort()) && (
        <div className="update-groups-button">
          <Button onClick={handleReset}>Reset</Button>
          <Button onClick={handleUpdate}>Submit</Button>
        </div>
      )}
    </div>
  );
};

export default UserGroupsUpdater;
