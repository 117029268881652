 /* eslint-disable */
import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { Select } from "antd";
import "./ShotQuality.scss";

const { Option } = Select;

const QualityVsSpeedVsTightnessBar = ({ data }) => {
  const [selectedQualityType, setSelectedQualityType] = useState("High");
  const [qualityData, setQualityData] = useState(null);

  const handleQualityTypeChange = (value) => {
    setSelectedQualityType(value);
  };

  useEffect(() => {
    data && setQualityData(data[selectedQualityType]);
  }, [selectedQualityType]);

  useEffect(() => {
    // Initialize bar graph container
    const chartContainer = document.getElementById(
      "quality-vs-speed-vs-tightness-graph"
    );
    const chart = echarts.init(chartContainer);

    // Define bar graph options
    const options = {
      title: {
        text: "Speed & Tightness",
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        // Custom tooltip formatter to include percentage
        formatter: function (params) {
          let tooltip = params[0].name + "<br/>";
          params.forEach(function (item) {
            let totalSum;
            if (item.seriesName === "Tight") {
              totalSum =
                qualityData?.Fast?.Tight +
                qualityData?.Fast?.Medium +
                qualityData?.Fast?.Loose;
            } else if (item.seriesName === "Medium") {
              totalSum =
                qualityData?.Medium?.Tight +
                qualityData?.Medium?.Medium +
                qualityData?.Medium?.Loose;
            } else if (item.seriesName === "Loose") {
              totalSum =
                qualityData?.Slow?.Tight +
                qualityData?.Slow?.Medium +
                qualityData?.Slow?.Loose;
            }
            tooltip +=
              item.seriesName +
              ": " +
              item.value +
              " (" +
              ((item.value / totalSum) * 100).toFixed(2) +
              "%)<br/>";
          });
          return tooltip;
        },
      },
      legend: {
        data: ["Tight", "Medium", "Loose"],
      },
      xAxis: {
        type: "category",
        data: ["High", "Medium", "Low"],

        axisLabel: {
          interval: 0,
          rotate: 45,
          formatter: (value) => {
            return value !== " " ? value : "Shot Types"; // Show "Shot Types" at the last category position
          },
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Tight",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            qualityData?.Fast?.Tight,
            qualityData?.Fast?.Medium,
            qualityData?.Fast?.Loose,
          ],
          color: "#1fd137",
        },
        {
          name: "Medium",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            qualityData?.Medium?.Tight,
            qualityData?.Medium?.Medium,
            qualityData?.Medium?.Loose,
          ],
          color: "#faf74d",
        },
        {
          name: "Loose",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            qualityData?.Slow?.Tight,
            qualityData?.Slow?.Medium,
            qualityData?.Slow?.Loose,
          ],
          color: "#ff6259",
        },
      ],
    };

    // Set options and render chart
    chart.setOption(options);

    // Cleanup function to dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [qualityData]);

  return (
    <>
      <div>
        <div className="shot-type-filter-items">
          <strong>{"Quality  "}</strong>
          <Select
            style={{ width: 200 }}
            placeholder="Select "
            onChange={handleQualityTypeChange}
            value={selectedQualityType}
          >
            {Object.keys(data).map((shotType) => (
              <Option key={shotType} value={shotType}>
                {shotType}
              </Option>
            ))}
          </Select>
        </div>
        <div
          id="quality-vs-speed-vs-tightness-graph"
          style={{ width: "100%", height: "400px" }}
        ></div>
      </div>
    </>
  );
};

export default QualityVsSpeedVsTightnessBar;
