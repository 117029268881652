import React from "react";
import "./CircularGridPoint.scss";

const CircularGridPoint = ({
  id,
  value,
  selected,
  style,
  onClick = () => {},
}) => {
  return (
    <div
      className="circularGridPoint"
      id={id}
      onClick={onClick}
      style={style}
    ></div>
  );
};

export default CircularGridPoint;
