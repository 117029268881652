export const GRID_SELECTOR_ENABLE = "GRID_SELECTOR_ENABLE";
export const SET_VIDEO_RESOLUTION = "SET_VIDEO_RESOLUTION";
export const SET_WALL_INPUT = "SET_WALL_INPUT";
export const SET_FLOOR_INPUT = "SET_FLOOR_INPUT";

export const GENERATE_GRIDS = "GENERATE_GRIDS";
export const GENERATE_GRIDS_FAILURE = "GENERATE_GRIDS_FAILURE";
export const GENERATE_GRIDS_SUCCESS = "GENERATE_GRIDS_SUCCESS";

export const UPDATE_GRIDS = "UPDATE_GRIDS";
export const UPDATE_GRIDS_FAILURE = "UPDATE_GRIDS_FAILURE";
export const UPDATE_GRIDS_SUCCESS = "UPDATE_GRIDS_SUCCESS";

export const GET_GRIDS = "GET_GRIDS";
export const GET_GRIDS_FAILURE = "GET_GRIDS_FAILURE";
export const GET_GRIDS_SUCCESS = "GET_GRIDS_SUCCESS";

export const DELETE_GRIDS = "DELETE_GRIDS";
export const DELETE_GRIDS_FAILURE = "DELETE_GRIDS_FAILURE";
export const DELETE_GRIDS_SUCCESS = "DELETE_GRIDS_SUCCESS";

export const SELECTED_TAB = "SELECTED_TAB";
export const SELECTED_GRID = "SELECTED_GRID";

export const SET_GRID_DURATION = "SET_GRID_DURATION";

export const SET_NEW_GRID = "SET_NEW_GRID";

export const SET_GRID_LIST = "SET_GRID_LIST";

export const SET_HTI_OR_BOUNCE = "SET_HTI_OR_BOUNCE";

export const SELECTED_GRID_POINT_INDEX = "SELECTED_GRID_POINT_INDEX";
