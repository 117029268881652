import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { Radio } from "antd";

const ShotCountAndQualityGraph = ({ data, disableSetsButton }) => {
  const [dataSets, setDataSets] = useState(
    data?.analysed_data?.match_data?.shottype_vs_quality
  );
  useEffect(() => {
    setDataSets(data?.analysed_data?.match_data?.shottype_vs_quality);
  }, [data]);

  const onSetChange = (value) => {
    if (value === "match") {
      setDataSets(data?.analysed_data?.match_data?.shottype_vs_quality);
    } else if (
      data?.analysed_data?.set_data &&
      data?.analysed_data?.set_data.length > value
    ) {
      setDataSets(data?.analysed_data?.set_data[value].shottype_vs_quality);
    }
  };

  useEffect(() => {
    // Initialize bar graph container
    const chartContainer = document.getElementById(
      "shot-count-and-quality-graph"
    );
    const chart = echarts.init(chartContainer);

    // Define shot types array
    const shotTypes = [
      "Serve",
      "Straight Drive",
      "Drop",
      "Lob",
      "Boast",
      "Reverse Boast",
      "Volley Drive",
      "Volley Kill",
      "Volley Lob",
      "Cross Drive",
      "Cross Drop",
      "Cross Lob",
      "Cross Volley Lob",
      "Cross Volley Kill",
    ];

    // Define bar graph options
    const options = {
      title: {
        text: "Weak Shot Analysis",
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          const shotType = params[0].axisValue;
          const shotData = dataSets[shotType];
          const totalShots = shotData.Low + shotData.Medium + shotData.High;
          const lowPercentage = ((shotData.Low / totalShots) * 100).toFixed(2);

          return `
            ${shotType}<br/>
            Total Shots: ${totalShots}<br/>
            Weak Shots %: ${lowPercentage}% (${shotData.Low})
          `;
        },
      },
      legend: {
        data: ["Shots", "Weak Shots"],
      },
      xAxis: {
        type: "category",
        name: "Shot Type",
        data: shotTypes,
        axisLabel: {
          interval: 0,
          rotate: 45,
          formatter: (value) => {
            return value !== " " ? value : "Shot Types"; // Show "Shot Types" at the last category position
          },
        },
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: {
        type: "value",
        name: "Shots",
      },

      series: [
        {
          name: "Shots",
          type: "bar",
          data: shotTypes.map((type) => {
            const { Low, Medium, High } = dataSets[type];
            return Low + Medium + High;
          }),
          itemStyle: {
            color: "#306bff",
          },
        },
        {
          name: "Weak Shots",
          type: "line",
          data: shotTypes.map((type) => dataSets[type].Low),
          itemStyle: {
            color: "#ffac38",
          },
        },
      ],
    };

    // Set options and render chart
    chart.setOption(options);

    // Cleanup function to dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [dataSets]);

  return (
    <>
      <div className="set-radio-buttons">
        <Radio.Group
          onChange={(e) => onSetChange(e.target.value)}
          defaultValue="match"
        >
          <Radio.Button value="match">Match</Radio.Button>
          <Radio.Button value={0} disabled={disableSetsButton[0]}>
            Set 1
          </Radio.Button>
          <Radio.Button value={1} disabled={disableSetsButton[1]}>
            Set 2
          </Radio.Button>
          <Radio.Button value={2} disabled={disableSetsButton[2]}>
            Set 3
          </Radio.Button>
          <Radio.Button value={3} disabled={disableSetsButton[3]}>
            Set 4
          </Radio.Button>
          <Radio.Button value={4} disabled={disableSetsButton[4]}>
            Set 5
          </Radio.Button>
        </Radio.Group>
      </div>
      <div
        id="shot-count-and-quality-graph"
        style={{ width: "100%", height: "520px" }}
      ></div>
    </>
  );
};
export default ShotCountAndQualityGraph;
