import {
  IS_ADD_ANNOTATION_MODAL_OPEN,
  SET_PIN_MODE,
  SET_VIDEO_START_TIME,
} from "../actionTypes/AnnotationModal"

export const isAddAnnotationModalOpen = (isModalOpen) => {
  return {
    type: IS_ADD_ANNOTATION_MODAL_OPEN,
    payload: isModalOpen,
  }
}

export const setVideoStartTime = (startTime) => {
  return {
    type: SET_VIDEO_START_TIME,
    payload: startTime,
  }
}

export const setPinMode = (mode) => {
  return {
    type: SET_PIN_MODE,
    payload: mode,
  }
}
