import { Button, AutoComplete } from "antd";
import "./MatchList.scss";
import { useState } from "react";
import { checkPermissions } from "../../../libs/permissions";

const MatchHeader = ({
  handleAddMatch,
  matchListItems,
  handleSelectedMatch,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    // Filter the matchListItems based on the search query
    const filteredOptions = matchListItems.filter(
      (item) => item.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setOptions(filteredOptions.map((item) => ({ value: item.title })));
  };

  const onSelect = (value) => {
    const selectedItem = matchListItems.find((item) => item.title === value);
    handleSelectedMatch(selectedItem);
  };

  return (
    <>
      <div className="search-bar">
        <AutoComplete
          options={options}
          onSelect={onSelect}
          onChange={handleSearch}
          placeholder="Search..."
          value={searchQuery}
          style={{ width: "100%", margin: "1%" }}
        ></AutoComplete>
      </div>
      <div className="match-header">
        <Button onClick={handleAddMatch} disabled={!checkPermissions("canCreateMatch")}>Add Match</Button>
      </div>
    </>
  );
};

export default MatchHeader;
