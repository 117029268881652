import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedVideo } from "../../store/selectors/Match";
import StyledModal from "../StyledModal/StyledModal";
import "./CreateDeleteModal.scss";
import { selectBulkSelectValues } from "../../store/selectors/BulkSelectSelectors";
import { deleteBulkSelected } from "../../store/actions/bulkSelectActions";
import { ANNOTATIONS_TAB } from "../../constants";
import { selectAnnotation } from "../../store/selectors/ReviewDetails";
import { deleteAnnotation } from "../../store/actions/ReviewDetails";
import { secondsToHMSM } from "../../libs/utils";

const CreateDeleteModal = ({ open, onClose, onDelete }) => {
  // Redux hooks for dispatching actions and selecting state
  const dispatch = useDispatch();
  const selectedShot = useSelector(selectAnnotation);
  const bulkSelectValues = useSelector((state) =>
    selectBulkSelectValues(state, ANNOTATIONS_TAB)
  );
  const matchId = useSelector(selectSelectedVideo)?.id;

  // Function to handle the "Ok" button click
  const handleOk = () => {
    if (!bulkSelectValues || bulkSelectValues.length === 0) {
      dispatch(deleteAnnotation(selectedShot));
    } else {
      // Dispatch the deleteBulkSelected action
      dispatch(deleteBulkSelected(matchId, bulkSelectValues, onClose));
    }
  };

  // Render the component
  return (
    <StyledModal
      title="Delete Annotations"
      open={open}
      onCancel={onClose}
      onOk={handleOk}
    >
      <div className="delete-modal-content">
        Are you sure you want to delete the selected annotation{" "}
        {!bulkSelectValues ||
          (bulkSelectValues?.length === 0 && (
            <span>
              <strong>{secondsToHMSM(selectedShot?.start_time)}</strong>
            </span>
          ))}
        ?
      </div>
    </StyledModal>
  );
};

export default CreateDeleteModal;
