import React, { useState, useRef } from "react";
import { Modal, Divider } from "antd";
import "./ShotCharts/ShotQuality.scss";
import jsPDF from "jspdf";
//import html2canvas from 'html2canvas';
import { Button } from "antd";
import { tableItems } from "./CourtChart/FilterConstants";
import { hasIntegerKeys } from "../../libs/utils";
import CommonGraphs from "./commonCharts/commonGraphs";
import CommonCourtView from "./commonCharts/commonCourtView";
import ChartFilterPanel from "./ChartFilterPanel";

const PreviewChartsModal = ({
  selectedPlayerStats,
  open,
  modalcls,
  selectedPlayerName,
  setsValues,
}) => {
  const reportTemplateRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    set: [],
    charts: [],
    courtArea: [],
  });

  // const handleGeneratePdf_old = async () => {
  //   const data = document.getElementById('pdf-content');
  //   const pageHeight = 295; // Set the desired height of each page in the PDF

  //   const doc = new jsPDF('p', 'mm');
  //   const docWidth = doc.internal.pageSize.getWidth();

  //   let positionY = 0;
  //   let currentPage = 1;

  //   const addNewPage = () => {
  //     doc.addPage();
  //     positionY = 0;
  //     currentPage++;
  //   };

  //   const renderContent = (child) => {
  //     return new Promise((resolve) => {
  //       html2canvas(child).then((canvas) => {
  //         const imgData = canvas.toDataURL('image/jpeg', 1);
  //         const imgHeight = (canvas.height * docWidth) / canvas.width;

  //         if (positionY + imgHeight > pageHeight) {
  //           addNewPage();
  //         }

  //         doc.addImage(imgData, 'JPEG', 0, positionY, docWidth, imgHeight,'','FAST');
  //         positionY += imgHeight;

  //         resolve();
  //       });
  //     });
  //   };

  //   const children = Array.from(data.children);

  //   for (let i = 0; i < children.length; i++) {
  //     const child = children[i];

  //     await renderContent(child);
  //   }

  //   doc.save('Download.pdf');
  // };
  const handleGeneratePdf = () => {
    setLoading(true);
    let doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "letter",
    });

    const opt = {
      callback: function (doc) {
        setLoading(false);
        doc.save(selectedPlayerName + "_report");
      },
      margin:
        reportTemplateRef.current.scrollWidth < 1600
          ? [10, 30, 30, 10]
          : [10, 30, 40, 20],
      autoPaging: true,
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        logging: false,
        scale: reportTemplateRef.current.scrollWidth < 1600 ? 0.38 : 0.3,
      },
    };

    doc.html(reportTemplateRef.current, opt);
  };

  const barJsxCreation = (graphData, title, graphTitle) => {
    let barJsx = [];
    let rowCount = 0;
    let objKeysArr = [];
    let colCount = 2;
    if (Object.keys(graphData).length > 0) {
      rowCount = Math.ceil(Object.keys(graphData).length / 2);
      for (let key in graphData) {
        if (graphData.hasOwnProperty(key)) {
          objKeysArr.push(key);
        }
      }
      for (let i = 1; i <= rowCount; i++) {
        let columnData = [];
        for (let col = (i - 1) * colCount; col < i * colCount; col++) {
          if (objKeysArr.length > col) {
            columnData.push(
              <CommonGraphs
                data={graphData[objKeysArr[col]]}
                id={`${title}_${objKeysArr[col]}_${col}`}
                title={`${graphTitle} - ${objKeysArr[col]}`}
                chartType="bar"
                divWidth="50%"
                divHeight="400px"
              />
            );
          }
        }
        if (!!columnData && columnData.length > 0) {
          barJsx.push(
            <>
              <Divider></Divider>
              <div className="pie-section">{columnData}</div>
            </>
          );
        }
      }
      return barJsx;
    } else return null;
  };

  const showCourtGrid = (data, title_label) => {
    if (!hasIntegerKeys(data)) {
      let dataJsx = [];
      var title = "";
      for (let i in data) {
        title = title_label + " - " + i;
        dataJsx.push(
          <>
            <Divider></Divider>
            <CommonCourtView courtData={data[i]} title={title} />
          </>
        );
      }
      return dataJsx;
    } else {
      return (
        <>
          <Divider></Divider>
          <CommonCourtView courtData={data} title={title_label} />
        </>
      );
    }
  };

  const { set, charts, courtArea } = selectedFilter;
  const match = set.includes("match");
  const court = tableItems.filter(({ key }) => courtArea.includes(key));
  const chartsObj = charts.reduce(
    (obj, chart) => ({ ...obj, [chart]: true }),
    {}
  );

  return (
    <>
      <ChartFilterPanel
        open={open === "filter"}
        selectedFilter={selectedFilter}
        viewHandler={modalcls}
        playerStats={selectedPlayerStats}
        setSelectedFilter={setSelectedFilter}
      />
      {open === "preview" && (
        <Modal
          title={
            <div>
              <span>Preview Reports</span>
              <div className="prev-charts-modal-btn">
                <Button
                  onClick={() => modalcls("filter")}
                >
                  Edit Filter
                </Button>
                <Button
                  onClick={() => handleGeneratePdf()}
                  loading={loading}
                >
                  Download Report
                </Button>
              </div>
            </div>
          }
          open={open === "preview"}
          onCancel={() => modalcls(false)}
          maskClosable={false}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          width="auto"
          bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          <div
            className="modal-content"
            id="pdf-content"
            ref={reportTemplateRef}
          >
            {/* Match Data With Charts  */}
            <div className="breakpage"></div>
            {match && (
              <>
                <div className="grp-title">
                  <span>Match Data</span>
                </div>

                {
                  // Shot Quality and Speed Chart
                  (chartsObj?.shot_quality_table ||
                    chartsObj?.shot_speed_table) && (
                    <>
                      <div className="pie-section">
                        {
                          // Shot Quality Chart
                          chartsObj.shot_quality_table && (
                            <>
                              <CommonGraphs
                                data={
                                  selectedPlayerStats?.analysed_data?.match_data
                                    ?.shot_quality_table
                                }
                                id={"shot-quality-pie-chart_1"}
                                title="Shot Quality"
                                chartType="pei"
                              />
                            </>
                          )
                        }
                        {
                          // Shot Speed Chart
                          chartsObj.shot_speed_table && (
                            <>
                              <CommonGraphs
                                data={
                                  selectedPlayerStats?.analysed_data?.match_data
                                    ?.shot_speed_table
                                }
                                id={"shot-quality-pie-chart_2"}
                                title="Shot Speed"
                                chartType="pei"
                              />
                            </>
                          )
                        }
                      </div>
                      <Divider></Divider>
                    </>
                  )
                }
                {
                  /* Shot Tightness Graph  */

                  chartsObj?.shot_tightness_table && (
                    <>
                      <div className="pie-section">
                        <CommonGraphs
                          data={
                            selectedPlayerStats?.analysed_data?.match_data
                              ?.shot_tightness_table
                          }
                          id={"shot-quality-pie-chart_3"}
                          title="Shot Tightness"
                          chartType="pei"
                        />
                      </div>
                      <Divider></Divider>
                    </>
                  )
                }
                {
                  //  Categorized Shot Quality

                  chartsObj?.shottype_vs_quality && (
                    <>
                      <CommonGraphs
                        data={
                          selectedPlayerStats?.analysed_data?.match_data
                            ?.shottype_vs_quality
                        }
                        id="match_bar_qua"
                        title="Categorized Shot Quality"
                        chartType="bar"
                        xLabelName="Shot Types"
                        yLabelName="Shots"
                        divWidth="100%"
                        divHeight="400px"
                      />
                      <Divider></Divider>
                    </>
                  )
                }
                {
                  // Quality & Speed Chart and  Quality & Tightness Chart

                  (chartsObj?.quality_vs_speed_table ||
                    chartsObj?.quality_vs_tightness_table) && (
                    <>
                      <div className="pie-section">
                        {
                          // Quality & Speed
                          chartsObj?.quality_vs_speed_table && (
                            <>
                              <CommonGraphs
                                data={
                                  selectedPlayerStats?.analysed_data?.match_data
                                    ?.quality_vs_speed_table
                                }
                                id="match_bar_qua_spe"
                                title="Quality & Speed"
                                chartType="bar"
                                xLabelName="Quality"
                                yLabelName="Shots"
                                divWidth="50%"
                                divHeight="400px"
                              />
                            </>
                          )
                        }
                        {
                          // Quality & Tightness Charts
                          chartsObj?.quality_vs_tightness_table && (
                            <>
                              <CommonGraphs
                                data={
                                  selectedPlayerStats?.analysed_data?.match_data
                                    ?.quality_vs_tightness_table
                                }
                                id="match_bar_qua_tight"
                                title="Quality & Tightness"
                                chartType="bar"
                                xLabelName="Quality"
                                yLabelName="Shots"
                                divWidth="50%"
                                divHeight="400px"
                              />
                            </>
                          )
                        }
                      </div>
                    </>
                  )
                }

                {
                  //  Shot Type vs Speed vs Tightness Charts
                  chartsObj?.shottype_vs_speed_vs_tightness_table &&
                    barJsxCreation(
                      selectedPlayerStats?.analysed_data?.match_data
                        ?.shottype_vs_speed_vs_tightness_table,
                      "match_bar_speed_tight",
                      "Speed & Tightness"
                    )
                }

                {
                  // Quality vs Speed vs Tightness Table

                  charts?.quality_vs_speed_vs_tightness_table &&
                    barJsxCreation(
                      selectedPlayerStats?.analysed_data?.match_data
                        ?.quality_vs_speed_vs_tightness_table,
                      "match_bar_quality_tight",
                      "Speed & Tightness"
                    )
                }

                {/* court Area Charts  */}

                {!!court &&
                  court.length > 0 &&
                  court.map((item, key) =>
                    showCourtGrid(
                      selectedPlayerStats?.analysed_data?.match_data?.[
                        item.key
                      ],
                      item.label
                    )
                  )}
                {
                  // Weak Shot Analysis
                  chartsObj?.shottype_vs_quality && (
                    <>
                      <CommonGraphs
                        data={
                          selectedPlayerStats?.analysed_data?.match_data
                            ?.shottype_vs_quality
                        }
                        id={`match_bar_line_shot_qua`}
                        title="Weak Shot Analysis"
                        chartType="bar-line"
                        xLabelName="Shot Types"
                        yLabelName="Shots"
                        divWidth="100%"
                        divHeight="520px"
                      />
                      <Divider></Divider>{" "}
                    </>
                  )
                }
              </>
            )}
            {/* Sets Wise Data */}

            {!!setsValues &&
              setsValues.length > 0 &&
              setsValues.map(
                (item, i) =>
                  !item &&
                  set.includes(String(i)) && (
                    <React.Fragment key={i}>
                      <div className="breakpage"></div>
                      <div className="grp-title">
                        <span>{`Set ${i + 1}`}</span>
                      </div>

                      {
                        //short Speed and Quality Charts
                        (chartsObj?.shot_quality_table ||
                          chartsObj?.shot_speed_table) && (
                          <>
                            <div className="pie-section">
                              {
                                // Shot Quality Charts

                                chartsObj?.shot_quality_table && (
                                  <>
                                    <CommonGraphs
                                      data={
                                        selectedPlayerStats?.analysed_data
                                          ?.set_data[i]?.shot_quality_table
                                      }
                                      id={`shot-quality-pie-chart_qua_${i}`}
                                      title="Shot Quality"
                                      chartType="pei"
                                    />
                                  </>
                                )
                              }
                              {
                                // Shot Speed Charts

                                chartsObj?.shot_speed_table && (
                                  <>
                                    <CommonGraphs
                                      data={
                                        selectedPlayerStats?.analysed_data
                                          ?.set_data[i]?.shot_speed_table
                                      }
                                      id={`shot-quality-pie-chart_speed_${i}`}
                                      title="Shot Speed"
                                      chartType="pei"
                                    />{" "}
                                  </>
                                )
                              }
                            </div>
                            <Divider></Divider>
                          </>
                        )
                      }
                      {
                        //Shot Tightness
                        chartsObj?.shot_tightness_table && (
                          <>
                            <div className="pie-section">
                              <CommonGraphs
                                data={
                                  selectedPlayerStats?.analysed_data?.set_data[
                                    i
                                  ]?.shot_tightness_table
                                }
                                id={`shot-quality-pie-chart_tight_${i}`}
                                title="Shot Tightness"
                                chartType="pei"
                              />
                            </div>
                            <Divider></Divider>
                          </>
                        )
                      }

                      {
                        // Categorized Shot Quality

                        chartsObj?.shottype_vs_quality && (
                          <>
                            <CommonGraphs
                              data={
                                selectedPlayerStats?.analysed_data?.set_data[i]
                                  ?.shottype_vs_quality
                              }
                              id={`set_bar_qua_${i}`}
                              title="Categorized Shot Quality"
                              chartType="bar"
                              xLabelName="Shot Types"
                              yLabelName="Shots"
                              divWidth="100%"
                              divHeight="400px"
                            />
                            <Divider></Divider>
                          </>
                        )
                      }
                      <>
                        {(chartsObj?.quality_vs_tightness_table ||
                          chartsObj.quality_vs_speed_table) && (
                          <div className="pie-section">
                            {
                              // Quality & Speed chart
                              chartsObj.quality_vs_speed_table && (
                                <>
                                  <CommonGraphs
                                    data={
                                      selectedPlayerStats?.analysed_data
                                        ?.set_data[i]?.quality_vs_speed_table
                                    }
                                    id={`set_bar_qua_spe_${i}`}
                                    title="Quality & Speed"
                                    chartType="bar"
                                    xLabelName="Quality"
                                    yLabelName="Shots"
                                    divWidth="50%"
                                    divHeight="400px"
                                  />
                                </>
                              )
                            }

                            {
                              // Quality & Tightness Charts

                              chartsObj?.quality_vs_tightness_table && (
                                <>
                                  <CommonGraphs
                                    data={
                                      selectedPlayerStats?.analysed_data
                                        ?.set_data[i]
                                        ?.quality_vs_tightness_table
                                    }
                                    id={`set_bar_qua_tight_${i}`}
                                    title="Quality & Tightness"
                                    chartType="bar"
                                    xLabelName="Quality"
                                    yLabelName="Shots"
                                    divWidth="50%"
                                    divHeight="400px"
                                  />
                                </>
                              )
                            }
                          </div>
                        )}
                      </>
                      {
                        //  Shot Type vs Speed vs Tightness

                        chartsObj?.shottype_vs_speed_vs_tightness_table && (
                          <>
                            {barJsxCreation(
                              selectedPlayerStats?.analysed_data?.set_data[i]
                                ?.shottype_vs_speed_vs_tightness_table,
                              `set_${i}_bar_speed_tight`,
                              "Speed & Tightness"
                            )}
                          </>
                        )
                      }
                      {
                        // Quality vs Speed vs Tightness Bar Graph

                        chartsObj?.quality_vs_speed_vs_tightness_table && (
                          <>
                            {barJsxCreation(
                              selectedPlayerStats?.analysed_data?.set_data[i]
                                ?.quality_vs_speed_vs_tightness_table,
                              `set_${i}_bar_quality_tight`,
                              "Speed & Tightness"
                            )}
                          </>
                        )
                      }

                      {
                        // court Area wise Graphs

                        !!court &&
                          court.length > 0 &&
                          court.map((item, k) =>
                            showCourtGrid(
                              selectedPlayerStats?.analysed_data?.set_data[i]?.[
                                item.key
                              ],
                              item.label
                            )
                          )
                      }
                      {
                        // Point Progression Graph

                        chartsObj?.score_progression && (
                          <>
                            <CommonGraphs
                              data={
                                selectedPlayerStats?.analysed_data?.set_data[i]
                                  ?.score_progression
                              }
                              id={`set_line_point_progession_${i}`}
                              title="Point Progression"
                              chartType="line"
                              xLabelName="Rally"
                              yLabelName="Points"
                              divWidth="90%"
                              divHeight="400px"
                            />
                            <Divider></Divider>
                          </>
                        )
                      }

                      {
                        // Weak Shot Analysis Graph

                        chartsObj?.shottype_vs_quality && (
                          <>
                            <Divider />
                            <CommonGraphs
                              data={
                                selectedPlayerStats?.analysed_data?.set_data[i]
                                  ?.shottype_vs_quality
                              }
                              id={`set_bar_line_shot_qua_${i}`}
                              title="Weak Shot Analysis"
                              chartType="bar-line"
                              xLabelName="Shot Types"
                              yLabelName="Shots"
                              divWidth="100%"
                              divHeight="520px"
                            />
                          </>
                        )
                      }
                    </React.Fragment>
                  )
              )}
          </div>
        </Modal>
      )}
    </>
  );
};
export default PreviewChartsModal;
