 /* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnnotation,
  selectAnnotationRowIndex,
  selectAnnotations,
} from "../../../store/selectors/ReviewDetails";
import { useEffect, useRef, useState } from "react";
import {
  UpdateShotList,
  bulkUpdateAnnotation,
  listAnnotations,
  reMapPointId,
  setAnnotationRowIndex,
  setPreviousAnnotation,
  setSelectedAnnotation,
  updateAnnotation,
} from "../../../store/actions/ReviewDetails";
import { selectSelectedVideo } from "../../../store/selectors/Match";
import { selectAnnotationsListingLoading } from "../../../store/selectors/User";
import {
  areSameArray,
  hasObjValueChanged,
  secondsToHMSM,
} from "../../../libs/utils";
import { getMatch } from "../../../store/actions/Match";
import { setVideoStartTime } from "../../../store/actions/AnnotationModal";
import ShotRow from "./ShotRow/ShotRow";
import "./ShotList.scss";
import ShotHeader from "./ShotHeader/ShotHeader";
import {
  addBulkSelect,
  clearBulkSelect,
  setBulkSelect,
} from "../../../store/actions/bulkSelectActions";
import { ANNOTATIONS_TAB } from "../../../constants";
import { LoadingOutlined } from "@ant-design/icons";
import { selectGridList } from "../../../store/selectors/GridConfig";
import { setSelectedGrid } from "../../../store/actions/GridConfig";
import { selectBulkSelectValues } from "../../../store/selectors/BulkSelectSelectors";
import { setAnnotationListingLoading } from "../../../store/actions/User";
import { Spin, message } from "antd";
import { checkPermissions } from "../../../libs/permissions";

const ShotList = ({ handleShot, formik }) => {
  const annotationsRef = useRef(null);
  const selectedShot = useSelector(selectAnnotation);
  const annotations = useSelector(selectAnnotations);
  const dispatch = useDispatch();
  const selectedMatch = useSelector((state) => selectSelectedVideo(state));
  const loading = useSelector((state) =>
    selectAnnotationsListingLoading(state)
  );
  const [dataSource, setDataSource] = useState([]);
  const selectedShotIndex = useSelector(selectAnnotationRowIndex);
  const gridList = useSelector(selectGridList);
  const bulkSelectValues = useSelector((state) =>
    selectBulkSelectValues(state, ANNOTATIONS_TAB)
  );

  useEffect(() => {
    dispatch(listAnnotations(selectedMatch?.id || "", "", true));
  }, [selectedMatch?.id, dispatch]);

  useEffect(() => {
    const updatedDataSource = annotations.map((annotation) => ({
      ...annotation,
      name: selectedMatch[`player${annotation.player}`],
      start_time: secondsToHMSM(annotation.start_time),
    }));
    setDataSource(updatedDataSource);
    const selectedRow = annotations.find(
      (item) => item.id === selectedShot?.id
    );
    dispatch(setSelectedAnnotation(selectedRow));
  }, [annotations, dispatch]);

  const shotListNeedsRefresh = (updatedData, oldData, index) => {
    if (
      updatedData.point_id !== oldData[index].point_id ||
      !areSameArray(updatedData?.current_score, oldData[index]?.current_score)
    ) {
      return true;
    }
    return false;
  };

  const callback = (updatedAnnotation, rowIndex) => {
    // Always dispatch getMatch
    selectedShot.hasOwnProperty("set") &&
      dispatch(getMatch(selectedShot?.match));
    // Check if the updatedAnnotation is different from the annotation at rowIndex
    if (shotListNeedsRefresh(updatedAnnotation, annotations, rowIndex)) {
      // If the IDs match, dispatch listAnnotations
      if (updatedAnnotation.id === annotations[rowIndex].id) {
        dispatch(listAnnotations(selectedMatch?.id, "", true));
      }
    } else {
      // Update the annotations list with the updatedAnnotation
      dispatch(setAnnotationListingLoading(false));
      dispatch(UpdateShotList(updatedAnnotation));
    }
  };

  const handleRowClick = (annotation, selectedIndex) => {
    // to update the shot if there is any changes
    const showUpdateMessage = hasObjValueChanged(
      annotations[selectedShotIndex],
      selectedShot
    )
      ? true
      : false;
    if (showUpdateMessage && !checkPermissions("canUpdateShot")) {
      message.warning("You do not have permission to perform this action.");
    }
    if (
      selectedShotIndex !== null &&
      checkPermissions("canUpdateShot") &&
      hasObjValueChanged(annotations[selectedShotIndex], {
        ...selectedShot,
        is_visited: true,
      })
    ) {
      dispatch(
        updateAnnotation(
          selectedShot,
          { ...selectedShot, is_visited: true },
          selectedShotIndex,
          callback,
          showUpdateMessage
        )
      );

      if (
        shotListNeedsRefresh(selectedShot, annotations, selectedShotIndex) &&
        checkPermissions("canUpdateShot")
      ) {
        dispatch(setAnnotationListingLoading(true));
      }
    }
    const selectedRow = annotations.find((item) => item.id === annotation.id);
    dispatch(setAnnotationRowIndex(selectedIndex));
    dispatch(setSelectedAnnotation(selectedRow));
    dispatch(setVideoStartTime(selectedRow?.start_time + 2));
    const indexOfSelectedAnnotation = annotations.findIndex(
      (obj) => obj.id === annotation.id
    );
    if (indexOfSelectedAnnotation + 1 > 1) {
      const lastAnnotation = annotations[indexOfSelectedAnnotation - 1];
      dispatch(setPreviousAnnotation(lastAnnotation));
    } else {
      dispatch(setPreviousAnnotation(selectedRow));
    }

    dispatch(clearBulkSelect(ANNOTATIONS_TAB));
    dispatch(addBulkSelect(annotation?.id));
  };

  useEffect(() => {
    const gridObj = gridList.find((item) => item.id === selectedShot?.grid);
    if (gridObj) {
      dispatch(setSelectedGrid(gridObj));
    } else {
      dispatch(setSelectedGrid(null));
    }
  }, [selectedShot, dispatch, gridList]);

  useEffect(() => {
    let timeoutId;

    // Function to perform scrolling after 1 second
    const scrollAfterDelay = () => {
      timeoutId = setTimeout(() => {
        // Scroll to the selected row when it changes
        const selectedRow = document.querySelector(
          ".annotations-row-selected-player"
        );
        if (selectedRow) {
          selectedRow.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 1000);
    };

    // Clear previous timeout
    clearTimeout(timeoutId);

    // Call the function to scroll after a state change
    scrollAfterDelay();

    // Cleanup function to clear the timeout when component unmounts or when state changes again
    return () => {
      clearTimeout(timeoutId);
    };
  }, [selectedShot, loading]);

  // shortcut key to select next and previous row
  const handleKey = (e) => {
    // Handle Shift + N to select next item
    if (e.shiftKey && e.key === "N") {
      e.preventDefault(); // Prevent default Ctrl+N behavior
      e.target.blur(); // Remove focus from the element
      selectNextItem();
    }
    // Handle Shift + B to select previous item
    else if (e.shiftKey && e.key === "B") {
      e.preventDefault(); // Prevent default Ctrl+B behavior
      e.target.blur(); // Remove focus from the element
      selectPreviousItem();
    }
    // Check if the Escape key is pressed
    if (e.key === "Escape") {
      // Check if there are bulk select values or if there are changes in the selected shot
      if (
        bulkSelectValues &&
        (bulkSelectValues.length !== 0 ||
          (selectedShotIndex !== null &&
            hasObjValueChanged(annotations[selectedShotIndex], selectedShot)))
      ) {
        // Inform the user that the selection has been reset
        message.info("Selection has reset");
      }

      // Find the index of the selected annotation
      const indexOfSelectedAnnotation = annotations.findIndex(
        (obj) => obj.id === selectedShot?.id
      );

      // Dispatch action to clear bulk selection
      dispatch(clearBulkSelect(ANNOTATIONS_TAB));

      // Reset form values to the selected annotation
      formik.setValues({
        ...annotations[indexOfSelectedAnnotation],
      });

      // Remove focus from the target element
      e.target.blur();
    }

    // Range Selection

    if (e.ctrlKey && e.shiftKey && e.key === "A") {
      e.preventDefault();
      // e.target.blur();
      if (bulkSelectValues && bulkSelectValues.length === 2) {
        const firstSelection = annotations.findIndex(
          (obj) => obj.id === bulkSelectValues[0]
        );
        const secondSelection = annotations.findIndex(
          (obj) => obj.id === bulkSelectValues[1]
        );

        if (firstSelection !== -1 && secondSelection !== -1) {
          const start = Math.min(firstSelection, secondSelection);
          const end = Math.max(firstSelection, secondSelection);

          const selectedAnnotations = annotations.slice(start, end + 1);
          dispatch(
            setBulkSelect(
              ANNOTATIONS_TAB,
              selectedAnnotations.map((annotation) => annotation.id)
            )
          );
        }
      }
    }
  };

  const selectNextItem = () => {
    if (annotations.length !== selectedShotIndex + 1) {
      handleRowClick(annotations[selectedShotIndex + 1], selectedShotIndex + 1);
    }
  };

  const selectPreviousItem = () => {
    if (selectedShotIndex > 0) {
      handleRowClick(annotations[selectedShotIndex - 1], selectedShotIndex - 1);
    }
  };

  const mapIdRemap = () => {
    dispatch(setAnnotationListingLoading(true));
    dispatch(reMapPointId(selectedMatch?.id));
  };

  useEffect(() => {
    // Add event listener for keydown
    window.addEventListener("keydown", handleKey);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, [selectedShotIndex, selectedShot, annotations, bulkSelectValues]);

  const onFilterChange = (filterItems) => {
    const filters = filterItems.join("&");
    dispatch(listAnnotations(selectedMatch?.id, filters));
  };

  const onFilterReset = () => {
    dispatch(listAnnotations(selectedMatch?.id));
  };

  const onUpdateShot = (updateItems) => {
    dispatch(setAnnotationListingLoading(true));
    dispatch(
      bulkUpdateAnnotation(selectedMatch?.id, {
        ...updateItems,
        ids: bulkSelectValues,
      })
    );
    dispatch(clearBulkSelect(ANNOTATIONS_TAB));
  };

  return (
    <div className="shot-list-section">
      <div className="shot-list-header-section">
        <ShotHeader
          onFilterChange={onFilterChange}
          onFilterReset={onFilterReset}
          handleShot={handleShot}
          onUpdateShot={onUpdateShot}
          mapIdRemap={mapIdRemap}
          showUpdateOption={bulkSelectValues?.length > 0 ? true : false}
        />
      </div>
      {loading && (
        <div className="shot-list-loading">
          <Spin
            indicator={<LoadingOutlined spin style={{ color: "black" }} />}
            size="large"
          />
        </div>
      )}
      <div className="shot-list">
        {!loading && dataSource?.length === 0 && (
          <div className="empty-shot-list">No Annotation Found!</div>
        )}

        {dataSource.map((annotation, index) => (
          <ShotRow
            key={index}
            selectedAnnotation={selectedShot}
            annotation={annotation}
            handleRowClick={handleRowClick}
            annotationsRef={annotationsRef}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};

export default ShotList;
