 /* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedVideo } from "../../store/selectors/Match";
import { Button, Slider, Dropdown, Switch, InputNumber } from "antd";
import {
  SettingOutlined,
  PauseOutlined,
  CaretRightOutlined,
  SoundOutlined,
  MutedOutlined,
  ForwardOutlined,
  BackwardOutlined,
} from "@ant-design/icons";
import "./VideoPlayerSection.scss";
import { convertCoordinates, secondsToHMSM } from "../../libs/utils";
import CoordinateSelector from "./KonvaComponents/CoordinateSelector";
import {
  selectGridViewEnable,
  selectNewDimension,
  selectSelectedGrid,
} from "../../store/selectors/GridConfig";
import {
  selectedGridIndex,
  setFloorCoordinatesInput,
  setGirdConfigVideoResolution,
  setWallCoordinatesInput,
} from "../../store/actions/GridConfig";
import GridLayout from "./KonvaComponents/GridLayout/GridLayout";
import {
  setDeltaTime,
  setEndDeltaTime,
  setLoopEnable,
} from "../../store/actions/Settings";
import {
  selectCurrentPage,
  isLoopEnable,
} from "../../store/selectors/Settings";
import { UndoOutlined } from "@ant-design/icons";
import { DEFAULT_DELTATIME, DEFAULT_END_DELTATIME } from "../../libs/constants";

const VideoPlayerSection = (props) => {
  const { videoPlayerRef, handleVideoProgress } = props;
  const dispatch = useDispatch();
  const selectedMatch = useSelector(selectSelectedVideo);
  const gridViewEnable = useSelector(selectGridViewEnable);
  const selectedGrid = useSelector(selectSelectedGrid);
  const newDimension = useSelector(selectNewDimension);
  const segmentLoopControl = useSelector(isLoopEnable);
  const page = useSelector(selectCurrentPage);
  const [volumeEnabled, setVolumeEnabled] = useState(true);
  const [volume, setVolume] = useState(100);
  const [isPlaying, setIsPlaying] = useState(true);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [playerWidth, setPlayerWidth] = useState(0);
  const [selectedSpeed, setSelectedSpeed] = useState(1);
  const [delta, setDelta] = useState(-0.5);
  const [endDelta, setEndDelta] = useState(0.0);
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dotRadius, setDotRadius] = useState(4);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isGridSelectable, setIsGridSelectable] = useState(false);
  const [seekDuration, setSeekDuration] = useState(2);
  const myStateRef = useRef();
  const aspectRatio = 16 / 9;
  // Calculate the height based on the width and aspect ratio
  const playerHeight = Math.round(playerWidth / aspectRatio);

  useEffect(() => {
    const zoomElement = document.querySelector(".grid-mapping-over-video");
    let zoom = 1;
    const ZOOM_SPEED = 0.1;
    zoomElement.addEventListener("wheel", (e) => {
      if (e.deltaY > 0) {
        zoomElement.style.transform =
          zoom < 4 ? `scale(${(zoom += ZOOM_SPEED)})` : `scale(${zoom})`;
        if (zoom < 4) resetRadius("less", zoom, ZOOM_SPEED, myStateRef.current);
      } else {
        zoomElement.style.transform =
          zoom > 1 ? `scale(${(zoom -= ZOOM_SPEED)})` : `scale(${zoom})`;
        if (zoom >= 1) resetRadius("add", zoom, ZOOM_SPEED, myStateRef.current);
      }
    });
    dispatch(selectedGridIndex(null));
  }, []);

  useEffect(() => {
    myStateRef.current = dotRadius; // Update the ref with the latest state value
  }, [dotRadius]);

  // get the reactPlayer width
  useEffect(() => {
    const playerWrapper = document.querySelector(".grid-config-video-players");
    if (playerWrapper) {
      const divWidth = playerWrapper.offsetWidth;
      if (divWidth !== 0) {
        setPlayerWidth(divWidth);
      }
    }
  }, [currentTime]);

  useEffect(() => {
    dispatch(
      setGirdConfigVideoResolution([
        playerWidth,
        Math.round(playerWidth / aspectRatio),
      ])
    );
  }, [playerWidth]);

  useEffect(() => {
    selectedGrid?.resolution &&
      selectedGrid.wall_input &&
      dispatch(
        setWallCoordinatesInput(
          convertCoordinates(
            selectedGrid?.resolution,
            selectedGrid?.wall_input,
            newDimension
          )
        )
      );
    selectedGrid?.resolution &&
      selectedGrid.floor_input &&
      dispatch(
        setFloorCoordinatesInput(
          convertCoordinates(
            selectedGrid?.resolution,
            selectedGrid?.floor_input,
            newDimension
          )
        )
      );
  }, [selectedGrid?.id, newDimension]);

  const videoConfig = {
    youtube: {
      playerVars: {
        modestbranding: 1,
        autoplay: 1,
        rel: 0,
      },
    },
  };

  const resetRadius = (type, zoom, ZOOM_SPEED, currRadius) => {
    if ((parseFloat(zoom.toFixed(1)) * 10) % 2 === 0) {
      if (type === "less")
        setDotRadius(parseFloat((currRadius - ZOOM_SPEED).toFixed(1)));
      else setDotRadius(parseFloat((currRadius + ZOOM_SPEED).toFixed(1)));
    }
    if (zoom === 1) {
      const dviElement = document.querySelector(".grid-mapping-over-video");
      dviElement.style.left = `0px`;
      dviElement.style.top = `0px`;
      setDotRadius(4);
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      setIsDragging(true);
      const dviElement = document.querySelector(".grid-mapping-over-video");
      setDragOffset({
        x: event.clientX - dviElement.offsetLeft,
        y: event.clientY - dviElement.offsetTop,
      });
    } else {
      setIsDragging(false);
    }
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const dviElement = document.querySelector(".grid-mapping-over-video");
    const newLeft = event.clientX - dragOffset.x;
    const newTop = event.clientY - dragOffset.y;
    dviElement.style.left = `${newLeft}px`;
    dviElement.style.top = `${newTop}px`;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleProgress = (progress) => {
    handleVideoProgress(progress);
    setCurrentTime(progress.playedSeconds);
  };

  const handleDuration = (videoDuration) => {
    setDuration(videoDuration);
  };

  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleSliderChange = (value) => {
    setCurrentTime(value);
    videoPlayerRef.current.seekTo(value, "seconds");
  };

  const handlePlaySpeed = (speed) => {
    setSelectedSpeed(speed);
  };

  const handleLoopSwitch = (checked) => {
    dispatch(setLoopEnable(checked));
  };

  const onDeltaChange = (value) => {
    setDelta(value);
  };

  const onEndDeltaChange = (value) => {
    setEndDelta(value);
  };

  useEffect(() => {
    dispatch(setDeltaTime(delta));
    dispatch(setEndDeltaTime(endDelta));
  }, [delta, endDelta]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click is outside of the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const handleSound = (sound) => {
    setVolumeEnabled(sound);
  };

  const handleVolumeChange = (volume) => {
    setVolume(volume);
  };

  const handleSeekForward = () => {
    const newTime = Math.min(currentTime + seekDuration, duration);
    setCurrentTime(newTime);
    videoPlayerRef.current.seekTo(newTime, "seconds");
  };

  const handleSeekBackward = () => {
    const newTime = Math.max(currentTime - seekDuration, 0);
    setCurrentTime(newTime);
    videoPlayerRef.current.seekTo(newTime, "seconds");
  };

  const items = [
    {
      key: "1",
      type: "group",
      label: "Playback speed :",
      children: [
        {
          key: "1-1",
          label: (
            <>
              <div
                onClick={() => handlePlaySpeed(0.25)}
                className={selectedSpeed === 0.25 ? "selected-speed" : ""}
              >
                0.25x
              </div>
            </>
          ),
        },
        {
          key: "1-2",
          label: (
            <>
              <div
                onClick={() => handlePlaySpeed(0.5)}
                className={selectedSpeed === 0.5 ? "selected-speed" : ""}
              >
                0.5x
              </div>
            </>
          ),
        },
        {
          key: "1-3",
          label: (
            <>
              <div
                onClick={() => handlePlaySpeed(0.75)}
                className={selectedSpeed === 0.75 ? "selected-speed" : ""}
              >
                0.75x
              </div>
            </>
          ),
        },
        {
          key: "1-4",
          label: (
            <>
              <div
                onClick={() => handlePlaySpeed(1)}
                className={selectedSpeed === 1 ? "selected-speed" : ""}
              >
                Normal
              </div>
            </>
          ),
        },
      ],
    },
   
    {
      key: "2",
      type: "group",
      label: "Video looping :",
      children: [
        {
          key: "2-1",
          label: (
            <>
              <Switch value={segmentLoopControl} onChange={handleLoopSwitch} />
            </>
          ),
        },
      ],
    },
    {
      key: "5",
      type: "group",
      label: "Seek Duration (sec) :",
      children: [
        {
          key: "5-1",
          label: (
            <>
               <InputNumber
                className="deltatime-input"
                min={0}
                max={60}
                step={0.5}
                value={seekDuration}
                onChange={setSeekDuration}
              />
            </>
          ),
        },
      ],
    },
    {
      key: "3",
      type: "group",
      label: "Start Delta Time :",
      children: [
        {
          key: "3-1",
          label: (
            <>
              <span>Start DeltaTime </span>
              <InputNumber
                className="deltatime-input"
                min={-5}
                max={2}
                step={0.01}
                value={delta}
                onChange={onDeltaChange}
              />
              <UndoOutlined
                className="reset-icon"
                onClick={() => {
                  dispatch(setDeltaTime(DEFAULT_DELTATIME));
                  setDelta(DEFAULT_DELTATIME);
                }}
              />
            </>
          ),
        },
      ],
    },
    {
      key: "4",
      type: "group",
      label: "End Delta Time :",
      children: [
        {
          key: "4-1",
          label: (
            <>
              <span>End DeltaTime </span>
              <InputNumber
                className="deltatime-input"
                min={0}
                max={5}
                step={0.01}
                value={endDelta}
                onChange={onEndDeltaChange}
              />
              <UndoOutlined
                className="reset-icon"
                onClick={() => {
                  dispatch(setEndDeltaTime(DEFAULT_END_DELTATIME));
                  setEndDelta(DEFAULT_END_DELTATIME);
                }}
              />
            </>
          ),
        },
      ],
    },
    

  ];

  const handleKeyDown = (e) => {
    // shortcut to mute and unmute the video sound

    if (e.ctrlKey && e.keyCode === 77) {
      // Prevent default Ctrl + M behavior
      e.preventDefault();
      // Ctrl + M (KeyCode for M is 77)
      setVolumeEnabled(!volumeEnabled);
    }

    // shortcut to on/off segment looping
    if (e.ctrlKey && e.keyCode === 76) {
      // Prevent default Ctrl + L behavior
      e.preventDefault();
      // Ctrl + L (KeyCode for L is 76)

      handleLoopSwitch(!segmentLoopControl);
    }

    // shortcut to play and pause the video
    if (e.ctrlKey && e.keyCode === 32) {
      // Prevent default Ctrl + Space behavior
      e.preventDefault();
      // Ctrl + Space (KeyCode for Space is 32)
      handlePlayPause();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown); // Attach event listener to window
    return () => {
      window.removeEventListener("keydown", handleKeyDown); // Clean up event listener
    };
  }, [volumeEnabled, segmentLoopControl, isPlaying]);

  return (
    <div
      className="grid-config-video-container"
      style={{ maxWidth: page === "detail" ? "122vh" : "100%" }}
    >
      <div className="div-zoom">
        <div
          className="grid-mapping-over-video"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseUp}
          draggable="false"
        >
          <ReactPlayer
            url={selectedMatch?.url}
            className="grid-config-video-players"
            controls={false}
            playbackRate={selectedSpeed}
            ref={videoPlayerRef}
            width="100%"
            height={`${playerHeight}px`}
            loop={true}
            playing={isPlaying}
            onProgress={handleProgress}
            config={videoConfig}
            onDuration={handleDuration}
            volume={volumeEnabled ? volume / 100 : 0}
            onPause={() => setIsPlaying(false)}
            onPlay={() => setIsPlaying(true)}
          />
          {!gridViewEnable && (
            <CoordinateSelector
              width={playerWidth}
              height={playerHeight}
              dotRadius={dotRadius}
            />
          )}
          {gridViewEnable && selectedGrid && (
            <div className={`grid-layout ${!isGridSelectable && 'unselectable'}`}>
              <GridLayout width={playerWidth} height={playerHeight} />
            </div>
          )}
        </div>
        <div className="video-controls-container">
          <div className="video-controls">
          <Button
              className="seek-button"
              type="text"
              icon={<BackwardOutlined style={{ fontSize: "20px", padding: "6px" }} />}
              onClick={handleSeekBackward}
            />

            <Button
              type="text"
              icon={
                isPlaying ? (
                  <PauseOutlined style={{ fontSize: "20px", padding: "6px" }} />
                ) : (
                  <CaretRightOutlined
                    style={{ fontSize: "25px", padding: "4px" }}
                  />
                )
              }
              onClick={handlePlayPause}
            />
              <Button
              type="text"
              className="seek-button"
              icon={<ForwardOutlined style={{ fontSize: "20px", padding: "6px" }} />}
              onClick={handleSeekForward}
            />
            <Slider
              className="video-slider"
              min={0}
              max={duration}
              step={1}
              value={currentTime}
              onChange={handleSliderChange}
              tooltipVisible={false}
              style={{ flex: 1, marginLeft: "10px", minWidth: "200px" }}
            />

            <Dropdown
              ref={dropdownRef}
              className="video-setting-icon"
              visible={visible}
              onVisibleChange={handleVisibleChange}
              menu={{
                items,
              }}
              placement="bottomLeft"
            >
              <div onClick={(e) => e.persist()}>
                <SettingOutlined />
              </div>
            </Dropdown>
          </div>
          <div className="bottom-control">
            <p className="run-time">{`${secondsToHMSM(
              currentTime
            )} / ${secondsToHMSM(duration)}`}</p>
            {page === "detail" &&
              <div className="coordinate-selection-switch">
                <span>Grid Selection</span>
                <Switch size="small" value={isGridSelectable} onChange={setIsGridSelectable} />
              </div>}
              
            <div className="sound-control">
              {volumeEnabled && (
                <SoundOutlined
                  className="sound-icon"
                  onClick={() => handleSound(false)}
                />
              )}
              {!volumeEnabled && (
                <MutedOutlined
                  className="sound-icon"
                  onClick={() => handleSound(true)}
                />
              )}
              <Slider
                className="volume-slider"
                min={0}
                max={100}
                value={volume}
                disabled={!volumeEnabled}
                onChange={handleVolumeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayerSection;