import React from "react";
import "../CourtChart/CourtChart.scss";
import CourtLayout from "../CourtChart/CourtLayout/CourtLayout";
import StatsTable from "../CourtChart/StatsTable/ShotStatsTable";
import { hasIntegerKeys } from "../../../libs/utils";

const CommonCourtView= React.memo((props) => {
  const { courtData ,title} = props;
  const showGridDetails = (tableData) =>{
    let tableJsx=[];
    if(hasIntegerKeys(tableData) && /^\d+$/.test(tableData["1"])){
      tableJsx.push(<><div className="tab-row">
        <div className="table-layout">
      <StatsTable
        selectedGrid={null}
        analyzedData={
          tableData
        }
      />
    </div></div></>)
    }
    else{
      let rowCount=4;
      let colCount=3;
      for (let i=1;i<=rowCount;i++){
        let columnData=[];
        for (let col=((i-1) * colCount); col < i*colCount; col++){
          if(Object.keys(tableData).length > col){
            columnData.push(<div className="table-layout">
            <StatsTable
              selectedGrid={col+1}
              analyzedData={
                tableData[col+1]
              }
            />
          </div>)
          }
      }
      if(!!columnData && columnData.length>0){
        tableJsx.push(<><div className="tab-row">{columnData}</div></>)
      }
      }
    
    }
    
    return <div className="tab-container">{tableJsx}</div>;
  }
  return (
    <>
     <h3 className="court-title">{title}</h3>
      <div className="court-table">
        <div className="court-layout">
          <CourtLayout
            selectedGrid={0}
            data={courtData}
            clWidth={'350px'}
            clHeight={'600px'}
          />
        </div>
        
        {showGridDetails(courtData)}
        {/* {hasIntegerKeys(courtData) && /^\d+$/.test(courtData["1"]) ? 
        null : <div className="table-layout">
        <StatsTable
          selectedGrid={1}
          analyzedData={
            courtData[1]
          }
        />
      </div>} */}
      </div>
    </>
  );
})
export default CommonCourtView;
