 /* eslint-disable */
import { useEffect, useRef, useState } from "react";
import VideoPlayerSection from "../GridConfigPage/VideoPlayerSection";
import CreateShotDataDrawer from "./CreateShotDataDrawer/CreateShotDataDrawer";
import ReviewTopNavigation from "./ReviewTopNavigation/ReviewTopNavigation";
import { Col, Row, message } from "antd";
import "./ReviewPage.scss";
import ShotList from "./ShotList/ShotList";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  selectAnnotation,
  selectAnnotations,
} from "../../store/selectors/ReviewDetails";
import {
  addAnnotation,
  setAnnotationRowIndex,
  setSelectedAnnotation,
} from "../../store/actions/ReviewDetails";
import { selectSelectedVideo } from "../../store/selectors/Match";
import {
  getGridList,
  setGirdViewEnable,
  setHitOrBounce,
} from "../../store/actions/GridConfig";
import {
  isLoopEnable,
  selectDeltaTime,
  selectEndDeltaTime,
} from "../../store/selectors/Settings";
import { setCurrentPage } from "../../store/actions/Settings";
import BottomSection from "./BottomSectionFields/BottomSection";
import RightSection from "./RightSectionFields/RightSection";
import { findIndexById } from "../../libs/utils";
import useLocalValt from "../../hooks/useLocalValt";

const ReviewPage = () => {
  const videoPlayerRef = useRef(null);
  const shotList = useSelector(selectAnnotations);
  const selectedShot = useSelector(selectAnnotation);
  const match = useSelector(selectSelectedVideo);
  const endTime = selectedShot?.end_time;
  const loopEnable = useSelector(isLoopEnable);
  const deltaTime = useSelector(selectDeltaTime);
  const endTimeDelta = useSelector(selectEndDeltaTime);
  const loopStart = selectedShot?.start_time;
  const [loopDuration, setLoopDuration] = useState(3);
  const [lastVisitedAnnotation, setLastVisitedAnnotation] = useLocalValt("pointId");

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...selectedShot,
    },
  });

  // Helper function to validate and parse an integer from a given value
  const validateAndParseInteger = (value) => {
    const parsedValue = parseInt(value, 10);
    return Number.isInteger(parsedValue) ? parsedValue : null;
  };

  // Helper function to show error messages based on the field name
  const showErrorMessage = (fieldName, input) => {
    const messages = {
      init_ball_position: `${input} is not a valid choice for Source.`,
      dest_ball_position: `${input} is not a valid choice for Destination.`,
      front_wall_position: `${input} is not a valid choice for Front Wall.`,
    };
    message.error(messages[fieldName]);
  };

  // Helper function to handle changes in ball position inputs
  const handlePositionInputChange = (fieldName, value) => {
    if (value === null ||
      value === "") {
      formik.setFieldValue(fieldName, null);
    }
    else if (
      value === 0 || value > 12 ||
      !Number.isInteger(value)
    ) {
      showErrorMessage(fieldName, value);
      // formik.setFieldValue(fieldName, null);
    } else {
      formik.setFieldValue(fieldName, value);
    }
  };

  // Main function to handle input changes
  const handleInputChange = (fieldName, event, player) => {
    if (fieldName === "current_score") {
      // Validate and parse the input value as an integer
      const value = validateAndParseInteger(event); // Antd Number Input returns value directly
      if (value !== null) {
        // Initialize currentScore based on formik values, ensure it's an array with default values
        const currentScore = Array.isArray(formik.values?.current_score)
          ? formik.values.current_score
          : [0, 0];

        // Ensure the player index is valid (0 or 1)
        if (player === 0 || player === 1) {
          // Update the current score based on the player index
          const updatedScore =
            player === 0 ? [value, currentScore[1]] : [currentScore[0], value];
          // Update the formik field value for current_score
          formik.setFieldValue(fieldName, updatedScore);
        }
      }
    } else if (
      [
        "init_ball_position",
        "dest_ball_position",
        "front_wall_position",
      ].includes(fieldName)
    ) {
      // For ball position fields, handle input changes separately
      const inputValue = validateAndParseInteger(event.target.value);
      handlePositionInputChange(fieldName, inputValue);
    } else if (fieldName === "point_id") {
      const value = validateAndParseInteger(event);
      if (value >= 101 && value <= 599) {
        formik.setFieldValue(fieldName, value);
      }
    } else {
      formik.handleChange(event);
    }
  };
  useEffect(() => {
    // Update formik initial values when selectedShot changes
    formik.setValues({
      ...selectedShot,
    });
    videoPlayerRef.current.seekTo(selectedShot?.start_time);

    if ((match?.id, selectedShot?.id)) {
      setLastVisitedAnnotation({ [match?.id]: selectedShot?.id });
    }

  }, [
    selectedShot?.id,
    selectedShot?.point_id,
    selectedShot?.front_wall_position,
    selectedShot?.init_ball_position,
    selectedShot?.dest_ball_position,
    selectedShot?.current_score,
    selectedShot?.updated_at,
  ]);

  useEffect(() => {
    match?.id && dispatch(getGridList(match?.id));
  }, [dispatch, match?.id]);

  useEffect(() => {
    // Dispatch changes whenever values change
    dispatch(setSelectedAnnotation(formik.values));
  }, [formik.values, dispatch]);

  const handleHitOrBounce = (value) => {
    dispatch(setHitOrBounce(value));
  };

  const handleActivePlayer = (value) => {
    formik.setFieldValue("player", value);
    // const newSelectedShot = { ...selectedShot, player: value, xyz: "abc" }; // {xyz: "abc"} to tiger update call
    // dispatch(setSelectedAnnotation(newSelectedShot));

    // // updated the change in active player in Shot list
    // const newShotList = [...shotList];
    // const updatedSelectedShot = { ...selectedShot, player: value };
    // newShotList[updateAnnotationRowIndex] = updatedSelectedShot;
    // dispatch(setShotList(newShotList));
  };

  const handleShot = () => {
    const annotationCreationData = {
      player:
        formik.values?.player === 1
          ? 2
          : formik.values?.player === 2
            ? 1
            : null,
      start_time: selectedShot?.start_time,
      match_id: match.id,
    };
    dispatch(addAnnotation(match.id, annotationCreationData));
  };

  // this function is to handle video loop
  const handleVideoProgress = (progress) => {
    // const { playedSeconds } = progress;
    // const adjustedLoopStart = loopStart > 1 ? loopStart + deltaTime : loopStart;
    // const loopEnd = endTime ? endTime + endTimeDelta : loopStart + loopDuration;
    // if (playedSeconds < adjustedLoopStart || playedSeconds > loopEnd) {
    //   loopEnable && videoPlayerRef.current.seekTo(adjustedLoopStart);
    // }
  };

  // this useEffect is to handle video loop
  useEffect(() => {
    const intervalId = setInterval(() => {
      const playedSeconds = videoPlayerRef.current.getCurrentTime();
      const adjustedLoopStart =
        loopStart > 1 ? loopStart + deltaTime : loopStart;
      const loopEnd = endTime
        ? endTime + endTimeDelta
        : loopStart + loopDuration;
      if (playedSeconds < adjustedLoopStart || playedSeconds > loopEnd) {
        loopEnable && videoPlayerRef.current.seekTo(adjustedLoopStart);
      }
    }, 100); // Interval of 100 milliseconds (1000ms / 10)

    return () => {
      clearInterval(intervalId); // Cleanup function to clear the interval when the component unmounts
    };
  }, [loopStart, deltaTime, endTime, endTimeDelta, loopDuration, loopEnable]);

  // to set current view page
  useEffect(() => {
    dispatch(setCurrentPage("detail"));
    dispatch(setGirdViewEnable(true));
  }, [dispatch]);

  // set first shot selected in the list when page loads
  const isMounted = useRef(false);
  useEffect(() => {
    if (shotList?.length > 0) {
      if (!isMounted.current) {
        const lastIndex = lastVisitedAnnotation && lastVisitedAnnotation[match?.id]
          ? findIndexById(shotList, lastVisitedAnnotation[match?.id])
          : 0;
        const index = shotList[lastIndex] ? lastIndex : 0;
        dispatch(setSelectedAnnotation(shotList[index]));
        dispatch(setAnnotationRowIndex(index));
        isMounted.current = true;
      } else if (selectedShot) {
        const index = findIndexById(shotList, selectedShot?.id);
        dispatch(setAnnotationRowIndex(index));
      }
    }
  }, [shotList, dispatch]);

  return (
    <div className="reviewPage-container">
      <ReviewTopNavigation />
      <div className="row-wrapper">
        <Row style={{ height: "calc(100vh - 48px)" }}>
          <Col span={4} className="shot-list-container">
            <ShotList handleShot={handleShot} formik={formik} />
          </Col>
          <Col span={20} >
            <form onSubmit={formik.handleSubmit}>
              <div className="video-right-bottom-wrapper">
                <div className="mid-row-wrapper">
                  <Row>
                    <Col span={19}>
                      <VideoPlayerSection
                        videoPlayerRef={videoPlayerRef}
                        handleVideoProgress={handleVideoProgress}
                      />
                      <Col >
                        <div className="bottom-wrapper">
                          <div className="bottom-section-wrapper">
                            <BottomSection
                              formik={formik}
                              handleInputChange={handleInputChange}
                              handleActivePlayer={handleActivePlayer}
                              handleHitOrBounce={handleHitOrBounce}
                            />
                          </div>
                        </div>
                      </Col>
                    </Col>
                    <Col span={5}>
                      <RightSection
                        formik={formik}
                        videoPlayerRef={videoPlayerRef}
                        handleInputChange={handleInputChange}
                        handleActivePlayer={handleActivePlayer}
                        handleHitOrBounce={handleHitOrBounce}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
          </Col>
        </Row>
        <div className="shot-drawer-wrapper">
          <CreateShotDataDrawer formik={formik} />
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;
