import {
  IS_ADD_ANNOTATION_MODAL_OPEN,
  SET_PIN_MODE,
  SET_VIDEO_START_TIME,
} from "../actionTypes/AnnotationModal";

const initialState = {
  isModalOpen: true,
  startTime: 0,
  pinMode: true,
};

export const annotationModal = (state = initialState, action) => {
  switch (action.type) {
    case IS_ADD_ANNOTATION_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case SET_VIDEO_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      };
    case SET_PIN_MODE:
      return {
        ...state,
        pinMode: action.payload,
      };
    default:
      return state;
  }
};
