import GridConfigActions from "./GridConfigActions/GridConfigActions";
import "./GridConfigSection.scss";
import GridDropDown from "./GridDropDown/GridDropDown";
import GridTabs from "./GridTabs/GridTabs";

const GridConfigSection = (props) => {
  const { GridConfigPlayerRef } = props;
  return (
    <div className="gridConfig-section-container">
      <div className="gridConfig-section-top-content">
        <GridDropDown />
        <GridTabs GridConfigPlayerRef={GridConfigPlayerRef} />
      </div>
      <div className="gridConfig-section-bottom-content">
        <GridConfigActions />
      </div>
    </div>
  );
};

export default GridConfigSection;
