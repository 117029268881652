import { useEffect, useState } from "react";
import "./CourtLayout.scss";

const CourtLayout = (props) => {
  const { handleGridClick, selectedGrid, data } = props;
  const [gridPercentages, setGridPercentages] = useState([]);
  const [isSinglePercentage, setIsSinglePercentage] = useState(false);

  // Calculate grid percentage data
  useEffect(() => {
    const calculateGridPercentages = () => {
      const gridPercentages = {};
      for (let gridNum in data) {
        const gridData = data[gridNum];
        const firstItemKey = gridData && Object.keys(gridData)[0];
        const firstItemValue = gridData[firstItemKey];
        const secondItemKey = gridData && Object.keys(gridData)[1];
        const secondItemValue = gridData[secondItemKey];
        const thirdItemKey = gridData && Object.keys(gridData)[2];
        const thirdItemValue = gridData[thirdItemKey];
        const totalCount = firstItemValue + secondItemValue + thirdItemValue;
        gridPercentages[gridNum] = {
          first: firstItemValue === 0 ? 0 : (firstItemValue / totalCount) * 100,
          second:
            secondItemValue === 0 ? 0 : (secondItemValue / totalCount) * 100,
          third: thirdItemValue === 0 ? 0 : (thirdItemValue / totalCount) * 100,
        };
      }
      setGridPercentages(gridPercentages);
    };

    data && calculateGridPercentages();

    if (typeof data["1"] === "object") {
      // The value is an object
      setIsSinglePercentage(false);
    }

    // Check if the value of a specific key is an integer
    if (Number.isInteger(data["1"])) {
      // The value is an integer
      setIsSinglePercentage(true);
    }
  }, [data]);

  const PercentageSection = (gridData) => {
    let isValueZero = false;
    const firstValue = parseFloat(gridData?.gridData?.first?.toFixed(2));
    const secondValue = parseFloat(gridData?.gridData?.second?.toFixed(2));
    const thirdValue = parseFloat(gridData?.gridData?.third?.toFixed(2));
    const totalPercentage = firstValue + secondValue + thirdValue;

    if (totalPercentage === 0) {
      isValueZero = true;
    }
    return (
      <>
        {!isValueZero && gridData?.gridData && (
          <div className="percentage-section">
            {firstValue !== 0 && (
              <div className="low-section" style={{ height: `${firstValue}%` }}>
                {firstValue}%
              </div>
            )}
            {secondValue !== 0 && (
              <div
                className="med-section"
                style={{ height: `${secondValue}%` }}
              >
                {secondValue}%
              </div>
            )}
            {thirdValue !== 0 && (
              <div
                className="high-section"
                style={{ height: `${thirdValue}%` }}
              >
                {thirdValue}%
              </div>
            )}
          </div>
        )}
        {(isValueZero || !gridData?.gridData) && (
          <div className="percentage-section no-shots">No Shots</div>
        )}
      </>
    );
  };

  const SinglePercentage = ({ count }) => {
    const sum = Object.values(data).reduce((acc, value) => acc + value, 0);
    const percentage = ((count / sum) * 100)?.toFixed(2);
    return (
      <div className="single-percentage">
        <span className="percentage-text">{percentage}%</span>
      </div>
    );
  };

  return (
    <>
      <div className="court-outer-layout" style={{height: props.clHeight,width: props.clWidth}}>
        <div className="court-first-row">
          <div
            className={`court-first-row-col-1 ${
              selectedGrid === 1 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(1) :null}
          >
            {/* <span className="grid-numbers">1</span> */}
            {isSinglePercentage && (
              <SinglePercentage count={data && data["1"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["1"]}
              />
            )}
          </div>
          <div
            className={`court-first-row-col-2 ${
              selectedGrid === 2 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(2): null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["2"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["2"]}
              />
            )}
          </div>
          <div
            className={`court-first-row-col-3 ${
              selectedGrid === 3 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(3) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["3"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["3"]}
              />
            )}
          </div>
        </div>
        <div className="court-second-row">
          <div
            className={`court-second-row-col-1 ${
              selectedGrid === 4 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(4) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["4"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["4"]}
              />
            )}
          </div>
          <div
            className={`court-second-row-col-2 ${
              selectedGrid === 5 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(5) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["5"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["5"]}
              />
            )}
          </div>
          <div
            className={`court-second-row-col-3 ${
              selectedGrid === 6 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(6) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["6"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["6"]}
              />
            )}
          </div>
        </div>
        <div className="court-third-row">
          <div
            className={`court-third-row-col-1 ${
              selectedGrid === 7 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(7) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["7"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["7"]}
              />
            )}
          </div>
          <div
            className={`court-third-row-col-2 ${
              selectedGrid === 8 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(8) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["8"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["8"]}
              />
            )}
          </div>
          <div
            className={`court-third-row-col-3 ${
              selectedGrid === 9 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(9) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["9"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["9"]}
              />
            )}
          </div>
        </div>
        <div className="court-fourth-row">
          <div
            className={`court-fourth-row-col-1 ${
              selectedGrid === 10 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(10) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["10"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["10"]}
              />
            )}
          </div>
          <div
            className={`court-fourth-row-col-2 ${
              selectedGrid === 11 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(11) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["11"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["11"]}
              />
            )}
          </div>
          <div
            className={`court-fourth-row-col-3 ${
              selectedGrid === 12 ? "selected-grid" : ""
            }`}
            onClick={!!handleGridClick ? () => handleGridClick(12) : null}
          >
            {isSinglePercentage && (
              <SinglePercentage count={data && data["12"]} />
            )}
            {!isSinglePercentage && (
              <PercentageSection
                gridData={gridPercentages && gridPercentages["12"]}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CourtLayout;
