export const selectAnnotation = (state) => {
  return state.annotationReducer.selectedAnnotation;
};
export const selectPreviousAnnotation = (state) =>
  state.annotationReducer.previousAnnotation;
export const selectPartialUpdateAnnotation = (state) =>
  state.annotationReducer.partialUpdateAnnotation;
export const selectAnnotationRowIndex = (state) =>
  state.annotationReducer.selectedAnnotationRowIndex;

export const selectAnnotations = (state) => state?.annotationListReducer || [];
