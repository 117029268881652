export const matchOrSetItems = [
  {
    label: "Match",
    key: "match",
  },
  {
    label: "Set 1",
    key: "0",
  },
  {
    label: "Set 2",
    key: "1",
  },
  {
    label: "Set 3",
    key: "2",
  },
  {
    label: "Set 4",
    key: "3",
  },
  {
    label: "Set 5",
    key: "4",
  },
];

export const tableItems = [
  {
    label: "Quality of shot against court target area",
    key: "court_dest_position_vs_quality_table",
  },
  {
    label: "Quality of shot against court position",
    key: "init_position_vs_quality_table",
  },
  {
    label: "Pushing the opponent BACK (court position of opponent)",
    key: "opponent_pushing_back",
  },
  {
    label: "Pushing the opponent BACK SUMMARY (court position of opponent)",
    key: "opponent_summary",
  },
  {
    label: "Quality of shot against court position; and opponent shot quality",
    key: "opponent_shot_quality",
  },
  {
    label: "Quality of shot, court target area vs shot type",
    key: "quality_of_shot_court_target_area_vs_shottype",
  },
  {
    label: "Quality of shot, court position area vs shot type",
    key: "quality_of_shot_court_position_area_vs_shottype",
  },
  {
    label: "Court Tracker (No Serve)",
    key: "court_tracker_no_serve",
  },
  {
    label:
      "Target area analysis (Against court position, where the ball is being hit)",
    key: "target_area_analysis",
  },
];

export const ShotTypeItems = [
  {
    label: "Serve",
    key: "SER",
  },
  {
    label: "Straight Drive",
    key: "DRI",
  },
  {
    label: "Drop",
    key: "DRP",
  },
  {
    label: "Lob",
    key: "LOB",
  },
  {
    label: "Boast",
    key: "BOA",
  },
  {
    label: "Reverse Boast",
    key: "REV",
  },
  {
    label: "Volley Drive",
    key: "VOD",
  },
  {
    label: "Volley Kill",
    key: "VOK",
  },
  {
    label: "Volley Lob",
    key: "VOL",
  },
  {
    label: "Cross Drive",
    key: "CDI",
  },
  {
    label: "Cross Drop",
    key: "CDP",
  },
  {
    label: "Cross Lob",
    key: "CLB",
  },
  {
    label: "Cross Volley Lob",
    key: "CVL",
  },
  {
    label: "Cross Volley Kill",
    key: "CVK",
  },
];

export const shotKeysArray = [
  "SER",
  "DRI",
  "DRP",
  "LOB",
  "BOA",
  "REV",
  "VOD",
  "VOK",
  "VOL",
  "CDI",
  "CDP",
  "CLB",
  "CVL",
  "CVK",
];
