 /* eslint-disable */
import React, { useEffect, useState } from "react";
import { Line } from "react-konva";
import { useSelector } from "react-redux";
import { selectInitialDimension } from "../../../../store/selectors/GridConfig";
import { convertCoordinates } from "../../../../libs/utils";

const WallGridLayout = (props) => {
  const { dimensions, wallCoordinates } = props;
  const initialDimensions = useSelector(selectInitialDimension);
  const [coordinates, setCoordinates] = useState(wallCoordinates);

  useEffect(() => {
    wallCoordinates?.length === 20 &&
      setCoordinates(
        convertCoordinates(initialDimensions, wallCoordinates, dimensions)
      );
  }, [dimensions, wallCoordinates]);

  return (
    <>
      {coordinates?.length > 0 && (
        <div>
          {/* horizontal line 1 */}
          <Line
            points={[
              coordinates[0][0],
              coordinates[0][1],
              coordinates[1][0],
              coordinates[1][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[1][0],
              coordinates[1][1],
              coordinates[2][0],
              coordinates[2][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[2][0],
              coordinates[2][1],
              coordinates[3][0],
              coordinates[3][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[3][0],
              coordinates[3][1],
              coordinates[4][0],
              coordinates[4][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* horizontal line 2 */}
          <Line
            points={[
              coordinates[5][0],
              coordinates[5][1],
              coordinates[6][0],
              coordinates[6][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[6][0],
              coordinates[6][1],
              coordinates[7][0],
              coordinates[7][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[7][0],
              coordinates[7][1],
              coordinates[8][0],
              coordinates[8][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[8][0],
              coordinates[8][1],
              coordinates[9][0],
              coordinates[9][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* horizontal line 3 */}
          <Line
            points={[
              coordinates[10][0],
              coordinates[10][1],
              coordinates[11][0],
              coordinates[11][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[11][0],
              coordinates[11][1],
              coordinates[12][0],
              coordinates[12][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[12][0],
              coordinates[12][1],
              coordinates[13][0],
              coordinates[13][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[13][0],
              coordinates[13][1],
              coordinates[14][0],
              coordinates[14][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* horizontal line 4 */}
          <Line
            points={[
              coordinates[15][0],
              coordinates[15][1],
              coordinates[16][0],
              coordinates[16][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[16][0],
              coordinates[16][1],
              coordinates[17][0],
              coordinates[17][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[17][0],
              coordinates[17][1],
              coordinates[18][0],
              coordinates[18][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[18][0],
              coordinates[18][1],
              coordinates[19][0],
              coordinates[19][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* vertical line 1 */}
          <Line
            points={[
              coordinates[0][0],
              coordinates[0][1],
              coordinates[5][0],
              coordinates[5][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[5][0],
              coordinates[5][1],
              coordinates[10][0],
              coordinates[10][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[10][0],
              coordinates[10][1],
              coordinates[15][0],
              coordinates[15][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* vertical line 2 */}
          <Line
            points={[
              coordinates[1][0],
              coordinates[1][1],
              coordinates[6][0],
              coordinates[6][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[6][0],
              coordinates[6][1],
              coordinates[11][0],
              coordinates[11][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[11][0],
              coordinates[11][1],
              coordinates[16][0],
              coordinates[16][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* vertical line 3 */}
          <Line
            points={[
              coordinates[2][0],
              coordinates[2][1],
              coordinates[7][0],
              coordinates[7][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[7][0],
              coordinates[7][1],
              coordinates[12][0],
              coordinates[12][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[12][0],
              coordinates[12][1],
              coordinates[17][0],
              coordinates[17][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* vertical line 4 */}
          <Line
            points={[
              coordinates[3][0],
              coordinates[3][1],
              coordinates[8][0],
              coordinates[8][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[8][0],
              coordinates[8][1],
              coordinates[13][0],
              coordinates[13][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[13][0],
              coordinates[13][1],
              coordinates[18][0],
              coordinates[18][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          {/* vertical line 5 */}
          <Line
            points={[
              coordinates[4][0],
              coordinates[4][1],
              coordinates[9][0],
              coordinates[9][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[9][0],
              coordinates[9][1],
              coordinates[14][0],
              coordinates[14][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />

          <Line
            points={[
              coordinates[14][0],
              coordinates[14][1],
              coordinates[19][0],
              coordinates[19][1],
            ]}
            stroke="blue"
            strokeWidth={1}
          />
        </div>
      )}
    </>
  );
};

export default WallGridLayout;
