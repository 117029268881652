import React from "react";
import { Button, Dropdown } from "antd";
import "./ThreeDotMenu.scss";
import { MoreOutlined } from "@ant-design/icons";

/**
 * ThreeDotMenu Component
 * @param {Object} props - The component props
 * @param {Array} props.items - The items to be displayed in the menu
 * @returns {JSX.Element|null} - The ThreeDotMenu component JSX
 */
const ThreeDotMenu = ({ items, onClick, ...props }) => {
  // Filter items to exclude those with 'protect' set to true
  const filteredItems = items.filter(item => !item.protected);

  // Check if 'filteredItems' prop is empty or not an array
  if (!filteredItems || filteredItems.length === 0) {
    // console.error("ThreeDotMenu: 'items' prop is missing or not an array");
    return null; // or return some default content
  }

  if (filteredItems.length === 1) {
    return (
      <Button
        className={filteredItems[0]?.className}
        icon={filteredItems[0]?.icon}
        type="text"
        onClick={filteredItems[0]?.onClick}
      >
        <span className={`${filteredItems[0]?.className}-label`}>
          {filteredItems[0]?.label}
        </span>
      </Button>
    );
  }

  return (
    // Container for the three-dot menu
    <div className="three-dot-menu">
      {/* Ant Design Dropdown component */}
      <Dropdown menu={{ items: filteredItems, onClick }} {...props} placement="bottomRight">
        {/* Three dots icon */}
        <MoreOutlined style={{ fontSize: "20px" }} />
      </Dropdown>
    </div>
  );
};

export default ThreeDotMenu;
