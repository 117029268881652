import React from "react";
import "./FloorGridPointsInput.scss";
import CircularGridPoint from "../CircularGridPoint/CircularGridPoint";
import { useSelector, useDispatch } from "react-redux";
import { selectedGridIndex } from "../../../../../store/actions/GridConfig";
import {
  selectFloorInput,
  getGridIndex,
} from "../../../../../store/selectors/GridConfig";

const FloorGridPointsInput = () => {
  const dispatch = useDispatch();
  const floorInput = useSelector(selectFloorInput);
  const gridIndex = useSelector(getGridIndex);
  const isWithinInputRange = (id) => !!floorInput && floorInput[id-1]?.length;
  const nextInputPoint = (id) => !!floorInput && floorInput[id-1] === null && gridIndex === id-1;

  const handleIndex = (event, index) => {
    dispatch(selectedGridIndex(index));
  };

  return (
    <div className="floorGridPointsInput-container">
      <div className="floor-main-box">
        <CircularGridPoint
          id={1}
          onClick={(e) => handleIndex(e, 0)}
          style={{
            background: isWithinInputRange(1)
              ? "#00A524"
              : nextInputPoint(1)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 0 ? "solid" : "none",
            borderColor: gridIndex === 0 ? "red" : "",
          }}
        />
        <CircularGridPoint
          id={2}
          onClick={(e) => handleIndex(e, 1)}
          style={{
            background: isWithinInputRange(2)
              ? "#00A524"
              : nextInputPoint(2)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 1 ? "solid" : "none",
            borderColor: gridIndex === 1 ? "red" : "",
          }}
        />
        <CircularGridPoint
          id={11}
          onClick={(e) => handleIndex(e, 10)}
          style={{
            background: isWithinInputRange(11)
              ? "#00A524"
              : nextInputPoint(11)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 10 ? "solid" : "none",
            borderColor: gridIndex === 10 ? "red" : "",
          }}
        />
        <CircularGridPoint
          id={12}
          onClick={(e) => handleIndex(e, 11)}
          style={{
            background: isWithinInputRange(12)
              ? "#00A524"
              : nextInputPoint(12)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 11 ? "solid" : "none",
            borderColor: gridIndex === 11 ? "red" : "",
          }}
        />
        <div className="floor-top-box"></div>
        <div className="floor-bottom-box">
          <div className="floor-bottom-box-left">
            <div className="floor-small-box-left">
              <CircularGridPoint
                id={3}
                onClick={(e) => handleIndex(e, 2)}
                style={{
                  background: isWithinInputRange(3)
                    ? "#00A524"
                    : nextInputPoint(3)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 2 ? "solid" : "none",
                  borderColor: gridIndex === 2 ? "red" : "",
                }}
              />
              <CircularGridPoint
                id={4}
                onClick={(e) => handleIndex(e, 3)}
                style={{
                  background: isWithinInputRange(4)
                    ? "#00A524"
                    : nextInputPoint(4)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 3 ? "solid" : "none",
                  borderColor: gridIndex === 3 ? "red" : "",
                }}
              />
              <CircularGridPoint
                id={7}
                onClick={(e) => handleIndex(e, 6)}
                style={{
                  background: isWithinInputRange(7)
                    ? "#00A524"
                    : nextInputPoint(7)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 6 ? "solid" : "none",
                  borderColor: gridIndex === 6 ? "red" : "",
                }}
              />
              <CircularGridPoint
                id={8}
                onClick={(e) => handleIndex(e, 7)}
                style={{
                  background: isWithinInputRange(8)
                    ? "#00A524"
                    : nextInputPoint(8)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 7 ? "solid" : "none",
                  borderColor: gridIndex === 7 ? "red" : "",
                }}
              />
            </div>
          </div>
          <div className="floor-bottom-box-right">
            <div className="floor-small-box-right">
              <CircularGridPoint
                id={5}
                onClick={(e) => handleIndex(e, 4)}
                style={{
                  background: isWithinInputRange(5)
                    ? "#00A524"
                    : nextInputPoint(5)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 4 ? "solid" : "none",
                  borderColor: gridIndex === 4 ? "red" : "",
                }}
              />
              <CircularGridPoint
                id={6}
                onClick={(e) => handleIndex(e, 5)}
                style={{
                  background: isWithinInputRange(6)
                    ? "#00A524"
                    : nextInputPoint(6)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 5 ? "solid" : "none",
                  borderColor: gridIndex === 5 ? "red" : "",
                }}
              />
              <CircularGridPoint
                id={9}
                onClick={(e) => handleIndex(e, 8)}
                style={{
                  background: isWithinInputRange(9)
                    ? "#00A524"
                    : nextInputPoint(9)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 8 ? "solid" : "none",
                  borderColor: gridIndex === 8 ? "red" : "",
                }}
              />
              <CircularGridPoint
                id={10}
                onClick={(e) => handleIndex(e, 9)}
                style={{
                  background: isWithinInputRange(10)
                    ? "#00A524"
                    : nextInputPoint(10)
                    ? "#FF7A00"
                    : "",
                  borderStyle: gridIndex === 9 ? "solid" : "none",
                  borderColor: gridIndex === 9 ? "red" : "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorGridPointsInput;
