import {
  GET_MATCHES,
  SET_MATCH_LIST,
  SET_SELECTED_MATCH,
} from "../actionTypes/Match";
import { actionFactory } from "../../libs/actionFactory";
import { setVideosListingLoading } from "./User";
import { setVideoStartTime } from "./AnnotationModal";
import { message } from "antd";

export const addMatch = (match) => {
  return actionFactory({
    api: "post/api/matches/",
    actionBody: {
      body: match,
    },
    failureToast: false,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(listVideos());
          dispatch(setVideoStartTime(0));
          message.success(`Match "${match?.title}" added successfully.`);
        })
        .catch((err) => {
          const errorMessage =
            err?.response?.data?.status?.status_message?.title;
          message.error(`Error: ${errorMessage}`, 5);
        });
    },
  });
};

export const updateMatch = (match) => {
  return actionFactory({
    api: `patch/api/matches/${match.id}/`,
    actionBody: { body: match },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(setSelectedVideo(resp.data));
          dispatch(listVideos());
          message.success(
            `Match ${resp?.data?.data?.title} updated successfully `
          );
        })
        .catch((err) => {
          // const errorMessage =
          //   err?.response?.data?.status?.status_message;
          // message.error(`Error: ${errorMessage}`, 5);
          dispatch(listVideos());
        });
    },
  });
};

export const setSelectedVideo = (selectedMatch) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_MATCH,
    payload: selectedMatch,
  });
};

export const setMatchList = (matchList) => (dispatch) => {
  dispatch({
    type: SET_MATCH_LIST,
    payload: matchList,
  });
};

export const listVideos = (page = 1, pageSize = 1000) => {
  return actionFactory({
    api: `get/api/matches/?page=${page}&page_size=${pageSize}`,
    failureToast: true,
    actionBase: GET_MATCHES,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(setVideosListingLoading(false));
        })
        .catch((err) => {});
    },
  });
};

export const getMatch = (id) => {
  return actionFactory({
    api: `get/api/matches/${id}/`,
    failureToast: true,
    actionBase: GET_MATCHES,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(setSelectedVideo(resp.data.data));
        })
        .catch((err) => {});
    },
  });
};



export const deleteMatch = (id) => {
  return actionFactory({
    api: `delete/api/matches/${id}/`,
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(listVideos());
          message.success(`Match deleted successfully.`);
        })
        .catch((err) => {});
    },
  });
};
