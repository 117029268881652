 /* eslint-disable */
import React, { useEffect, useState } from "react";
import "./RightSection.scss";
import { ReactComponent as TimePickerIcon } from "./../../GridConfigPage/GridConfigSection/TimeStamp/TimeStampInput/TimePicker.svg";
import { parseHMSM, secondsToHMSM } from "../../../libs/utils";
import { Button, Divider, Dropdown, InputNumber, Menu, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGridList,
  selectHitOrBounce,
} from "../../../store/selectors/GridConfig";
import { useNavigate } from "react-router-dom";
import { selectSelectedVideo } from "../../../store/selectors/Match";
import { DownOutlined, TableOutlined } from "@ant-design/icons";
import { setSelectedGrid } from "../../../store/actions/GridConfig";
import { setSelectedAnnotation } from "../../../store/actions/ReviewDetails";
import { selectAnnotation } from "../../../store/selectors/ReviewDetails";

const RightSection = (props) => {
  const { formik, videoPlayerRef, handleHitOrBounce, handleInputChange } =
    props;

  const dispatch = useDispatch();
  const gridList = useSelector(selectGridList);
  const selectedShot = useSelector(selectAnnotation);
  const hitOrBounce = useSelector((state) => selectHitOrBounce(state));
  const [selectedGridItem, setSelectedGridItem] = useState(null);

  const handleTimePicker = (field) => {
    const currentTime = videoPlayerRef?.current?.getCurrentTime();
    formik.setFieldValue(field, currentTime);
  };
  const navigate = useNavigate();
  const match = useSelector(selectSelectedVideo);
  const handleMenuClick = (e) => {
    const selectedKey = e.key;
    const selectedGrid = gridList.find((item) => item.id === selectedKey);

    if (selectedKey === "noGrid") {
      setSelectedGridItem(`No Grid`);
      dispatch(setSelectedGrid({}));
      dispatch(setSelectedAnnotation({ ...selectedShot, grid: null }));
    } else if (selectedGrid) {
      const selectedIndex = items.findIndex((item) => item.key === selectedKey);
      setSelectedGridItem(`Grid ${selectedIndex + 1}`);
      dispatch(setSelectedGrid(selectedGrid));
      dispatch(
        setSelectedAnnotation({ ...selectedShot, grid: selectedGrid?.id })
      );
    }
  };

  const menuProps = {
    onClick: handleMenuClick,
  };

  const gridItems = gridList.map((obj, index) => ({
    ...obj,
    label: `Grid ${index + 1}`,
    key: obj.id,
  }));
  const items = [
    ...gridItems,
    {
      label: `No Grid`,
      key: "noGrid",
    },
  ];

  // to update grid selection dropdown
  useEffect(() => {
    const selectedIndex = items.findIndex(
      (item) => item.key === selectedShot?.grid
    );
    const newSelectedGridItem =
      selectedIndex >= 0 ? `Grid ${selectedIndex + 1}` : "No Grid";

    setSelectedGridItem(newSelectedGridItem);
  }, [gridList, selectedShot]);
  // to update grid selection dropdown
  useEffect(() => {
    const selectedGrid = gridList.find(
      (item) => item.id === selectedShot?.grid
    );
    dispatch(setSelectedGrid(selectedGrid));
    dispatch(
      setSelectedAnnotation({ ...selectedShot, grid: selectedGrid?.id || null })
    );
  }, [selectedGridItem]);

  const handleGridEdit = () => {
    navigate(`/match/${match.id}/grid-config`);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="right-form-section">
      <div className="right-section">
        <div className="timestamp-input-container">
          <label htmlFor="timestamp-box">
            <Divider orientation="center">Shot Time</Divider>
          </label>
          <div className="timestamp-start-time-input">
            <div className="ts-label">Start</div>
            <InputNumber
              className="timestamp-input"
              id="timestamp"
              name="timestamp"
              onChange={(currentTime) =>
                formik.setFieldValue("start_time", currentTime)
              }
              step={0.5}
              value={formik.values?.start_time}
              formatter={(value) => secondsToHMSM(value)}
              parser={(value) => parseHMSM(value, formik.values?.start_time)}
              addonAfter={
                <TimePickerIcon
                  onClick={() => handleTimePicker("start_time")}
                />
              }
            />
          </div>
          <div className="timestamp-end-time-input">
            <div className="te-label">End</div>
            <InputNumber
              className="timestamp-input"
              id="end_time"
              name="end_time"
              onChange={(currentTime) =>
                formik.setFieldValue("end_time", currentTime)
              }
              step={0.5}
              value={formik.values?.end_time}
              formatter={(value) => secondsToHMSM(value)}
              parser={(value) => parseHMSM(value, formik.values?.end_time)}
              addonAfter={
                <TimePickerIcon onClick={() => handleTimePicker("end_time")} />
              }
            />
          </div>
        </div>

        <div className="ball-impact">
          <label htmlFor="gridNumber">
            <Divider orientation="center">Ball Impact</Divider>
          </label>
          <div className="ball-impact-group">
            <div className="source-dest-group">
              <div className="hit-bounce-buttons">
                <div className="shot-button-group">
                  <Button
                    onClick={() => handleHitOrBounce("hit")}
                    className="shot-radio-button btn-h"
                    value="hit"
                    style={{
                      backgroundColor:
                        hitOrBounce === "hit" ? "#ECFDF3" : "initial",
                      border:
                        hitOrBounce === "hit"
                          ? "1px solid #037847"
                          : "1px solid initial",
                    }}
                  >
                    Source
                  </Button>
                  <Button
                    onClick={() => handleHitOrBounce("bounce")}
                    className="shot-radio-button btn-b"
                    value="bounce"
                    style={{
                      backgroundColor:
                        hitOrBounce === "bounce" ? "#ECFDF3" : "initial",
                      border:
                        hitOrBounce === "bounce"
                          ? "1px solid #037847"
                          : "1px solid initial",
                    }}
                  >
                    Destination
                  </Button>
                </div>
              </div>
              <div className="source-dest-input">
                <div className="floor">
                  <input
                    className="floor-input"
                    id="floorInputValue"
                    name="init_ball_position"
                    onChange={(event) =>
                      handleInputChange("init_ball_position", event)
                    }
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.init_ball_position !== null
                        ? formik.values.init_ball_position
                        : ""
                    }
                  />
                </div>

                <div className="dest">
                  <input
                    className="dest-input"
                    id="destInputValue"
                    name="dest_ball_position"
                    onChange={(event) =>
                      handleInputChange("dest_ball_position", event)
                    }
                    onBlur={formik.handleBlur}
                    value={
                      formik.values.dest_ball_position !== null
                        ? formik.values.dest_ball_position
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div className="frontwall-backwall-grouping">
              <Divider style={{ margin: "12px 0px" }} dashed></Divider>
              <div className="front-wall">
                <input
                  className="front-wall-input"
                  id="frontWallInputValue"
                  name="front_wall_position"
                  onChange={(event) =>
                    handleInputChange("front_wall_position", event)
                  }
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.front_wall_position !== null
                      ? formik.values.front_wall_position
                      : ""
                  }
                />
                <label htmlFor="frontWallInputValue">Frontwall</label>
              </div>
              <Divider style={{ margin: "12px 0px" }} dashed></Divider>
              <div className="checkbox-container">
                <input
                  className="annotation-checkbox"
                  type="checkbox"
                  id="backWallCheckbox"
                  name="backwall"
                  onChange={(event) => {
                    formik.handleChange(event);
                    // Set the value to true if checked, otherwise false
                    formik.setFieldValue("is_backwall", event.target.checked);
                  }}
                  onBlur={formik.handleBlur}
                  checked={formik.values.is_backwall}
                />
                <label htmlFor="backWallCheckbox">Back Wall</label>
              </div>
              <div className="checkbox-container">
                <input
                  className="annotation-checkbox"
                  type="checkbox"
                  id="volleyPossibilityCheckbox"
                  name="volley_possibility"
                  onChange={(event) => {
                    formik.handleChange(event);
                    // Set the value to true if checked, otherwise false
                    formik.setFieldValue(
                      "is_volley_possibility",
                      event.target.checked
                    );
                  }}
                  onBlur={formik.handleBlur}
                  checked={formik.values.is_volley_possibility}
                />
                <label htmlFor="volleyPossibilityCheckbox">
                  Volley Possibility
                </label>
              </div>
            </div>
            <div className="vertical-divider"></div>
            <div className="select-grid">
              <Divider orientation="center">Grid</Divider>
              <div>
                <Space>
                  <Dropdown
                    overlay={
                      <Menu
                        style={{ maxHeight: 300, overflowY: "auto" }}
                        {...menuProps}
                      >
                        {items.map((item) => (
                          <Menu.Item key={item.key}>{item.label}</Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button>
                      {selectedGridItem || "Select"}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <Button icon={<TableOutlined />} onClick={handleGridEdit} />
                </Space>
              </div>
            </div>
          </div>
        </div>

        <div>
          <label htmlFor="timestamp">
            <Divider orientation="center">Status</Divider>
          </label>
          <div className="overall-shot">
            {/* <div className="checkbox-container">
              <label
                htmlFor="markForIsCompleteCheckbox"
                style={{ wordWrap: "wrap" }}
              >
                visited
              </label>
              <input
                className="annotation-checkbox"
                type="checkbox"
                id="markForIsCompleteCheckbox"
                name="is_complete"
                onChange={(event) => {
                  formik.handleChange(event);
                  // Set the value to true if checked, otherwise false
                  formik.setFieldValue("is_complete", event.target.checked);
                }}
                onBlur={formik.handleBlur}
                checked={formik.values.is_complete}
              />
            </div> */}

            <div className="checkbox-container">
              <label htmlFor="erroneousCheckbox" style={{ wordWrap: "wrap" }}>
                Erroneous
              </label>
              <input
                className="annotation-checkbox"
                type="checkbox"
                id="erroneousCheckbox"
                name="erroneous"
                onChange={(event) => {
                  formik.handleChange(event);
                  // Set the value to true if checked, otherwise false
                  formik.setFieldValue("is_erroneous", event.target.checked);
                }}
                onBlur={formik.handleBlur}
                checked={formik.values.is_erroneous}
              />
            </div>

            <div className="checkbox-container">
              <label
                htmlFor="markForReviewCheckbox"
                style={{ wordWrap: "wrap" }}
              >
                Shortlisted
              </label>
              <input
                className="annotation-checkbox"
                type="checkbox"
                id="markForReviewCheckbox"
                name="is_reviewed"
                onChange={(event) => {
                  formik.handleChange(event);
                  // Set the value to true if checked, otherwise false
                  formik.setFieldValue("is_shortlisted", event.target.checked);
                }}
                onBlur={formik.handleBlur}
                checked={formik.values.is_shortlisted}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default RightSection;