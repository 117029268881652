 /* eslint-disable */
import "./App.scss";
import LoginPage from "./components/LandingPage/LoginPage";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import MatchPage from "./components/MatchPage/MatchPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import GridConfigContainer from "./components/GridConfigPage/GridConfigContainer";
import ReviewPage from "./components/ReviewPage/ReviewPage";
import ChartPage from "./components/ChartPage/ChartPage";
import { selectSelectedVideo } from "./store/selectors/Match";
import UserList from "./components/Admin/UserList";
import { loadUserDetails } from "./store/actions/User";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedMatch = useSelector(selectSelectedVideo);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else if (token) {
      dispatch(loadUserDetails());
      if (!selectedMatch) {
        navigate("/matches");
        !selectedMatch && navigate("/matches");
      }
    }
  }, [token]);

  return (
    <div className="App">
      <Routes>
        <Route exact path="*" element={<Navigate to="/matches" />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/matches" element={<MatchPage />} />
        <Route exact path="/admin/users" element={<UserList />} />

        <Route
          exact
          path="/match/:id/grid-config"
          element={<GridConfigContainer />}
        />
        <Route
          exact
          path="/match/:id/review-details"
          element={<ReviewPage />}
        />
        <Route exact path="/match/:id/charts" element={<ChartPage />} />
      </Routes>
    </div>
  );
}

export default App;
