import React from "react";
import { Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as TimePickerIcon } from "./TimePicker.svg";
import "./TimeStampInput.scss";
import { secondsToHMSM } from "../../../../../libs/utils";
import { useDispatch } from "react-redux";
import { setGridDuration } from "../../../../../store/actions/GridConfig";

const TimeStampInput = ({
  GridConfigPlayerRef,
  timestamp,
  index,
  onClose,
  durationArray,
  onClick,
}) => {
  const dispatch = useDispatch();

  const startTime = timestamp ? timestamp[0] : 0;
  const endTime = timestamp ? timestamp[1] : 0;

  const handleStartChange = (e) => {
    // setStartValue(e.target.value);
  };

  const handleEndChange = (e) => {
    // setEndValue(e.target.value);
  };

  const handleStartTimePicker = (e) => {
    const currentTime = GridConfigPlayerRef?.current?.getCurrentTime();
    const newDurationArray = durationArray.map((subarray, i) =>
      i === index
        ? [...subarray.slice(0, 0), currentTime, ...subarray.slice(0 + 1)]
        : subarray
    );
    dispatch(setGridDuration(newDurationArray));
  };

  const handleEndTimePicker = () => {
    const currentTime = GridConfigPlayerRef?.current?.getCurrentTime();
    const newDurationArray = durationArray.map((subarray, i) =>
      i === index
        ? [...subarray.slice(0, 1), currentTime, ...subarray.slice(1 + 1)]
        : subarray
    );
    dispatch(setGridDuration(newDurationArray));
  };

  return (
    <div className="timeStamp-input-container" key={index}>
      <div className="header">
        <CloseOutlined
          style={{ color: "#9b9a9a" }}
          onClick={() => onClose(index)}
        />
      </div>
      <div className="timestamp-start-label">Start</div>
      <div className="input-container">
        <Input
          className="time-input"
          value={secondsToHMSM(startTime)}
          onChange={handleStartChange}
        />
        <div className="pointer-buton">
          <TimePickerIcon onClick={handleStartTimePicker} />
        </div>
      </div>
      <div className="timestamp-start-label">End</div>
      <div className="input-container">
        <Input
          className="time-input"
          value={secondsToHMSM(endTime)}
          onChange={handleEndChange}
        />
        <div className="pointer-buton">
          <TimePickerIcon onClick={handleEndTimePicker} />
        </div>
      </div>
    </div>
  );
};

export default TimeStampInput;
