import React, { useEffect } from "react";
import * as echarts from "echarts";

const QualityVsSpeedBar = ({ data }) => {
  useEffect(() => {
    // Initialize bar graph container
    const chartContainer = document.getElementById(
      "shot-quality-vs-speed-graph"
    );
    const chart = echarts.init(chartContainer);

    // Define bar graph options
    const options = {
      title: {
        text: "Quality & Speed",
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        // Custom tooltip formatter to include percentage
        formatter: function (params) {
          let tooltip = params[0].name + "<br/>";
          params.forEach(function (item) {
            let totalSum;
            if (item.seriesName === "Fast") {
              totalSum =
                data?.High?.Fast + data?.High?.Medium + data?.High?.Slow;
            } else if (item.seriesName === "Medium") {
              totalSum =
                data?.Medium?.Fast + data?.Medium?.Medium + data?.Medium?.Slow;
            } else if (item.seriesName === "Slow") {
              totalSum = data?.Low?.Fast + data?.Low?.Medium + data?.Low?.Slow;
            }
            tooltip +=
              item.seriesName +
              ": " +
              item.value +
              " (" +
              ((item.value / totalSum) * 100).toFixed(2) +
              "%)<br/>";
          });
          return tooltip;
        },
      },
      legend: {
        data: ["Fast", "Medium", "Slow"],
      },
      xAxis: {
        type: "category",
        name: "Quality",
        data: ["High", "Medium", "Low"],
        axisLabel: {
          interval: 0,
          rotate: 45,
          formatter: (value) => {
            return value !== " " ? value : "Shot Types"; // Show "Shot Types" at the last category position
          },
        },
      },
      yAxis: {
        type: "value",
        name: "Shots",
      },
      series: [
        {
          name: "Fast",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [data?.High?.Fast, data?.High?.Medium, data?.High?.Slow],
          color: "#1fd137",
        },
        {
          name: "Medium",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [data?.Medium?.Fast, data?.Medium?.Medium, data?.Medium?.Slow],
          color: "#faf74d",
        },
        {
          name: "Slow",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [data?.Low?.Fast, data?.Low?.Medium, data?.Low?.Slow],
          color: "#ff6259",
        },
      ],
    };

    // Set options and render chart
    chart.setOption(options);

    // Cleanup function to dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      <div
        id="shot-quality-vs-speed-graph"
        style={{ width: "50%", height: "400px" }}
      ></div>
    </>
  );
};

export default QualityVsSpeedBar;
