import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import "./StyledModal.scss";

/**
 * Reusable StyledModal component using Ant Design's Modal.
 *
 * @param {Object} props - Component props.
 * @param {ReactNode} props.children - Content to be displayed inside the modal.
 * @param {function} props.onOk - Callback function to handle the "Ok" button click.
 * @param {function} props.onCancel - Callback function to handle the "Cancel" button click.
 * @param {string} props.title - Title of the modal.
 * @param {boolean} props.open - Boolean to control the visibility of the modal.
 * @param {...any} props - Other props passed to Ant Design Modal component.
 *
 * @example
 * // Example Usage:
 * const ExampleParentComponent = () => {
 *   const [isModalOpen, setIsModalOpen] = useState(false);
 *
 *   const handleOk = () => {
 *     // Handle "Ok" button click
 *   };
 *
 *   const handleCancel = () => {
 *     // Handle "Cancel" button click
 *     setIsModalOpen(false);
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={() => setIsModalOpen(true)}>Open Modal</button>
 *       <StyledModal
 *         title="Example Modal"
 *         open={isModalOpen}
 *         onOk={handleOk}
 *         onCancel={handleCancel}
 *       >
 *         <p>This is the content of the modal.</p>
 *       </StyledModal>
 *     </div>
 *   );
 * };
 * // End Example Usage
 */

const StyledModal = ({ children, onOk, onCancel, title, open, ...props }) => {
  return (
    <Modal
      {...props}
      centered
      width={500}
      onOk={onOk}
      onCancel={onCancel}
      title={title}
      open={open}
    >
      {children}
    </Modal>
  );
};

// Prop types for the StyledModal component
StyledModal.propTypes = {
  children: PropTypes.node.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  // Add other PropTypes for Modal props as needed
};

export default StyledModal;

// Example Usage:
/**
 * Example Usage:
 *
 * const ParentComponent = () => {
 *   const [isModalOpen, setIsModalOpen] = useState(false);
 *
 *   const handleOk = () => {
 *     // Handle "Ok" button click
 *   };
 *
 *   const handleCancel = () => {
 *     // Handle "Cancel" button click
 *     setIsModalOpen(false);
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={() => setIsModalOpen(true)}>Open Modal</button>
 *       <StyledModal
 *         title="Example Modal"
 *         open={isModalOpen}
 *         onOk={handleOk}
 *         onCancel={handleCancel}
 *       >
 *         <p>This is the content of the modal.</p>
 *       </StyledModal>
 *     </div>
 *   );
 * };
 *
 * export default ParentComponent;
 */
