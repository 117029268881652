import React from "react";
import LoginMethod from "./LoginMethod";
import LandingImage from "./LandingImage";
import { Row, Col } from "antd";

const LoginPage = () => {
  return (
    <Row>
      <Col span={12}>
        <LandingImage />
      </Col>
      <Col style={{ display: "flex", alignContent: "center" }} span={12}>
        <LoginMethod />
      </Col>
    </Row>
  );
};

export default LoginPage;
