 /* eslint-disable */
import React, { useEffect, useState } from "react";
import { Stage, Layer, Circle } from "react-konva";
import "./CoordinateSelector.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setFloorCoordinatesInput,
  setWallCoordinatesInput,
} from "../../../store/actions/GridConfig";
import {
  selectFloorInput,
  selectSelectedTab,
  selectWallInput,
  getGridIndex,
} from "../../../store/selectors/GridConfig";

const CoordinateSelector = (props) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectSelectedTab);
  const wallInput = useSelector(selectWallInput);
  const floorInput = useSelector(selectFloorInput);
  const gridIndex = useSelector(getGridIndex);
  const { width, height, dotRadius } = props;
  const [points, setPoints] = useState([]);

  useEffect(() => {
    if (selectedTab === "front-wall") {
      setPoints(wallInput);
    } else if (selectedTab === "floor") {
      setPoints(floorInput);
    } else {
      setPoints([]);
    }
  }, [selectedTab, wallInput, floorInput]);

  const handleStageClick = (e) => {
    if (e.evt.button === 0 && !!points && points?.length && gridIndex !== null) {
      const stage = e.target.getStage();
      const position = stage.getPointerPosition();
      let selectedPoints=[...points];
      selectedPoints[gridIndex]=new Array(position.x, position.y);
      const updatedPoints = selectedPoints;
      if (selectedTab === "front-wall") {
        setPoints(updatedPoints);
        dispatch(setWallCoordinatesInput(updatedPoints));
      } else if (selectedTab === "floor" ) {
        setPoints(updatedPoints);
        dispatch(setFloorCoordinatesInput(updatedPoints));
      } else {
      }
    }
  };
  return (
    <div className="coord-selector">
      <Stage width={width} height={height} onClick={handleStageClick}>
        <Layer>
          {points?.map((point, index) => 
          <div>{ !!point && 
            <Circle
              key={index}
              x={point ? point[0] : null}
              y={point ? point[1] : null}
              radius={dotRadius}
              fill="red"
              draggable
              strokeWidth={gridIndex === index ? 2 : null} // border width
              stroke={gridIndex === index ? "yellow" : ""}
              onDragEnd={(e) => {
                const updatedPoints = [...points];
                const coordinate = e.target.position();
                updatedPoints[index] = [coordinate.x, coordinate.y];
                setPoints(updatedPoints);
                selectedTab === "floor"
                  ? dispatch(setFloorCoordinatesInput(updatedPoints))
                  : dispatch(setWallCoordinatesInput(updatedPoints));
              }}
            />}
            </div>
          )}
        </Layer>
      </Stage>
    </div>
  );
};

export default CoordinateSelector;
