// Select values based on ID
export const bulkSelectReducerSelector = (state) => state.bulkSelectReducer;

export const isDeleteModalOpen = (state) =>
  state.bulkSelectReducer.is_delete_modal_open;

export const selectBulkSelectStatus = (state, id) => {
  return bulkSelectReducerSelector(state)[id]?.bulkSelect;
};

export const selectBulkSelectValues = (state, id) => {
  return bulkSelectReducerSelector(state)[id]?.values;
};
