const useLocalValt = (key) => {
  const storedData = JSON.parse(localStorage.getItem(key)) || {};

  const setData = (data) => {
    localStorage.setItem(key, JSON.stringify({ ...storedData, ...data }));
  };

  return [storedData, setData];
};

export default useLocalValt;
