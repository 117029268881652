 /* eslint-disable */
import React, { useEffect } from "react";
import * as echarts from "echarts";

const ShotTightnessPie = ({ data }) => {
  const modifiedData = [
    { value: data?.Tight, name: "Tight" },
    { value: data?.Medium, name: "Medium" },
    { value: data?.Loose, name: "Loose" },
  ];

  // Calculate total shots
  const totalShots = modifiedData.reduce(
    (acc, current) => acc + current.value,
    0
  );

  useEffect(() => {
    // Initialize pie chart container
    const chartContainer = document.getElementById("shot-tightness-pie-chart");
    const chart = echarts.init(chartContainer);

    // Define custom colors for slices
    const colors = ["#5470C6", "#91CC75", "#EE6666"];

    // Define pie chart options
    const options = {
      title: {
        text: `Shot Tightness (Total Shots: ${totalShots})`,
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: 30,
        top: 20,
        formatter: function (name) {
          const item = modifiedData.find((item) => item.name === name);
          return `${name} (${item.value})`;
        },
        data: modifiedData.map((item) => item.name),
      },
      series: [
        {
          name: "Shot Tightness Type",
          type: "pie",
          radius: "65%",
          center: ["50%", "60%"],
          data: modifiedData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          // Set custom colors
          color: colors,
        },
      ],
    };

    // Set options and render chart
    chart.setOption(options);

    // Cleanup function to dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      <div
        id="shot-tightness-pie-chart"
        style={{ width: "50%", height: "500px" }}
      ></div>
    </>
  );
};

export default ShotTightnessPie;
