export const ShotFilters = [
  { label: "Visited", value: "is_visited=True" },
  { label: "Not Visited", value: "is_visited=False" },
  { label: "Erroneous Shot", value: "is_erroneous=True" },
  { label: "Non Erroneous Shot", value: "is_erroneous=False" },
  { label: "Shortlisted", value: "is_shortlisted=True" },
  { label: "Not Shortlisted", value: "is_shortlisted=False" },
  { label: "Expert Added", value: "is_expert_added=True" },
  { label: "Not Expert Added", value: "is_expert_added=False" },
];

export const updateOptions = [
  { label: "Visited", value: "{ is_visited: true }" },
  { label: "Not Visited", value: "{ is_visited: false }" },
  { label: "Erroneous Shot", value: "{ is_erroneous: true }" },
  { label: "Non Erroneous Shot", value: "{ is_erroneous: false }" },
  { label: "Shortlisted", value: "{ is_shortlisted: true }" },
  { label: "Not Shortlisted", value: "{ is_shortlisted: false }" },
];
