 /* eslint-disable */
import { Layer, Stage } from "react-konva";
import FloorGridLayout from "./FloorGridLayout";
import WallGridLayout from "./WallGridLayout";
import GridSegmentSelector from "../GridSegmentSelector";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAnnotation } from "../../../../store/actions/ReviewDetails";
import { selectAnnotation } from "../../../../store/selectors/ReviewDetails";
import { selectCurrentPage } from "../../../../store/selectors/Settings";
import {
  selectGeneratedFloorGrid,
  selectGeneratedWallGrid,
  selectSelectedGrid,
} from "../../../../store/selectors/GridConfig";

const GridLayout = (props) => {
  const { width, height } = props;
  const dispatch = useDispatch();
  const selectedShot = useSelector(selectAnnotation);
  const selectedGrid = useSelector(selectSelectedGrid);
  const page = useSelector(selectCurrentPage);
  const floorCoordinates = useSelector(selectGeneratedFloorGrid);
  const wallCoordinates = useSelector(selectGeneratedWallGrid);

  const handleFieldUpdate = (field, value) => {
    const updatedShot = { ...selectedShot };
    updatedShot[field] = value;
    dispatch(setSelectedAnnotation(updatedShot));
  };

  return (
    <Stage width={width} height={height}>
      <Layer>
        {wallCoordinates?.length === 20 && (
          <WallGridLayout
            dimensions={[width, height]}
            wallCoordinates={wallCoordinates}
          />
        )}
        {/* {backwallCoordinates?.length === 6 && (
          <BackwallGridLayout
            dimensions={[width, height]}
            backwallCoordinates={backwallCoordinates}
          />
        )} */}
        {floorCoordinates?.length === 20 && (
          <FloorGridLayout
            dimensions={[width, height]}
            floorCoordinates={floorCoordinates}
          />
        )}
        {page !== "config" && selectedGrid != {} && (
          <GridSegmentSelector
            dimensions={[width, height]}
            selectedShot={selectedShot}
            handleFieldUpdate={handleFieldUpdate}
          />
        )}
      </Layer>
    </Stage>
  );
};

export default GridLayout;
