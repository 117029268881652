import {
  SET_CURRENT_PAGE,
  SET_DELTA_TIME,
  SET_END_DELTA_TIME,
  SET_LOOP_ENABLE,
} from "../actionTypes/Settings";

export const setDeltaTime = (deltaTime) => (dispatch) => {
  dispatch({
    type: SET_DELTA_TIME,
    payload: deltaTime,
  });
};

export const setEndDeltaTime = (deltaTime) => (dispatch) => {
  dispatch({
    type: SET_END_DELTA_TIME,
    payload: deltaTime,
  });
};

export const setLoopEnable = (enable) => (dispatch) => {
  dispatch({
    type: SET_LOOP_ENABLE,
    payload: enable,
  });
};

export const setCurrentPage = (page) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: page,
  });
};
