import {
  SET_CURRENT_PAGE,
  SET_DELTA_TIME,
  SET_END_DELTA_TIME,
  SET_LOOP_ENABLE,
} from "../actionTypes/Settings";

const DEFAULT_SETTINGS_STATE = {
  deltaTime: -0.5,
  endDeltaTime: 0.0,
  loop: true,
  current_page: "",
};

export const settingsReducer = (state = DEFAULT_SETTINGS_STATE, action) => {
  switch (action.type) {
    case SET_DELTA_TIME:
      return { ...state, deltaTime: action.payload };
    case SET_END_DELTA_TIME:
      return { ...state, endDeltaTime: action.payload };
    case SET_LOOP_ENABLE:
      return { ...state, loop: action.payload };
    case SET_CURRENT_PAGE:
      return { ...state, current_page: action.payload };
    default:
      return state;
  }
};
