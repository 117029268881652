import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { Select } from "antd";
import "./ShotQuality.scss";

const { Option } = Select;

const ShotVsSpeedVsTightnessBar = ({ data }) => {
  const [selectedShotType, setSelectedShotType] = useState("Serve");
  const [ShotData, setShotData] = useState(null);

  const handleShotTypeChange = (value) => {
    setSelectedShotType(value);
  };

  useEffect(() => {
    data && setShotData(data[selectedShotType]);
  }, [selectedShotType, data]); // Include data in the dependency array

  useEffect(() => {
    if (!ShotData) return; // Return if ShotData is null or undefined

    // Initialize bar graph container
    const chartContainer = document.getElementById(
      "shot-vs-speed-vs-tightness-graph"
    );
    const chart = echarts.init(chartContainer);

    // Define bar graph options
    const options = {
      title: {
        text: " Speed & Tightness",
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["Tight", "Medium", "Loose"],
      },
      xAxis: {
        type: "category",
        data: ["Fast", "Medium", "Slow"],

        axisLabel: {
          interval: 0,
          rotate: 45,
          formatter: (value) => {
            return value !== " " ? value : "Shot Types"; // Show "Shot Types" at the last category position
          },
        },
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Tight",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            ShotData?.Fast?.Tight,
            ShotData?.Fast?.Medium,
            ShotData?.Fast?.Loose,
          ],
          color: "#1fd137",
        },
        {
          name: "Medium",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            ShotData?.Medium?.Tight,
            ShotData?.Medium?.Medium,
            ShotData?.Medium?.Loose,
          ],
          color: "#faf74d",
        },
        {
          name: "Loose",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            ShotData?.Slow?.Tight,
            ShotData?.Slow?.Medium,
            ShotData?.Slow?.Loose,
          ],
          color: "#ff6259",
        },
      ],
    };

    // Set options and render chart
    chart.setOption(options);

    // Cleanup function to dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [ShotData]);

  return (
    <>
      <div>
        <div className="shot-type-filter-items">
          <strong>{"Shot Type  "}</strong>
          <Select
            style={{ width: 200 }}
            placeholder="Select shot type"
            onChange={handleShotTypeChange}
            value={selectedShotType}
          >
            {data &&
              Object.keys(data).map((shotType) => (
                <Option key={shotType} value={shotType}>
                  {shotType}
                </Option>
              ))}
          </Select>
        </div>
        <div
          id="shot-vs-speed-vs-tightness-graph"
          style={{ width: "100%", height: "400px" }}
        ></div>
      </div>
    </>
  );
};

export default ShotVsSpeedVsTightnessBar;
