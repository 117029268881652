const adminSelector = (state) => {
    return state.admin;
  };
  
  export const allUserSelector = (state) => {
    return adminSelector(state).users;
  };
  
  export const selectedUserSelector = (state) => {
    return adminSelector(state).selectedUser;
  };
  