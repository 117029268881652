import React from "react";
import { Button } from "semantic-ui-react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getJwtToken,
} from "../../store/actions/User";
import { message } from "antd";

const SignInWithGoogle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const unauthorizedCallBack = (authorized) => {
    if (authorized) {
      navigate("/matches");
    } else {
      messageApi.open({
        type: "error",
        content: "Access Denied!",
      });
      navigate("/login");
    }
  };

  const loginHandle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const googleToken = codeResponse.code;
      localStorage.setItem("token", googleToken);
      dispatch(getJwtToken(googleToken, unauthorizedCallBack));
      // dispatch(googleLogin(googleToken, unauthorizedCallBack));
    },
    flow: "auth-code",
  });

  return (
    <>
      {contextHolder}
      <div className="sign-in-with-google">
        <Button
          icon="google"
          content="Sign in with google"
          size="massive"
          secondary
          onClick={loginHandle}
          style={{ fontSize: "20px" }}
        />
      </div>
    </>
  );
};

export default SignInWithGoogle;
