import React from "react";
import CustomField from "./CustomField/CustomField";
import "./CreateShotDataFormFields.scss";
import {
  qualityOptions,
  receiverFootPositionOptions,
  riskFactorOptions,
  shotTypes,
  speedOptions,
  tightnessOptions,
  resultOptions,
} from "../../../../libs/constants";

const CreateShotDataFormFields = (props) => {
  const { formik, drawerOpen } = props;

  return (
    <div className="form-container">
      <div className="form-field-container">
        <CustomField
          label="Shot"
          fieldName="shot_type"
          options={shotTypes}
          type="RADIO_FIELD"
          formik={formik}
          drawerOpen={drawerOpen}
        />
        <div className="field-row-container">
          <CustomField
            label="Quality"
            fieldName="quality"
            options={qualityOptions}
            type="RADIO_FIELD"
            formik={formik}
            drawerOpen={drawerOpen}
          />
          <CustomField
            label="Tightness"
            fieldName="tightness"
            options={tightnessOptions}
            type="RADIO_FIELD"
            formik={formik}
            drawerOpen={drawerOpen}
          />
          <CustomField
            label="Speed"
            fieldName="speed"
            options={speedOptions}
            type="RADIO_FIELD"
            formik={formik}
            drawerOpen={drawerOpen}
          />
          <CustomField
            label="Risk Factor"
            fieldName="risk_factor"
            options={riskFactorOptions}
            type="RADIO_FIELD"
            formik={formik}
            drawerOpen={drawerOpen}
          />
        </div>
        <div className="field-row-container">
        <CustomField
          label="Receiver Foot Position"
          fieldName="receiver_foot_position"
          options={receiverFootPositionOptions}
          type="RADIO_FIELD"
          formik={formik}
          drawerOpen={drawerOpen}
        />
        <CustomField
          label="Result"
          fieldName="result"
          options={resultOptions}
          type="RADIO_FIELD"
          formik={formik}
          drawerOpen={drawerOpen}
        />
        </div>
      </div>
    </div>
  );
};

export default CreateShotDataFormFields;
