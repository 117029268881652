import "./StatsTable.scss";

const ShotStatsTable = (props) => {
  const { analyzedData } = props;

  // Convert analyzedData object into an array of objects
  const dataSource =
    analyzedData &&
    Object.entries(analyzedData).map(([category, count]) => ({
      category,
      count,
      percentage:
        count === 0
          ? 0
          : (
              (count /
                Object.values(analyzedData).reduce(
                  (total, count) => total + count,
                  0
                )) *
              100
            ).toFixed(2),
    }));

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Percentage(%)",
      dataIndex: "percentage",
      key: "percentage",
    },
  ];

  return (
    <>
      <div className="grid-detail">
        <h4>Grid {!!props.selectedGrid && props.selectedGrid} Details</h4>
        <span>
          Total Shots:{" "}
          <strong>
            {dataSource &&
              dataSource.reduce((total, item) => total + item.count, 0)}
          </strong>
        </span>
      </div>
      {!!dataSource && 
      <table className="grid-dtl-list">
      <thead>
      {columns.map((coldata,colIndex)=>
        <th key={`col_${colIndex}`}>{coldata.title}</th>
        )}
        </thead>
        <tbody>
          {dataSource.map((itemdata,index)=>
          <tr key={`tdata_${index}`}><td>{itemdata.category}</td><td>{itemdata.count}</td><td>{itemdata.percentage}</td></tr>
          )}
        </tbody>
      </table>
}
    </>
  );
};

export default ShotStatsTable;
