import React from "react";
import "./LoginPage.scss";
import SignInWithGoogle from "./SignInWithGoogle";
import SignInWithCredential from "./SignInWithCredential";

const LoginMethod = () => {
  return (
    <>
      <div className="text-center login-container">
        <div>
          <h2 className="rally-vision-font">Rally Vision</h2>
        </div>
        <div>
          <h5 className="imp-player-style">Improving Player Performance</h5>
        </div>
        <div className="login-header">
          <h2>Login</h2>
        </div>
        <SignInWithCredential />
        <span className="or-text">OR</span>
        <SignInWithGoogle />
      </div>
    </>
  );
};

export default LoginMethod;
