import { actionFactory } from "../../libs/actionFactory";
import {
  GOOGLE_CLIENT_ID,
  SET_USER_DETAILS,
  USER_LOGOUT,
  SET_VIDEOS_LISTING_LOADING,
  SET_ANNOTATIONS_LISTING_LOADING,
  CRED_LOGIN_ACTION,
} from "../actionTypes/User";
import axios from "axios";
import { message } from "antd";

export const setUserDetails = (user) => ({
  type: SET_USER_DETAILS,
  payload: user,
});

export const logoutUser = () => ({ type: USER_LOGOUT });

export const loadUserDetails = (callback =()=>{}) => {
  return actionFactory({
    api: "get/api/userauth/user/self/",
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(setUserDetails(resp["data"].data));
          }
        })
        .catch((err) => {
        });
    },
  });
};

export const registerUser = (user) => {
  return actionFactory({
    api: "post/api/users/register/",
    actionBody: {
      body: user,
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(loadUserDetails());
          } else {
            localStorage.removeItem("token");
            dispatch(logoutUser());
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
        });
    },
  });
};

export const getJwtToken = (googleToken, callback) => {
  return actionFactory({
    api: "post/api/auth/validate-token",
    failureToast: false,
    actionBase: GOOGLE_CLIENT_ID,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const jwtToken = resp["data"].data["Bearer Token"];
            localStorage.setItem("token", jwtToken);
            dispatch(googleLogin(jwtToken, callback));
            // const email = getGmailFromJwtToken(jwtToken);
            // dispatch(registerUser({ email: email }));
          } else {
            message.error("Google Login Failed.");
          }
        })
        .catch((err) => {});
    },
  });
};

export const setVideosListingLoading = (loading) => ({
  type: SET_VIDEOS_LISTING_LOADING,
  payload: loading,
});

export const setAnnotationListingLoading = (loading) => ({
  type: SET_ANNOTATIONS_LISTING_LOADING,

  payload: loading,
});

export const googleLogin = async (accessToken, callback) => {
  try {
    const response = await axios.post("api/userauth/user/google/login/", {
      access_token: accessToken,
    });

    localStorage.setItem("token", `Token ${response.data.key}`);
    callback(true);

    return {
      type: CRED_LOGIN_ACTION,
      payload: response.data,
    };
  } catch (error) {
    message.error("Google Login Failed.");
    callback(false);

    return {
      type: CRED_LOGIN_ACTION,
      error: true,
      payload: error,
    };
  }
};

export const userLogout = (callback) => {
  return actionFactory({
    api: "post/api/userauth/user/logout/",
    failureToast: true,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          callback(true);
        })
        .catch((_) => {
          callback(false);
        });
    },
  });
};

export const credLoginAction = async (values, callback) => {
  try {
    const response = await axios.post("api/userauth/user/token/", {
      username: values.username,
      password: values.password,
    });

    localStorage.setItem("token", `Token ${response.data.key}`);
    callback(true);

    return {
      type: CRED_LOGIN_ACTION,
      payload: response.data,
    };
  } catch (error) {
    message.error("Incorrect username or password.");
    callback(false);

    return {
      type: CRED_LOGIN_ACTION,
      error: true,
      payload: error,
    };
  }
};
