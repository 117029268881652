import {
  SET_VIDEO_RESOLUTION,
  SET_WALL_INPUT,
  SET_FLOOR_INPUT,
  GRID_SELECTOR_ENABLE,
  GENERATE_GRIDS_SUCCESS,
  UPDATE_GRIDS_SUCCESS,
  GET_GRIDS_SUCCESS,
  SELECTED_TAB,
  SELECTED_GRID,
  SET_GRID_DURATION,
  SET_NEW_GRID,
  SET_GRID_LIST,
  SET_HTI_OR_BOUNCE,
  SELECTED_GRID_POINT_INDEX,
} from "../actionTypes/GridConfig";

const DEFAULT_GRID_STATE = {
  grid_enable: false,
  video_resolution: [],
  wall_input: [null, null, null, null, null, null],
  floor_input: [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  selected_tab: "floor",
  selected_grid: {},
  duration: [],
  grid_list: [],
  hit_or_bounce: "hit",
  selected_grid_index: null,
};

export const GridConfigReducer = (state = DEFAULT_GRID_STATE, action) => {
  switch (action.type) {
    case GRID_SELECTOR_ENABLE:
      return { ...state, grid_enable: action.payload };
    case SET_VIDEO_RESOLUTION:
      return {
        ...state,
        video_resolution: action.payload,
      };
    case SET_WALL_INPUT:
      return {
        ...state,
        selected_grid: {
          ...state.selected_grid,
          wall_input: action.payload,
        },
      };
    case SET_FLOOR_INPUT:
      return {
        ...state,
        selected_grid: {
          ...state.selected_grid,
          floor_input: action.payload,
        },
      };
    case SET_GRID_DURATION:
      return {
        ...state,
        selected_grid: {
          ...state.selected_grid,
          duration: action.payload,
        },
      };
    case GENERATE_GRIDS_SUCCESS:
      return { ...state, selected_grid: action.payload.data };
    case UPDATE_GRIDS_SUCCESS:
      return { ...state, selected_grid: action.payload.data };
    case GET_GRIDS_SUCCESS:
      return {
        ...state,
        grid_list: action.payload.data.results || action.payload.data,
      };
    case SET_GRID_LIST:
      return { ...state, grid_list: action.payload };
    case SELECTED_TAB:
      return { ...state, selected_tab: action.payload };
    case SELECTED_GRID:
      return { ...state, selected_grid: action.payload };
    case SET_NEW_GRID:
      return {
        ...state,
        grid_list: [...state.grid_list, action.payload],
        selected_grid: action.payload,
      };
    case SET_HTI_OR_BOUNCE:
      return { ...state, hit_or_bounce: action.payload };
    case SELECTED_GRID_POINT_INDEX:
      return { ...state, selected_grid_index: action.payload };
    default:
      return state;
  }
};
