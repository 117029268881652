import { actionFactory } from "../../libs/actionFactory";
import { ALL_USERS } from "../actionTypes/Admin";
import { message } from "antd";
export const getAllUser = (params = {}, callback) => {
  return actionFactory({
    api: "get/api/userauth/profile/get/",
    actionBody: {
      params: { ...params },
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: ALL_USERS,
              payload: resp["data"].data,
            });
          }
          if (callback) {
            callback();
          }
        })
        .catch((err) => {});
    },
  });
};

export const updateUserGroups = (id, groups, successCallback = () => {}) => {
  return actionFactory({
    api: `patch/api/userauth/profile/set_group/${id}/`,
    actionBody: {
      body: { ...groups },
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            message.success("Groups updated successfully");
            dispatch(getAllUser());
            successCallback(resp.data);
          }
        })
        .catch((_err) => {});
    },
  });
};

export const addNewUser = (user, handleSubmitting) => {
  return actionFactory({
    api: "post/api/userauth/user/create/",
    actionBody: {
      body: user,
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp?.data?.status?.status_type === "SUCCESS") {
            handleSubmitting({status: "success", data: resp.data})
            message.success("User created successfully");
            dispatch(getAllUser());
          } 
        })
        .catch((err) => {
          // message.error(err?.data?.status?.status_type)
          handleSubmitting({status: "error", data: err.response.data.status.status_message})
        });
    },
  });
};