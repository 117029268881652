import { Button, Dropdown, Radio, Space } from "antd";
import "./CourtChart.scss";
import CourtLayout from "./CourtLayout/CourtLayout";
import { DownOutlined } from "@ant-design/icons";
import StatsTable from "./StatsTable/ShotStatsTable";
import { useEffect, useState } from "react";
import { shotKeysArray, tableItems } from "./FilterConstants";
import { hasIntegerKeys } from "../../../libs/utils";

const CourtChart = (props) => {
  const { selectedPlayerStats, disableSetsButton } = props;
  const [selectedGrid, setSelectedGrid] = useState(1);
  const [data, setData] = useState(
    selectedPlayerStats?.analysed_data?.match_data
  );
  const [tableData, setTableData] = useState(
    selectedPlayerStats?.analysed_data?.match_data
      ?.court_dest_position_vs_quality_table
  );
  const [selectedTableData, setSelectedTableData] = useState(null);

  const thirdFilterItemsArray = Object.keys(tableData || []);
  const thirdFilterItems = thirdFilterItemsArray.map((item) => ({
    key: item,
    label: `${item}`,
  }));

  const [tableFilterItem, setTableFilterItem] = useState(tableItems[0]);
  const [subTableFilterItem, setSubTableFilterItem] = useState({
    label: "Select",
  });

  const handleGridClick = (gridNumber) => {
    if (hasIntegerKeys(tableData) && /^\d+$/.test(tableData["1"])) {
      setSelectedGrid(null);
    } else {
      setSelectedGrid(gridNumber);
    }
  };

  const findObjectWithKeyValue = (array, keyToFind, valueToFind) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i][keyToFind] === valueToFind) {
        return array[i]; // Return the object if the value matches
      }
    }
    return null; // Return null if no matching value is found
  };

  const handleMenuClick = (e) => {
    const key = e.key;
    setTableData(data[key]);
    const item = findObjectWithKeyValue(tableItems, "key", key);
    setTableFilterItem(item);
  };

  const handleThirdFilterClick = (e) => {
    const key = e.key;
    setSelectedTableData(tableData[key]);
    const item = findObjectWithKeyValue(thirdFilterItems, "key", key);
    setSubTableFilterItem(item);
  };

  useEffect(() => {
    if (tableData) {
      const objectKeys = Object.keys(tableData);
      const keyExists = objectKeys.some((key) => shotKeysArray.includes(key));
      if (keyExists === false) {
        setSelectedTableData(null);
        setSubTableFilterItem("Select");
      }
    }

    const containsMediumKey =
      tableData && Object.keys(tableData).includes("Medium");
    const containsServeKey =
      tableData && Object.keys(tableData).includes("Serve");
    if (containsMediumKey) {
      setSelectedTableData(tableData["Medium"]);
      setSubTableFilterItem({ key: "Medium", label: "Medium" });
    } else if (containsServeKey) {
      setSelectedTableData(tableData["Serve"]);
      setSubTableFilterItem({ key: "Serve", label: "Serve" });
    }
  }, [tableData]);

  const menuProps = {
    items: tableItems,
    onClick: handleMenuClick,
  };

  const ShotTypeMenuProps = {
    items: thirdFilterItems,
    onClick: handleThirdFilterClick,
  };

  const onSetChange = (value) => {
    if (value === "match") {
      setData(selectedPlayerStats?.analysed_data?.match_data);
    } else {
      setData(selectedPlayerStats?.analysed_data?.set_data[value]);
    }
  };

  return (
    <>
      <div className="set-radio-buttons">
        <Radio.Group
          onChange={(e) => onSetChange(e.target.value)}
          defaultValue="match"
        >
          <Radio.Button value="match">Match</Radio.Button>
          <Radio.Button value="0" disabled={disableSetsButton[0]}>
            Set 1
          </Radio.Button>
          <Radio.Button value="1" disabled={disableSetsButton[1]}>
            Set 2
          </Radio.Button>
          <Radio.Button value="2" disabled={disableSetsButton[2]}>
            Set 3
          </Radio.Button>
          <Radio.Button value="3" disabled={disableSetsButton[3]}>
            Set 4
          </Radio.Button>
          <Radio.Button value="4" disabled={disableSetsButton[4]}>
            Set 5
          </Radio.Button>
        </Radio.Group>
      </div>
      <div className="filter-section">
        {data && (
          <div className="filter-items">
            <Dropdown menu={menuProps}>
              <Button>
                <Space>
                  {tableFilterItem.label || "Select"}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        )}
        {!hasIntegerKeys(tableData) && (
          <div className="filter-items">
            <Dropdown menu={ShotTypeMenuProps}>
              <Button>
                <Space>
                  {subTableFilterItem.label || "Select "}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        )}
      </div>
      <div className="court-table">
        <div className="court-layout">
          <CourtLayout
            handleGridClick={handleGridClick}
            selectedGrid={selectedGrid}
            data={selectedTableData || tableData}
            clWidth={'50vh'}
            clHeight={'66vh'}
          />
        </div>
        <div className="table-layout">
          <StatsTable
            selectedGrid={selectedGrid}
            analyzedData={
              selectedTableData
                ? selectedTableData[selectedGrid]
                : tableData
                ? hasIntegerKeys(tableData) && /^\d+$/.test(tableData["1"])
                  ? tableData
                  : tableData[selectedGrid]
                : {}
            }
          />
        </div>
      </div>
    </>
  );
};
export default CourtChart;
