import { useDispatch, useSelector } from "react-redux";
import {
  setAnnotationRowIndex,
  setSelectedAnnotation,
} from "../../../../store/actions/ReviewDetails";
import { selectAnnotations } from "../../../../store/selectors/ReviewDetails";
import "./ShotHeader.scss";
import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Menu,
  Radio,
  Space,
  Tooltip,
} from "antd";
import { DeleteOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import { isDeleteModalOpen } from "../../../../store/selectors/BulkSelectSelectors";
import CreateDeleteModal from "../../../CreateDeleteModal/CreateDeleteModal";
import { setIsDeleteModalOpen } from "../../../../store/actions/bulkSelectActions";
import { FilterOutlined, SyncOutlined } from "@ant-design/icons";
import {
  convertArrayToObject,
  createLabelValuePairs,
  createOptionsFromNumber,
  findIndexById,
} from "../../../../libs/utils";
import { ShotFilters, updateOptions } from "./ShotConstants";
import {
  qualityOptions,
  receiverFootPositionOptions,
  resultOptions,
  riskFactorOptions,
  shotTypes,
  speedOptions,
  tightnessOptions,
} from "../../../../libs/constants";
import { selectSelectedVideo } from "../../../../store/selectors/Match";

const { SubMenu } = Menu;

const ShotHeader = ({
  onFilterChange,
  onFilterReset,
  handleShot,
  onUpdateShot,
  mapIdRemap,
  showUpdateOption,
}) => {
  const dispatch = useDispatch();
  const isDeleteModalOpened = useSelector(isDeleteModalOpen);
  const [isCompleteClassName, setIsCompleteClassName] = useState([]);
  const annotations = useSelector(selectAnnotations);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [updateDropdownVisible, setUpdateDropdownVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([
    null,
    null,
    null,
    null,
  ]);
  const [selectedUpdates, setSelectedUpdates] = useState([null, null, null]);

  const [shotTypeFilter, setShotTypeFilter] = useState([]);
  const [qualityFilter, setQualityFilter] = useState([]);
  const [tightnessFilter, setTightnessFilter] = useState([]);
  const [speedFilter, setSpeedFilter] = useState([]);
  const [riskFactorFilter, setRiskFactorFilter] = useState([]);
  const [resultFilter, setResultFilter] = useState([]);
  const [filters, setFilters] = useState({});
  const match = useSelector(selectSelectedVideo);

  const handleDeleteModalOpen = () => {
    dispatch(setIsDeleteModalOpen(true));
  };

  const handleDeleteModalClose = () => {
    dispatch(setIsDeleteModalOpen(false));
  };

  useEffect(() => {
    annotations.some((item) => item.is_erroneous)
      ? setIsCompleteClassName("not-completed-indicator")
      : setIsCompleteClassName("completed-indicator");
  }, [annotations]);

  //navigate to incomplete annotation
  const navigateToIncompleteAnnotation = () => {
    const incompleteAnnotations = annotations.filter(
      (annotation) => annotation.is_erroneous
    );
    if (incompleteAnnotations.length > 0) {
      // Find the annotation with the lowest start_time among incomplete annotations
      const lowestTimeAnnotation = incompleteAnnotations.reduce(
        (prev, current) =>
          prev.start_time < current.start_time ? prev : current
      );
      const index = findIndexById(annotations, lowestTimeAnnotation?.id);
      dispatch(setSelectedAnnotation(lowestTimeAnnotation));
      dispatch(setAnnotationRowIndex(index));
    }
  };

  // shot list filter ----------------------------------------------------

  const handleDropdownVisibleChange = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleApplyFilterClick = () => {
    setDropdownVisible(false);
    const appliedFilters = [...selectedFilters];

    shotTypeFilter.length > 0 &&
      appliedFilters.push(`shot_type=${shotTypeFilter.join(",")}`);
    qualityFilter.length > 0 &&
      appliedFilters.push(`quality=${qualityFilter.join(",")}`);
    tightnessFilter.length > 0 &&
      appliedFilters.push(`tightness=${tightnessFilter.join(",")}`);
    speedFilter.length > 0 &&
      appliedFilters.push(`speed=${speedFilter.join(",")}`);
    riskFactorFilter.length > 0 &&
      appliedFilters.push(`risk_factor=${riskFactorFilter.join(",")}`);
    resultFilter.length > 0 &&
      appliedFilters.push(`result=${resultFilter.join(",")}`);
    if (filters) {
      Object.keys(filters).forEach((field) => {
        appliedFilters.push(`${field}=${filters[field]}`);
      });
    }

    onFilterChange(appliedFilters);
  };

  const handleFilterResetClick = () => {
    setDropdownVisible(false);
    setSelectedFilters([]);
    onFilterReset();
    setShotTypeFilter([]);
    setQualityFilter([]);
    setTightnessFilter([]);
    setSpeedFilter([]);
    setRiskFactorFilter([]);
    setResultFilter([]);
    setFilters({});
  };

  const onFilterOneChange = (e) => {
    const filterArray = [...selectedFilters];
    filterArray[0] = e.target.value;
    setSelectedFilters(filterArray);
  };

  const onFilterTwoChange = (e) => {
    const filterArray = [...selectedFilters];
    filterArray[1] = e.target.value;
    setSelectedFilters(filterArray);
  };

  const onFilterThirdChange = (e) => {
    const filterArray = [...selectedFilters];
    filterArray[2] = e.target.value;
    setSelectedFilters(filterArray);
  };

  const onFilterFourthChange = (e) => {
    const filterArray = [...selectedFilters];
    filterArray[3] = e.target.value;
    setSelectedFilters(filterArray);
  };

  // update shots -----------------------------
  const handleUpdateDropdownVisibleChange = (visible) => {
    setUpdateDropdownVisible(visible);
  };

  const handleUpdateClick = () => {
    setUpdateDropdownVisible(false);
    onUpdateShot(convertArrayToObject(selectedUpdates));
  };

  const handleSelectedUpdateResetClick = () => {
    setDropdownVisible(false);
    setSelectedUpdates([null, null, null]);
  };

  const onCompleteUpdateChange = (e) => {
    const toUpdateArray = [...selectedUpdates];
    toUpdateArray[0] = e.target.value;
    setSelectedUpdates(toUpdateArray);
  };

  const onErroneousUpdateChange = (e) => {
    const toUpdateArray = [...selectedUpdates];
    toUpdateArray[1] = e.target.value;
    setSelectedUpdates(toUpdateArray);
  };

  const onReviewedUpdateChange = (e) => {
    const toUpdateArray = [...selectedUpdates];
    toUpdateArray[2] = e.target.value;
    setSelectedUpdates(toUpdateArray);
  };

  const filterHandler = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const shotCheckboxFilters = (field, boolean, value) => {
    // Map field to the corresponding state variable and setter
    const filterMap = {
      shot_type: [shotTypeFilter, setShotTypeFilter],
      quality: [qualityFilter, setQualityFilter],
      tightness: [tightnessFilter, setTightnessFilter],
      speed: [speedFilter, setSpeedFilter],
      risk_factor: [riskFactorFilter, setRiskFactorFilter],
      result: [resultFilter, setResultFilter],
    };

    // Destructure array to get currentFilter and setFilter function
    // eslint-disable-next-line no-unused-vars
    const [currentFilter, setFilter] = filterMap[field];
    setFilter(value);
    // Update filter based on boolean value
    // if (boolean) {
    //   setFilter([...currentFilter, value]);
    // } else {
    //   setFilter(currentFilter.filter((item) => item !== value));
    // }
  };

  const radioButtonStyle = {
    height: "40px",
    lineHeight: "40px",
    marginLeft: "10px",
  };

  return (
    <div className="shot-header-container">
      <CreateDeleteModal
        open={isDeleteModalOpened}
        onClose={handleDeleteModalClose}
      />

      <span className="shots-header-text pointer" onClick={onFilterReset}>
        Shots
      </span>
      {annotations?.length > 1 && <div className="shots-header-button">
        <div className="indicator-section">
          {/* <Tooltip title="Complete Status"> */}
          <div
            className={`indicator ${isCompleteClassName}`}
            onClick={navigateToIncompleteAnnotation}
          ></div>
          {/* </Tooltip> */}
        </div>
        <div>
          <Tooltip title="Remap Point Id">
            <SyncOutlined className="shot-reload-icon" onClick={mapIdRemap} />
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete Shot">
            <DeleteOutlined
              className="shot-delete-icon"
              onClick={handleDeleteModalOpen}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Add New Shot">
            <PlusOutlined className="shot-plus-icon" onClick={handleShot} />
          </Tooltip>
        </div>
        {!showUpdateOption && (
          <Dropdown
            open={dropdownVisible}
            onOpenChange={(open, info) => setDropdownVisible(open)}
            overlay={
              <div className="shot-filter">
                <Menu
                  style={{
                    width: 200,
                    textAlign: "start",
                    overflowY: "auto",
                    maxHeight: "80vh",
                  }}
                  mode="vertical"
                  className="shot-filter-menu"
                >
                  <Radio.Group
                    value={filters?.player}
                    onChange={(e) => filterHandler("player", e.target.value)}
                    className="filter-player-box"
                  >
                    <Radio.Button className="filter-player-name ellipsis-text" value="1">
                      {match?.player1 || "Player 1"}
                    </Radio.Button>
                    <Radio.Button className="filter-player-name ellipsis-text" value="2">
                      {match?.player2 || "Player 2"}
                    </Radio.Button>
                  </Radio.Group>
                  <Divider style={{ margin: "0px" }} />
                  <SubMenu key="sets" title="Sets">
                    <Checkbox.Group
                      options={createOptionsFromNumber(5)}
                      value={filters?.sets}
                      onChange={(value) => filterHandler("sets", value)}
                    />
                  </SubMenu>

                  <SubMenu key="shotStatus" title="Shot Status">
                    <Menu>
                      <Radio.Group
                        onChange={onFilterOneChange}
                        value={selectedFilters[0]}
                      >
                        <Space direction="vertical">
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[0].value}
                          >
                            {ShotFilters[0].label}
                          </Radio>
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[1].value}
                          >
                            {ShotFilters[1].label}
                          </Radio>
                        </Space>
                      </Radio.Group>
                      <Menu.Divider />
                      <Radio.Group
                        onChange={onFilterTwoChange}
                        value={selectedFilters[1]}
                      >
                        <Space direction="vertical">
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[2].value}
                          >
                            {ShotFilters[2].label}
                          </Radio>
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[3].value}
                          >
                            {ShotFilters[3].label}
                          </Radio>
                        </Space>
                      </Radio.Group>
                      <Menu.Divider />
                      <Radio.Group
                        onChange={onFilterThirdChange}
                        value={selectedFilters[2]}
                      >
                        <Space direction="vertical">
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[4].value}
                          >
                            {ShotFilters[4].label}
                          </Radio>
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[5].value}
                          >
                            {ShotFilters[5].label}
                          </Radio>
                        </Space>
                      </Radio.Group>
                      <Menu.Divider />
                      <Radio.Group
                        onChange={onFilterFourthChange}
                        value={selectedFilters[3]}
                      >
                        <Space direction="vertical">
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[6].value}
                          >
                            {ShotFilters[6].label}
                          </Radio>
                          <Radio
                            style={radioButtonStyle}
                            value={ShotFilters[7].value}
                          >
                            {ShotFilters[7].label}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Menu>
                  </SubMenu>
                  <SubMenu key="shot_Type" title="Shot Type">
                    <Checkbox.Group
                      style={{ maxHeight: "500px", overflowY: "auto" }}
                      options={createLabelValuePairs(shotTypes)}
                      value={shotTypeFilter}
                      onChange={(value) =>
                        shotCheckboxFilters("shot_type", true, value)
                      }
                    />
                  </SubMenu>

                  <SubMenu key="quality" title="Quality">
                    <Checkbox.Group
                      options={createLabelValuePairs(qualityOptions)}
                      value={qualityFilter}
                      onChange={(value) =>
                        shotCheckboxFilters("quality", true, value)
                      }
                    />
                  </SubMenu>

                  <SubMenu key="tightness" title="Tightness">
                    <Checkbox.Group
                      options={createLabelValuePairs(tightnessOptions)}
                      value={tightnessFilter}
                      onChange={(value) =>
                        shotCheckboxFilters("tightness", true, value)
                      }
                    />
                  </SubMenu>

                  <SubMenu key="speed" title="Speed">
                    <Checkbox.Group
                      options={createLabelValuePairs(speedOptions)}
                      value={speedFilter}
                      onChange={(value) =>
                        shotCheckboxFilters("speed", true, value)
                      }
                    />
                  </SubMenu>

                  <SubMenu key="riskFactor" title="Risk Factor">
                    <Checkbox.Group
                      options={createLabelValuePairs(riskFactorOptions)}
                      value={riskFactorFilter}
                      onChange={(value) =>
                        shotCheckboxFilters("risk_factor", true, value)
                      }
                    />
                  </SubMenu>

                  <SubMenu
                    key="receiver_foot_position"
                    title="Receiver foot position"
                  >
                    <Checkbox.Group
                      options={createLabelValuePairs(
                        receiverFootPositionOptions
                      )}
                      value={filters?.receiver_foot_position}
                      onChange={(value) =>
                        filterHandler("receiver_foot_position", value)
                      }
                    />
                  </SubMenu>

                  <SubMenu key="result" title="Result">
                    <Checkbox.Group
                      options={createLabelValuePairs(resultOptions)}
                      value={resultFilter}
                      onChange={(value) =>
                        shotCheckboxFilters("result", true, value)
                      }
                    />
                  </SubMenu>

                  {/* new filter */}

                  <SubMenu key="source" title="Source">
                    <Checkbox.Group
                      options={createOptionsFromNumber(12)}
                      value={filters?.source}
                      onChange={(value) => filterHandler("source", value)}
                    />
                  </SubMenu>

                  <SubMenu key="destination" title="Destination">
                    <Checkbox.Group
                      options={createOptionsFromNumber(12)}
                      value={filters?.destination}
                      onChange={(value) => filterHandler("destination", value)}
                    />
                  </SubMenu>

                  <SubMenu key="frontwall" title="Front Wall">
                    <Checkbox.Group
                      options={createOptionsFromNumber(12)}
                      value={filters?.frontwall}
                      onChange={(value) => filterHandler("frontwall", value)}
                    />
                  </SubMenu>

                  <SubMenu key="backwall" title="Back Wall">
                    <Checkbox.Group
                      options={createOptionsFromNumber(6)}
                      value={filters?.backwall}
                      onChange={(value) => filterHandler("backwall", value)}
                    />
                  </SubMenu>

                  <Divider style={{ margin: "2px 0px 8px 0px" }} />
                  <div
                    className="filter-btn"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button onClick={handleFilterResetClick}>Reset</Button>
                    <Button type="primary" onClick={handleApplyFilterClick}>
                      Apply
                    </Button>
                  </div>
                </Menu>
              </div>
            }
            trigger={["click"]}
          >
            <div>
              <Tooltip title="Filters">
                <FilterOutlined
                  className="shot-filter-icon"
                  onClick={handleDropdownVisibleChange}
                />
              </Tooltip>
            </div>
          </Dropdown>
        )}

        {showUpdateOption && (
          <div>
            <Dropdown
              visible={updateDropdownVisible}
              onVisibleChange={handleUpdateDropdownVisibleChange}
              overlay={
                <Menu>
                  <Radio.Group
                    onChange={onCompleteUpdateChange}
                    value={selectedUpdates[0]}
                  >
                    <Space direction="vertical">
                      <Radio value={updateOptions[0].value}>
                        {updateOptions[0].label}
                      </Radio>
                      <Radio value={updateOptions[1].value}>
                        {updateOptions[1].label}
                      </Radio>
                    </Space>
                  </Radio.Group>
                  <Menu.Divider />
                  <Radio.Group
                    onChange={onErroneousUpdateChange}
                    value={selectedUpdates[1]}
                  >
                    <Space direction="vertical">
                      <Radio value={updateOptions[2].value}>
                        {updateOptions[2].label}
                      </Radio>
                      <Radio value={updateOptions[3].value}>
                        {updateOptions[3].label}
                      </Radio>
                    </Space>
                  </Radio.Group>
                  <Menu.Divider />
                  <Radio.Group
                    onChange={onReviewedUpdateChange}
                    value={selectedUpdates[2]}
                  >
                    <Space direction="vertical">
                      <Radio value={updateOptions[4].value}>
                        {updateOptions[4].label}
                      </Radio>
                      <Radio value={updateOptions[5].value}>
                        {updateOptions[5].label}
                      </Radio>
                    </Space>
                  </Radio.Group>
                  <Menu.Divider />
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Button
                      type="text"
                      onClick={handleSelectedUpdateResetClick}
                    >
                      Reset
                    </Button>
                    <Button type="primary" onClick={handleUpdateClick}>
                      Update
                    </Button>
                  </div>
                </Menu>
              }
              placement="bottomLeft"
              trigger={["click"]}
              overlayClassName="filter-dropdown"
            >
              <Button
                icon={<MoreOutlined />}
                type="text"
                className="update-eclipse-icon"
              ></Button>
            </Dropdown>
          </div>
        )}
      </div>}
    </div>
  );
};

export default ShotHeader;
