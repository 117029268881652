import { actionFactory } from "../../libs/actionFactory";

const handleApiResponse = (responsePromise, setStatus) => {
  setStatus({ status: "loading", data: "Processing your request..." });
  responsePromise
    .then((response) => {
      if (response["data"].status.status_type === "SUCCESS") {
        setStatus({ status: "success", data: response?.data?.data });
      }
    })
    .catch((error) => {
      setStatus({
        status: "error",
        data: error.response.data.status.status_message,
      });
    });
};

const formData = "multipart/form-data";
export const updateShotData = (match, newShotData, setStatus) =>
  actionFactory({
    api: `post/api/matches/${match.id}/annotations/bulk_create/`,
    ContentType: formData,
    actionBody: { body: newShotData },
    callback: (responsePromise) =>
      handleApiResponse(responsePromise, setStatus),
  });

export const replaceDataWithinRange = (match, newRangeData, setStatus) =>
  actionFactory({
    api: `put/api/matches/${match.id}/annotations/replace_data/`,
    ContentType: formData,
    actionBody: { body: newRangeData },
    callback: (responsePromise) =>
      handleApiResponse(responsePromise, setStatus),
  });

export const patchShotData = (match, partialShotData, setStatus) =>
  //TODO: add file handling if required
  actionFactory({
    api: `patch/api/matches/${match.id}/annotations/patch_data/`,
    ContentType: formData,
    actionBody: { body: partialShotData },
    callback: (responsePromise) =>
      handleApiResponse(responsePromise, setStatus),
  });

export const clearShotData = (match, setStatus) =>
  actionFactory({
    api: `delete/api/matches/${match.id}/annotations/clear_all/`,
    callback: (responsePromise) =>
      handleApiResponse(responsePromise, setStatus),
  });

export const clearGridData = (match, setStatus) =>
  actionFactory({
    api: `delete/api/matches/${match.id}/grids/clear_all/`,
    callback: (responsePromise) =>
      handleApiResponse(responsePromise, setStatus),
  });
