import React from "react";
import { ReactComponent as DeleteIcon } from "./static/Delete.svg";
import { RollbackOutlined } from "@ant-design/icons";
import "./GridConfigActions.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFloorInput,
  selectGridList,
  selectNewDimension,
  selectSelectedGrid,
  selectSelectedTab,
  selectWallInput,
  getGridIndex,
} from "../../../../store/selectors/GridConfig";
import {
  deleteGridCoordinates,
  generateGridCoordinates,
  setFloorCoordinatesInput,
  setGirdViewEnable,
  setGridList,
  setSelectedGrid,
  setWallCoordinatesInput,
  updateGenerateGridCoordinates,
  selectedGridIndex,
} from "../../../../store/actions/GridConfig";
import { message } from "antd";
import { selectSelectedVideo } from "../../../../store/selectors/Match";

const GridConfigActions = () => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectSelectedTab);
  const selectedMatch = useSelector(selectSelectedVideo);
  const wallInput = useSelector(selectWallInput) || [];
  const floorInput = useSelector(selectFloorInput) || [];
  const selectedGrid = useSelector(selectSelectedGrid);
  const gridList = useSelector(selectGridList);
  const newDimension = useSelector(selectNewDimension);
  const gridIndex = useSelector(getGridIndex);

  const handleUndo = () => {
    if (gridIndex !== null) {
      if (selectedTab === "front-wall") {
        const newWallInput = [...wallInput];
        newWallInput[gridIndex] = null;
        dispatch(setWallCoordinatesInput(newWallInput));
      } else if (selectedTab === "floor") {
        const newFloorInput = [...floorInput];
        newFloorInput[gridIndex] = null;
        dispatch(setFloorCoordinatesInput(newFloorInput));
      } else {
      }
    }
  };

  const handleClickDelete = () => {
    const notSavedGrid = /\bnew\b/.test(selectedGrid?.id);
    dispatch(setGirdViewEnable(false));
    dispatch(selectedGridIndex(null));
    if (notSavedGrid) {
      const newGridList = gridList.filter(
        (item) => item.id !== selectedGrid?.id
      );
      dispatch(setGridList(newGridList));
      dispatch(setSelectedGrid({}));
    } else {
      dispatch(deleteGridCoordinates(selectedGrid));
    }
  };

  const handleClickSave = () => {
    let newSelectedGrid = { ...selectedGrid, resolution: newDimension };

    if (selectedGrid?.id?.includes("new")) {
      if (selectedGrid.floor_input.length === 10) {
        newSelectedGrid = {
          ...newSelectedGrid,
          floor_input: [...newSelectedGrid.floor_input, null, null],
        };
      } else if (selectedGrid.floor_input.length === 11) {
        newSelectedGrid = {
          ...newSelectedGrid,
          floor_input: [...newSelectedGrid.floor_input, null],
        };
      } else if (selectedGrid.floor_input.length < 10) {
        message.warning("Select at least 10 points for floor.");
      }

      if (selectedGrid.wall_input.length === 4) {
        const wallInput = newSelectedGrid.wall_input;
        const modifiedWallInput = [
          null,
          null,
          wallInput[2],
          wallInput[3],
          wallInput[0],
          wallInput[1],
        ];
        newSelectedGrid = {
          ...newSelectedGrid,
          wall_input: modifiedWallInput,
        };
      } else if (selectedGrid.wall_input.length === 5) {
        const wallInput = newSelectedGrid.wall_input;
        const modifiedWallInput = [
          null,
          wallInput[4],
          wallInput[2],
          wallInput[3],
          wallInput[0],
          wallInput[1],
        ];
        newSelectedGrid = {
          ...newSelectedGrid,
          wall_input: modifiedWallInput,
        };
      } else if (selectedGrid.wall_input.length === 6) {
        const wallInput = newSelectedGrid.wall_input;
        const modifiedWallInput = [
          wallInput[5],
          wallInput[4],
          wallInput[2],
          wallInput[3],
          wallInput[0],
          wallInput[1],
        ];
        newSelectedGrid = {
          ...newSelectedGrid,
          wall_input: modifiedWallInput,
        };
      } else if (selectedGrid.wall_input.length < 4) {
        message.warning("Select at least 4 points for front wall.");
      }
    }

    if (
      newSelectedGrid?.id?.includes("new") &&
      newSelectedGrid.floor_input.length >= 10 &&
      newSelectedGrid.wall_input.length >= 4
    ) {
      dispatch(generateGridCoordinates(selectedMatch.id, newSelectedGrid));
    } else if (!newSelectedGrid?.id?.includes("new")) {
      let newSelectedGrid = { ...selectedGrid, resolution: newDimension };
      dispatch(updateGenerateGridCoordinates(newSelectedGrid));
    }
  };

  return (
    <div className="GridConfigActions-container">
      <div className="GridConfigActions-reset-button" onClick={handleUndo}>
        <RollbackOutlined />
      </div>
      <div
        className="GridConfigActions-reset-delete"
        onClick={handleClickDelete}
      >
        <DeleteIcon />
      </div>
      <div className="GridConfigActions-save-button" onClick={handleClickSave}>
        Save
      </div>
    </div>
  );
};

export default GridConfigActions;
