// Common data to be passed while creating a new player entry in the annotation list

//  null is a default value for properties whose value can be a number or true/false. We dont need to select any value on UI by default,
//  so keeping it as null
import moment from "moment";
import { jwtDecode } from "jwt-decode";

export const commonPlayerData = {
  // pointID: 1,
  // shotID: 1,
  start_time: null,
  shot_type: null,
  quality: null,
  speed: null,
  front_wall_position: null,
  backwall: null,
  tightness: null,
  risk_factor: null,
  init_ball_position: null,
  dest_ball_position: null,
  result: null,
  volley_possibility: null,
  isIncomplete: true,
  video: null,
  receiver_foot_position: null,
};

export const resetFields = {
  shot_type: null,
  quality: null,
  speed: null,
  front_wall_position: null,
  backwall: null,
  tightness: null,
  risk_factor: null,
  init_ball_position: null,
  dest_ball_position: null,
  result: null,
  volley_possibility: null,
  receiver_foot_position: null,
  isIncomplete: true,
};

export function checkProperties(obj) {
  const requiredProperties = [
    "start_time",
    "shot_type",
    "quality",
    "speed",
    "front_wall_position",
    "back_wall",
    "tightness",
    "risk_factor",
    "init_ball_position",
    "dest_ball_position",
    "result",
    "volley_possibility",
  ];

  return requiredProperties.every(
    (prop) => obj[prop] !== null && obj[prop] !== ""
  );
}

export const options = (playerList) => {
  playerList &&
    playerList.map((player, index) => {
      if (player.name !== "") {
        return {
          value: player.id,
          label: player.name,
        };
      } else {
        return {};
      }
    });
};

export const secondsToHMS = (seconds) => {
  const duration = moment.duration(seconds, "seconds");

  const formattedHours = Math.floor(duration.asHours())
    .toString()
    .padStart(2, "0");
  const formattedMinutes = duration.minutes().toString().padStart(2, "0");
  const formattedSeconds = duration.seconds().toString().padStart(2, "0");

  return seconds
    ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    : "00:00:00";
};

export const secondsToHMSM = (seconds) => {
  const duration = moment.duration(seconds, "seconds");

  const formattedHours = Math.floor(duration.asHours())
    .toString()
    .padStart(2, "0");
  const formattedMinutes = duration.minutes().toString().padStart(2, "0");
  const formattedSeconds = duration.seconds().toString().padStart(2, "0");
  const formattedMilliseconds = Math.floor(duration.milliseconds() / 10)
    .toString()
    .padStart(2, "0");

  return seconds
    ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`
    : "00:00:00.00";
};

export const HMSToSeconds = (formattedTime) => {
  const duration = moment.duration(formattedTime);

  const hoursInSeconds = duration.hours() * 3600;
  const minutesInSeconds = duration.minutes() * 60;
  const seconds = duration.seconds();
  const millisecondsInSeconds = duration.milliseconds() / 1000;

  return hoursInSeconds + minutesInSeconds + seconds + millisecondsInSeconds;
};

export const transformData = (data) => {
  if (data.length > 0) {
    return data.map((item) => {
      const transformedItem = { ...item };

      // Iterate through each key in the item
      for (const key in transformedItem) {
        const value = transformedItem[key];

        if (key === "start_time" && typeof value === "number") {
          transformedItem[key] = secondsToHMS(value);
        } else if (key === "player") {
          transformedItem[key] = value?.name;
        } else if (key === "risk_factor") {
          transformedItem[key] = value?.risk_factor_type;
        } else if (key === "shot_type") {
          transformedItem[key] = value?.title;
        } else if (key === "quality") {
          transformedItem[key] = value?.quality_type;
        } else if (key === "speed") {
          transformedItem[key] = value?.speed_type;
        } else if (key === "tightness") {
          transformedItem[key] = value?.tightness_type;
        }

        // Check if the value is a string representing a boolean
        else if (value === true || value === false) {
          transformedItem[key] = value === true ? "Yes" : "No";
        }
      }

      return transformedItem;
    });
  } else {
    return [];
  }
};

export const getGmailFromJwtToken = (jwtToken) => {
  let gmailId;
  try {
    const decodedToken = jwtDecode(jwtToken);
    gmailId = decodedToken ? decodedToken.email : null;
  } catch (error) {
    console.error("Error decoding JWT token:", error.message);
  }

  return gmailId;
};

export const isJwtToken = (token) => {
  try {
    jwtDecode(token);
    return true;
  } catch (error) {
    return false;
  }
};

export const cleanYouTubeUrl = (url) => {
  // Check if the URL is a YouTube video URL
  const isYouTubeUrl =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)/.test(url);

  if (isYouTubeUrl) {
    // Remove unwanted parameters from the URL
    const cleanedUrl = url.replace(/[?&](list|index)=[^?&]+/g, "");
    return cleanedUrl;
  } else {
    // Return the original URL if it is not a YouTube video URL
    return url;
  }
};

export const convertCoordinates = (
  initialDimensions,
  initialCoordinates,
  newDimensions
) => {
  // Destructure initial dimensions
  const [initialWidth, initialHeight] = initialDimensions;

  // Destructure new dimensions
  const [newWidth, newHeight] = newDimensions;

  // Calculate scaling factors
  const xScale = newWidth / initialWidth;
  const yScale = newHeight / initialHeight;

  // Convert coordinates based on scaling factors, keeping null values
  const newCoordinates = initialCoordinates.map((point) => {
    if (point === null) {
      return null;
    }

    const [x, y] = point;
    return [Math.round(x * xScale), Math.round(y * yScale)];
  });

  // Return the new coordinates
  return newCoordinates;
};

export const hasObjValueChanged = (obj1, obj2) => {
  const isObject = (obj) => obj && typeof obj === "object";

  if (!isObject(obj1) || !isObject(obj2)) {
    return true; // If either one is not an object, they are different
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return true; // If number of keys differs, they are different
  }

  for (let key of keys1) {
    if (!obj2.hasOwnProperty(key)) {
      return true; // If obj2 does not have the same key, they are different
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (isObject(value1) || Array.isArray(value1)) {
      // If value1 is a nested object or array, recursively compare
      if (hasObjValueChanged(value1, value2)) {
        return true;
      }
    } else if (value1 !== value2) {
      return true; // Values are different
    }
  }

  return false; // No differences found
};

export const hasIntegerKeys = (obj) => {
  for (const key in obj) {
    if (!isNaN(parseInt(key))) {
      return true;
    }
  }
  return false;
};

export const findIndexById = (arr, id) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === id) {
      return i; // Return the index if ID matches
    }
  }
  return -1; // Return -1 if ID not found
};

export const convertArrayToObject = (array) => {
  return array.reduce((acc, str) => {
    if (!str) return acc; // Skip empty or null strings
    const obj = JSON.parse(
      str.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": ')
    ); // Parse string to object
    return { ...acc, ...obj }; // Merge objects
  }, {});
};

export const createLabelValuePairs = (
  fieldNames,
  labelName = "title",
  valueNames = "value"
) => {
  return fieldNames.map((items) => {
    return {
      label: items[labelName],
      value: items[valueNames],
    };
  });
};

export const areSameArray = (var1, var2) =>
  var1 === var2 ||
  (Array.isArray(var1) &&
    Array.isArray(var2) &&
    var1.length === var2.length &&
    var1.every((value, index) => value === var2[index]));

export const createOptionsFromNumber = (passedValue, labelPreFix = "") =>
  Array.from({ length: passedValue }, (_, i) => ({
    label: `${labelPreFix} ${i + 1}`,
    value: String(i + 1),
  }));

export const parseHMSM = (value, oldValue = value) => {

  const defaultValue = secondsToHMSM(oldValue);

  if (!value) return defaultValue;

  const parts = value.split(/[:.]/).map(part => part ? Number(part) : 0);
  const parts1 = defaultValue.split(/[:.]/).map(part => part ? Number(part) : 0);

  if (parts.some(isNaN)) return defaultValue;

  const [hours, minutes, seconds, milliseconds] = [
    parts[0] || parts1[0],
    parts[1] <= 60 ? parts[1] : parts1[1],
    parts[2] <= 60 ? parts[2] : parts1[2],
    parts[3] <= 100 ? parts[3] : parts1[3],
  ];

  return (hours * 3600) + (minutes * 60) + seconds + (milliseconds * 0.01);
};

export const capitalizeFirstWord = (str) => {
  if (!str) return str;
  const words = str.split(' ');
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  return words.join(' ');
};
