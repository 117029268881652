import { Divider, Radio } from "antd";
import ShotQualityPie from "./ShotQualityPie";
import ShotTypeQualityBar from "./ShotTypeQualityBar";
import ShotSpeedPie from "./ShotSpeedPie";
import "./ShotQuality.scss";
import ShotTightnessPie from "./ShotTightness";
import { useEffect, useState } from "react";
import QualityVsSpeedBar from "./QualityVsSpeedBar";
import QualityVsTightnessBar from "./QualityVsTightnessBar";
import ShotVsSpeedVsTightnessBar from "./ShotVsSpeedVsTightnessBar";
import QualityVsSpeedVsTightnessBar from "./QualityVsSpeedVsTightnessBar";

const ShotQuality = (props) => {
  const { selectedPlayerStats, disableSetsButton } = props;
  const [dataSets, setDataSets] = useState(
    selectedPlayerStats?.analysed_data?.match_data
  );

  useEffect(() => {
    setDataSets(selectedPlayerStats?.analysed_data?.match_data);
  }, [selectedPlayerStats]);

  const onSetChange = (value) => {
    if (value === "match") {
      setDataSets(selectedPlayerStats?.analysed_data?.match_data);
    } else {
      setDataSets(selectedPlayerStats?.analysed_data?.set_data[value]);
    }
  };

  return (
    <>
      <div className="set-radio-buttons">
        <Radio.Group
          onChange={(e) => onSetChange(e.target.value)}
          defaultValue="match"
        >
          <Radio.Button value="match">Match</Radio.Button>
          <Radio.Button value="0" disabled={disableSetsButton[0]}>
            Set 1
          </Radio.Button>
          <Radio.Button value="1" disabled={disableSetsButton[1]}>
            Set 2
          </Radio.Button>
          <Radio.Button value="2" disabled={disableSetsButton[2]}>
            Set 3
          </Radio.Button>
          <Radio.Button value="3" disabled={disableSetsButton[3]}>
            Set 4
          </Radio.Button>
          <Radio.Button value="4" disabled={disableSetsButton[4]}>
            Set 5
          </Radio.Button>
        </Radio.Group>
      </div>
      {dataSets && (
        <div>
          <div className="pie-section">
            <ShotQualityPie data={dataSets?.shot_quality_table} />{" "}
            <ShotSpeedPie data={dataSets?.shot_speed_table} />
          </div>
          <Divider></Divider>
          <div className="pie-section">
            <ShotTightnessPie data={dataSets?.shot_tightness_table} />
          </div>
          <Divider></Divider>
          <ShotTypeQualityBar data={dataSets?.shottype_vs_quality} />
          <Divider></Divider>
          <div className="pie-section">
            <QualityVsSpeedBar data={dataSets?.quality_vs_speed_table} />
            <QualityVsTightnessBar
              data={dataSets?.quality_vs_tightness_table}
            />
          </div>
          <Divider></Divider>
          <ShotVsSpeedVsTightnessBar
            data={dataSets?.shottype_vs_speed_vs_tightness_table}
          />
          <Divider></Divider>
          <QualityVsSpeedVsTightnessBar
            data={dataSets?.quality_vs_speed_vs_tightness_table}
          />
        </div>
      )}
    </>
  );
};

export default ShotQuality;
