import React from "react";
import { Header, Menu } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./ChartTopNavigation.scss";
import { selectSelectedVideo } from "../../../store/selectors/Match";
import { setSelectedGrid } from "../../../store/actions/GridConfig";
import { Button, Radio, Tooltip } from "antd";
import UserProfile from "../../ReviewPage/ReviewTopNavigation/UserProfile";

const ChartTopNavigation = (props) => {
  const { handleSelectedPlayer, selectedPlayer, showPreviewFnc } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedMatch = useSelector(selectSelectedVideo);


  const handleHeaderClick = () => {
    dispatch(setSelectedGrid({}));
  };

  const handleBack = () => {
    navigate(`/match/${selectedMatch?.id}/review-details`);
  };

  return (
    <>
      <Menu secondary attached="top" className="charts-menu">
        <Menu.Item>
          <Link to={"/matches"}>
            <Header
              as={"h2"}
              className="chart-menu-header"
              onClick={handleHeaderClick}
            >
              Rally Vision
            </Header>
          </Link>
        </Menu.Item>
        <Button className="back-button" onClick={handleBack}>
          Back
        </Button>

        <Radio.Group
          className="player-radio-btn"
          onChange={(e) => handleSelectedPlayer(e.target.value)}
          buttonStyle="solid"
          value={selectedPlayer}
        >
          <Radio.Button
            className="player-button"
            value={1}
            style={{
              backgroundColor: selectedPlayer === 1 ? "#ECFDF3" : "initial",
              border:
                selectedPlayer === 1
                  ? "1px solid #037847"
                  : "1px solid initial",
            }}
          >
            {selectedMatch?.player1.split(" ")[0]}
          </Radio.Button>
          <Radio.Button
            className="player-button"
            value={2}
            style={{
              backgroundColor: selectedPlayer === 2 ? "#ECFDF3" : "initial",
              border:
                selectedPlayer === 2
                  ? "1px solid #037847"
                  : "1px solid initial",
            }}
          >
            {selectedMatch?.player2.split(" ")[0]}
          </Radio.Button>
        </Radio.Group>
        <Menu.Item position="right" className="action-icons">
          <Button onClick={() => showPreviewFnc()}>Show Preview</Button>
          <Tooltip placement="bottom" title="Info text">
            <FontAwesomeIcon
              icon={faCircleQuestion}
              size="xl"
              className="question-icon"
            />
          </Tooltip>
          <UserProfile />
        </Menu.Item>
      </Menu>
    </>
  );
};

export default ChartTopNavigation;
