import React, { useEffect, useState } from "react";
import TopNavigation from "../TopNavigation/TopNavigation";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import MatchList from "./MatchList/MatchList";
import MatchEditModal from "./MatchEditModal/MatchEditModal";
import {
  HMSToSeconds,
  parseHMSM,
  secondsToHMS,
  secondsToHMSM,
} from "../../libs/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  addMatch,
  listVideos,
  setMatchList,
  setSelectedVideo,
  updateMatch,
} from "../../store/actions/Match";
import { selectMatchList } from "../../store/selectors/Match";
import MatchHeader from "./MatchList/MatchHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import DevelopersModal from "./DevelopersModal/DevelopersModal";

const timeWithMillisecondsSchema = Yup.string().matches(
  /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)(?:\.[0-9]{1,2})?$/,
  "HH:MM:SS.ms Invalid time format "
);

const timeWithoutMillisecondsSchema = Yup.string().matches(
  /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/,
  "Invalid time format (HH:MM:SS)"
);

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  url: Yup.string().url("Invalid URL format").required("URL is required"),
  player1: Yup.string().required("Player A name is required"),
  player2: Yup.string().required("Player B name is required"),
  start_time: timeWithoutMillisecondsSchema.required("Start time is required"),
  end_time: timeWithoutMillisecondsSchema.required("End time is required"),
  set_1: timeWithMillisecondsSchema,
  set_2: timeWithMillisecondsSchema,
  set_3: timeWithMillisecondsSchema,
  set_4: timeWithMillisecondsSchema,
  set_5: timeWithMillisecondsSchema,
});

const defaultMatchValues = {
  title: "",
  url: "",
  player1: "",
  player2: "",
  start_time: "00:00:00",
  end_time: "00:00:00",
  set_1: "00:00:00.00",
  set_2: "00:00:00.00",
  set_3: "00:00:00.00",
  set_4: "00:00:00.00",
  set_5: "00:00:00.00",
  is_complete: false,
};

const MatchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matchListItems = useSelector((state) => selectMatchList(state));
  const [matchModalOpen, setMatchModalOpen] = useState(false);
  const [toAddNewMatch, setToAddNewMatch] = useState(false);
  const [devModal, setDevModal] = useState({
    isOpen: false,
    additionalData: null
  });


  useEffect(() => {
    dispatch(listVideos());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: defaultMatchValues,
    validationSchema: validationSchema,
  });

  const handleAddMatch = () => {
    setMatchModalOpen(true);
    setToAddNewMatch(true);
    formik.setValues(defaultMatchValues);
  };

  const handleEditMatch = (match) => {
    setMatchModalOpen(true);
    formik.setValues({
      ...match,
      start_time: secondsToHMS(match?.start_time),
      end_time: secondsToHMS(match?.end_time),
      set_1: secondsToHMSM(match?.sets[0]),
      set_2: secondsToHMSM(match?.sets[1]),
      set_3: secondsToHMSM(match?.sets[2]),
      set_4: secondsToHMSM(match?.sets[3]),
      set_5: secondsToHMSM(match?.sets[4]),
    });
  };

  const handleMatchAddAndUpdate = (values) => {
    const updatedMatch = { ...values };
    updatedMatch["start_time"] = HMSToSeconds(values?.start_time);
    updatedMatch["end_time"] = HMSToSeconds(values?.end_time);


    if (toAddNewMatch) {
      dispatch(addMatch(updatedMatch));
      setMatchModalOpen(false);
      setToAddNewMatch(false);
    } else {
      updatedMatch["sets"] = [
        parseHMSM(values?.set_1),
        parseHMSM(values?.set_2),
        parseHMSM(values?.set_3),
        parseHMSM(values?.set_4),
        parseHMSM(values?.set_5),
      ];
      const index = matchListItems.findIndex((item) => item.id === values.id);
      const updatedMatchListItems = [...matchListItems];
      updatedMatchListItems[index] = updatedMatch;
      dispatch(updateMatch(updatedMatch));
      dispatch(setMatchList(updatedMatchListItems));
      setMatchModalOpen(false);
    }
  };

  const handleSelectedMatch = (match) => {
    dispatch(setSelectedVideo(match));
    navigate(`/match/${match.id}/review-details`);
  };

  const closeModal = () => {
    setMatchModalOpen(false);
    setToAddNewMatch(false);
    formik?.handleReset();
  };

  return (
    <Row>
      <TopNavigation />
      {devModal.isOpen && (
        <DevelopersModal open={devModal} setOpen={setDevModal} />
      )}
      <MatchHeader
        handleAddMatch={handleAddMatch}
        matchListItems={matchListItems}
        handleSelectedMatch={handleSelectedMatch}
        />
      <MatchList
        handleEditMatch={handleEditMatch}
        matchListItems={matchListItems}
        handleDevModal = {setDevModal}
      />
      {matchModalOpen && (
        <MatchEditModal
          formik={formik}
          matchModalOpen={matchModalOpen}
          closeModal={closeModal}
          handleMatchAddAndUpdate={handleMatchAddAndUpdate}
          toAddNewMatch={toAddNewMatch}
        />
      )}
    </Row>
  );
};

export default MatchPage;
