import "./UserList.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { userFormInitialValues, userListColumns } from "./adminConstant";
import TopNavigation from "../TopNavigation/TopNavigation";
import { getAllUser } from "../../store/actions/Admin";
import { allUserSelector } from "../../store/selectors/Admin";
import { UserAddOutlined } from "@ant-design/icons";
import UserFormModal from "./UserFormModal";

const UserList = () => {
  const userModalinitalState = { open: false, type: "" , payload: {} }
  const [userModal, setUserModal] = useState(userModalinitalState);

  const userFormHandler = (mode, payload = {}) => {
    // Set the modal state based on the mode and payload
    setUserModal({ open: mode !== "close", type: mode, payload });
  };

  const closeModal = () => setUserModal(userModalinitalState)
  
  // Initialize dispatch function
  const dispatch = useDispatch();

  // Get the users from the Redux store using the selector
  const users = useSelector(allUserSelector);

  // Fetch all users when the component mounts
  useEffect(() => {
    dispatch(getAllUser());
  }, [dispatch]);

  return (
    <div>
    {userModal.open && <UserFormModal modalState={userModal} onClose={closeModal} />}
      {/* Top navigation component */}
      <TopNavigation />
      <div className="users-list">
        {/* Heading for the users list */}
        <div className="user-list-sub-header">
          <h2 className="users-list-heading">Users</h2>
          <Button
            onClick={() => userFormHandler("add", userFormInitialValues)}
            type="text"
            icon={<UserAddOutlined />}
          >
            Add User
          </Button>
          {/* Table component from Ant Design with dataSource and columns */}
        </div>
        <Table
          dataSource={users}
          columns={userListColumns}
          pagination={false} // Disable pagination
        />
      </div>
    </div>
  );
};

export default UserList;
