export const DEFAULT_DELTATIME = -0.5;
export const DEFAULT_END_DELTATIME = 0.0;

export const shotTypes = [
  { id: "shot1", value: "SER", title: "Serve" },
  { id: "shot2", value: "DRI", title: "Straight Drive" },
  { id: "shot3", value: "DRP", title: "Drop" },
  { id: "shot5", value: "BOA", title: "Boast" },
  { id: "shot6", value: "REV", title: "Reverse Boast" },
  { id: "shot7", value: "VOD", title: "Volley Drive" },
  { id: "shot8", value: "VDI", title: "Volley Cross Drive" },
  { id: "shot9", value: "VOK", title: "Volley Kill" },
  { id: "shot10", value: "VOL", title: "Volley Lob" },
  { id: "shot11", value: "VDP", title: "Volley Drop" },
  { id: "shot12", value: "VBO", title: "Volley Boast" },
  { id: "shot13", value: "VCD", title: "Volley Cross Drop" },
  { id: "shot14", value: "CDI", title: "Cross Drive" },
  { id: "shot15", value: "CDP", title: "Cross Drop" },
  { id: "shot16", value: "CLB", title: "Cross Lob" },
  { id: "shot17", value: "CVL", title: "Cross Volley Lob" },
  { id: "shot18", value: "CVK", title: "Cross Volley Kill" },
  { id: "shot19", value: "BBO", title: "Backwall Boast" },
  { id: "shot4", value: "LOB", title: "Lob" },
  { id: "shot20", value: "UDF", title: "UNDF" },
];

export const qualityOptions = [
  { id: "quality1", value: "LOW", title: "Low" },
  { id: "quality2", value: "MED", title: "Medium" },
  { id: "quality3", value: "HIG", title: "High" },
  { id: "quality4", value: "UDF", title: "UNDF" },
];

export const speedOptions = [
  { id: "speed1", value: "SLO", title: "Slow" },
  { id: "speed2", value: "MED", title: "Medium" },
  { id: "speed3", value: "FAS", title: "Fast" },
  { id: "speed4", value: "UDF", title: "UNDF" },
];

export const tightnessOptions = [
  { id: "tightness1", value: "LOO", title: "Loose" },
  { id: "tightness2", value: "MED", title: "Medium" },
  { id: "tightness3", value: "TIG", title: "Tight" },
  { id: "tightness4", value: "UDF", title: "UNDF" },
];

export const riskFactorOptions = [
  { id: "risk1", value: "LOW", title: "Low" },
  { id: "risk2", value: "MED", title: "Medium" },
  { id: "risk3", value: "HIG", title: "High" },
  { id: "risk4", value: "UDF", title: "UNDF" },
];

export const resultOptions = [
  { id: "result1", value: "LET", title: "Let" },
  { id: "result7", value: "N/A", title: "N/A" },
  { id: "result2", value: "NLT", title: "No Let" },
  { id: "result3", value: "STR", title: "Stroke" },
  { id: "result5", value: "FOE", title: "Forced Error" },
  { id: "result4", value: "UFE", title: "Unforced Error" },
  { id: "result6", value: "WIN", title: "Winner" },
  { id: "result8", value: "UDF", title: "UNDF" },
];

export const receiverFootPositionOptions = [
  { id: "position1", value: "LLT", title: "Low-Left-T" },
  { id: "position2", value: "LCT", title: "Low-Center-T" },
  { id: "position3", value: "LRT", title: "Low-Right-T" },
  { id: "position4", value: "HLT", title: "High-Left-T" },
  { id: "position5", value: "HCT", title: "High-Center-T" },
  { id: "position6", value: "HRT", title: "High-Right-T" },
  { id: "position7", value: "OOP", title: "Out of Position" },
  { id: "position8", value: "UDF", title: "UNDF" },
];

export const typesOfCharts = [
  {
    label: "Shot Quality",
    value: "shot_quality_table",
  },
  {
    label: "Shot Speed",
    value: "shot_speed_table",
  },
  {
    label: "Shot Tightness",
    value: "shot_tightness_table",
  },
  {
    label: "Shot Type vs Quality | Categorized Shot Quality",
    value: "shottype_vs_quality",
  },
  {
    label: "Quality & Speed ",
    value: "quality_vs_speed_table",
  },
  {
    label: "Quality & Tightness",
    value: "quality_vs_tightness_table",
  },

  {
    label: "Shot Type vs Speed vs Tightness Table",
    value: "shottype_vs_speed_vs_tightness_table",
  },
  {
    label: "Quality vs Speed vs Tightness Table",
    value: "quality_vs_speed_vs_tightness_table",
  },

  {
    label: "Point Progression",
    value: "score_progression",
  },

  {
    label: "Weak Shot Analysis",
    value: "shottype_vs_quality",
  },
];

export const statusTag = {
  Completed: {
    dotColor: "#8B4513",
    textColor: "#8B4513",
    backgroundColor: "#FFFACD",
  },
  Reviewed: {
    dotColor: "#14ba6d",
    textColor: "#037847",
    backgroundColor: "#ecfdf3",
  },
  "Incomplete": {
    dotColor: "#dc6a00",
    textColor: "#dc6a00",
    backgroundColor: "#ffe8d3",
  },
  default: {
    dotColor: "#FFFFFF",
    textColor: "#FFFFFF",
    backgroundColor: "#808080",
  },
}
