 /* eslint-disable */
import React, { useEffect } from "react";
import "./GridDropDown.scss";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  getGridList,
  setNewGrid,
  setSelectedGrid,
} from "../../../../store/actions/GridConfig";
import {
  selectGridList,
  selectSelectedGrid,
} from "../../../../store/selectors/GridConfig";
import { selectSelectedVideo } from "../../../../store/selectors/Match";

const defaultNewGrid = {
  id: "new",
  duration: [[0, 0]],
  wall_input: [null,null,null,null,null,null],
  floor_input: [null,null,null,null,null,null,null,null,null,null,null,null],
  wall_output: [],
  floor_output: [],
  resolution: null,
};

const GridDropDown = () => {
  const dispatch = useDispatch();
  const selectedMatch = useSelector(selectSelectedVideo);
  const gridList = useSelector(selectGridList);
  const selectedGrid = useSelector(selectSelectedGrid);

  useEffect(() => {
    dispatch(getGridList(selectedMatch?.id));
  }, []);

  const handleChange = (value) => {
    dispatch(setSelectedGrid(value));
    // setSelectedValue(value);
  };

  const handleNewGrid = () => {
    const lastGrid = gridList[gridList.length - 1];
    if (lastGrid?.id.includes("new")) {
      const newGrid = { ...defaultNewGrid, id: lastGrid.id + "1" };
      dispatch(setNewGrid(newGrid));
    } else {
      dispatch(setNewGrid(defaultNewGrid));
    }
  };

  const renderDropdown = () => (
    <div className="add-grid-container" onClick={handleNewGrid}>
      <div className="divider"></div>
      <div className="add-grid-action">Create New</div>
    </div>
  );

  return (
    <div className="gridDropdown-container">
      <SelectDropdown
        options={gridList}
        value={selectedGrid}
        onChange={handleChange}
        placeholder={"Select Grid"}
        renderDropdown={renderDropdown}
      />
    </div>
  );
};

export default GridDropDown;
