import React, { useState } from "react";
import { Header, Menu } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./ReviewTopNavigation.scss";
import { selectSelectedVideo } from "../../../store/selectors/Match";
import { setSelectedGrid } from "../../../store/actions/GridConfig";
import { downloadCsvFile } from "../../../store/actions/ReviewDetails";
import { Button, Tooltip } from "antd";
import {
  deleteOldDerive,
  setPlayer1ChartStats,
  setPlayer2ChartStats,
} from "../../../store/actions/Charts";
import { reviewPageInfoText } from "./InfoTextConstants";
import UserProfile from "./UserProfile";

const ReviewTopNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedMatch = useSelector(selectSelectedVideo);
  const [loading, setLoading] = useState(false);
  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);


  const csvSuccessCallback = () => {
    setCsvDownloadLoading(false);
  };

  const handleHeaderClick = () => {
    dispatch(setSelectedGrid({}));
  };

  const CsvDownloadCallback = () => {
    setCsvDownloadLoading(true);
    dispatch(downloadCsvFile(selectedMatch?.id, csvSuccessCallback));
  };

  const downloadHandle = () => {
    setCsvDownloadLoading(true);
    dispatch(deleteOldDerive(selectedMatch?.id, CsvDownloadCallback));
  };

  const chartsCallback = (loading, success) => {
    setLoading(loading);
    !loading && success && navigate(`/match/${selectedMatch?.id}/charts`);
  };
  const handleChartsView = () => {
    setLoading(true);
    dispatch(setPlayer1ChartStats({}));
    dispatch(setPlayer2ChartStats({}));
    dispatch(deleteOldDerive(selectedMatch?.id, chartsCallback, true)); // to delete old derive data and will trigger to generate new derive data & sending true for chartview
  };

  return (
    <>
      <Menu secondary attached="top" className="review-details-menu" style={{ height: "48px" }}>
        <Menu.Item>
          <Link to={"/matches"}>
            <Header
              as={"h2"}
              className="grid-menu-header"
              onClick={handleHeaderClick}
            >
              Rally Vision
            </Header>
          </Link>
        </Menu.Item>
        <Menu.Item className="match-title">{selectedMatch?.title}</Menu.Item>
        <Button
          onClick={handleChartsView}
          className="graph-btn"
          loading={loading}
        >
          View Chart
        </Button>
        <Menu.Item position="right" className="action-icons">
          <Tooltip placement="bottom" title={reviewPageInfoText}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              size="xl"
              className="question-icon"
            />
          </Tooltip>
          <Tooltip title="Download shots Data">
            <Button
              type="text"
              size="large"
              onClick={downloadHandle}
              className="download-icon"
              loading={csvDownloadLoading}
              icon={<FontAwesomeIcon icon={faFileExcel} size="lg" />}
            />
          </Tooltip>
          <UserProfile />
        </Menu.Item>

      </Menu>

    </>
  );
};

export default ReviewTopNavigation;
