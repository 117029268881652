import {
  SET_SELECTED_ANNOTATION,
  SET_ANNOTATIONS,
  SET_PERVIOUS_ANNOTATION,
  SET_PARTIALLY_UPDATED_ANNOTATION,
  SET_ANNOTATION_ROW_INDEX,
  SET_SHOT_LIST,
  UPDATE_ANNOTATION,
} from "../actionTypes/ReviewDetails";

const DEFAULT_SELECTED_ANNOTATION = {
  selectedAnnotation: null,
  previousAnnotation: null,
  partialUpdateAnnotation: null,
  selectedAnnotationRowIndex: null,
};

const DEFAULT_ANNOTATIONS = [];

export const annotationReducer = (
  state = DEFAULT_SELECTED_ANNOTATION,
  action
) => {
  switch (action.type) {
    case SET_SELECTED_ANNOTATION:
      return {
        ...state,
        selectedAnnotation: action.payload,
      };
    case SET_PERVIOUS_ANNOTATION:
      return {
        ...state,
        previousAnnotation: action.payload,
      };
    case SET_PARTIALLY_UPDATED_ANNOTATION:
      return {
        ...state,
        partialUpdateAnnotation: action.payload,
      };
    case SET_ANNOTATION_ROW_INDEX:
      return {
        ...state,
        selectedAnnotationRowIndex: action.payload,
      };
    default:
      return state;
  }
};

export const annotationListReducer = (state = DEFAULT_ANNOTATIONS, action) => {
  switch (action.type) {
    case SET_ANNOTATIONS:
      return action.payload;
    case SET_SHOT_LIST:
      return action.payload;
    case UPDATE_ANNOTATION: {
      const updatedData = action.payload;
      const index = state.findIndex((obj) => obj.id === updatedData?.id);

      // If the item is found, update it immutably
      if (index !== -1) {
        const newState = [...state];
        newState[index] = { ...updatedData };
        return newState;
      }
      // If the item is not found, return the state unchanged
      return state;
    }

    default:
      return state;
  }
};
