 /* eslint-disable */
import React, { useEffect, useState } from "react";
import { Line } from "react-konva";
import { useSelector } from "react-redux";
import {
  selectGeneratedFloorGrid,
  selectGeneratedWallGrid,
  selectHitOrBounce,
  selectInitialDimension,
} from "../../../store/selectors/GridConfig";
import { convertCoordinates } from "../../../libs/utils";

const GridSegmentSelector = (props) => {
  const { dimensions, selectedShot, handleFieldUpdate } = props;
  const floorCoordinates = useSelector(selectGeneratedFloorGrid);
  const wallCoordinates = useSelector(selectGeneratedWallGrid);
  const initialDimensions = useSelector(selectInitialDimension);
  const [floor, setFloorCoordinates] = useState(floorCoordinates);
  const [wall, setWallCoordinates] = useState(wallCoordinates);
  const hitOrBounce = useSelector(selectHitOrBounce);

  useEffect(() => {
    floorCoordinates?.length === 20 &&
      setFloorCoordinates(
        convertCoordinates(initialDimensions, floorCoordinates, dimensions)
      );
    wallCoordinates?.length === 20 &&
      setWallCoordinates(
        convertCoordinates(initialDimensions, wallCoordinates, dimensions)
      );
  }, [dimensions]);

  const wallGrids = wall &&
    wall.length > 0 && {
      1: [
        wall[0][0],
        wall[0][1],
        wall[1][0],
        wall[1][1],
        wall[6][0],
        wall[6][1],
        wall[5][0],
        wall[5][1],
        wall[0][0],
        wall[0][1],
      ],
      2: [
        wall[1][0],
        wall[1][1],
        wall[2][0],
        wall[2][1],
        wall[7][0],
        wall[7][1],
        wall[6][0],
        wall[6][1],
        wall[1][0],
        wall[1][1],
      ],
      3: [
        wall[2][0],
        wall[2][1],
        wall[3][0],
        wall[3][1],
        wall[8][0],
        wall[8][1],
        wall[7][0],
        wall[7][1],
        wall[2][0],
        wall[2][1],
      ],
      4: [
        wall[3][0],
        wall[3][1],
        wall[4][0],
        wall[4][1],
        wall[9][0],
        wall[9][1],
        wall[8][0],
        wall[8][1],
        wall[3][0],
        wall[4][1],
      ],
      5: [
        wall[5][0],
        wall[5][1],
        wall[6][0],
        wall[6][1],
        wall[11][0],
        wall[11][1],
        wall[10][0],
        wall[10][1],
        wall[5][0],
        wall[5][1],
      ],
      6: [
        wall[6][0],
        wall[6][1],
        wall[7][0],
        wall[7][1],
        wall[12][0],
        wall[12][1],
        wall[11][0],
        wall[11][1],
        wall[6][0],
        wall[6][1],
      ],
      7: [
        wall[7][0],
        wall[7][1],
        wall[8][0],
        wall[8][1],
        wall[13][0],
        wall[13][1],
        wall[12][0],
        wall[12][1],
        wall[7][0],
        wall[7][1],
      ],
      8: [
        wall[8][0],
        wall[8][1],
        wall[9][0],
        wall[9][1],
        wall[14][0],
        wall[14][1],
        wall[13][0],
        wall[13][1],
        wall[8][0],
        wall[8][1],
      ],
      9: [
        wall[10][0],
        wall[10][1],
        wall[11][0],
        wall[11][1],
        wall[16][0],
        wall[16][1],
        wall[15][0],
        wall[15][1],
        wall[10][0],
        wall[10][1],
      ],
      10: [
        wall[11][0],
        wall[11][1],
        wall[12][0],
        wall[12][1],
        wall[17][0],
        wall[17][1],
        wall[16][0],
        wall[16][1],
        wall[11][0],
        wall[11][1],
      ],
      11: [
        wall[12][0],
        wall[12][1],
        wall[13][0],
        wall[13][1],
        wall[18][0],
        wall[18][1],
        wall[17][0],
        wall[17][1],
        wall[12][0],
        wall[12][1],
      ],
      12: [
        wall[13][0],
        wall[13][1],
        wall[14][0],
        wall[14][1],
        wall[19][0],
        wall[19][1],
        wall[18][0],
        wall[18][1],
        wall[13][0],
        wall[13][1],
      ],
    };

  const floorGrids = floor && {
    1: [
      floor[0][0],
      floor[0][1],
      floor[1][0],
      floor[1][1],
      floor[5][0],
      floor[5][1],
      floor[4][0],
      floor[4][1],
      floor[0][0],
      floor[0][1],
    ],
    2: [
      floor[1][0],
      floor[1][1],
      floor[2][0],
      floor[2][1],
      floor[6][0],
      floor[6][1],
      floor[5][0],
      floor[5][1],
      floor[1][0],
      floor[1][1],
    ],
    3: [
      floor[2][0],
      floor[2][1],
      floor[3][0],
      floor[3][1],
      floor[7][0],
      floor[7][1],
      floor[6][0],
      floor[6][1],
      floor[2][0],
      floor[2][1],
    ],
    4: [
      floor[4][0],
      floor[4][1],
      floor[5][0],
      floor[5][1],
      floor[9][0],
      floor[9][1],
      floor[8][0],
      floor[8][1],
      floor[4][0],
      floor[4][1],
    ],
    5: [
      floor[5][0],
      floor[5][1],
      floor[6][0],
      floor[6][1],
      floor[10][0],
      floor[10][1],
      floor[9][0],
      floor[9][1],
      floor[5][0],
      floor[5][1],
    ],
    6: [
      floor[6][0],
      floor[6][1],
      floor[7][0],
      floor[7][1],
      floor[11][0],
      floor[11][1],
      floor[10][0],
      floor[10][1],
      floor[6][0],
      floor[6][1],
    ],
    7: [
      floor[8][0],
      floor[8][1],
      floor[9][0],
      floor[9][1],
      floor[13][0],
      floor[13][1],
      floor[12][0],
      floor[12][1],
      floor[8][0],
      floor[8][1],
    ],
    8: [
      floor[9][0],
      floor[9][1],
      floor[10][0],
      floor[10][1],
      floor[14][0],
      floor[14][1],
      floor[13][0],
      floor[13][1],
      floor[9][0],
      floor[9][1],
    ],
    9: [
      floor[10][0],
      floor[10][1],
      floor[11][0],
      floor[11][1],
      floor[15][0],
      floor[15][1],
      floor[14][0],
      floor[14][1],
      floor[10][0],
      floor[10][1],
    ],
    10: [
      floor[12][0],
      floor[12][1],
      floor[13][0],
      floor[13][1],
      floor[17][0],
      floor[17][1],
      floor[16][0],
      floor[16][1],
      floor[12][0],
      floor[12][1],
    ],
    11: [
      floor[13][0],
      floor[13][1],
      floor[14][0],
      floor[14][1],
      floor[18][0],
      floor[18][1],
      floor[17][0],
      floor[17][1],
      floor[13][0],
      floor[13][1],
    ],
    12: [
      floor[14][0],
      floor[14][1],
      floor[15][0],
      floor[15][1],
      floor[19][0],
      floor[19][1],
      floor[18][0],
      floor[18][1],
      floor[14][0],
      floor[14][1],
    ],
  };

  const handleWallGridClick = (gridNumber) => {
    handleFieldUpdate("front_wall_position", gridNumber);
  };

  const handleFloorHitGridClick = (gridNumber) => {
    handleFieldUpdate("init_ball_position", gridNumber);
  };

  const handleFloorBounceGridClick = (gridNumber) => {
    handleFieldUpdate("dest_ball_position", gridNumber);
  };

  const propsGenrator = (segment) => {
    if (
      floorGrids[selectedShot?.init_ball_position] === segment &&
      floorGrids[selectedShot?.dest_ball_position] === segment
    ) {
      return {
        fill: "rgba(0, 255, 0, 0.2)",
        stroke: "red",
        strokeWidth: 2,
      };
    }
    if (floorGrids[selectedShot?.init_ball_position] === segment) {
      const stroke = hitOrBounce === "hit" ? "red" : "orange";
      return {
        fill: "rgba(255, 255, 0, 0.2)",
        stroke: stroke,
        strokeWidth: 2,
      };
    }
    if (floorGrids[selectedShot?.dest_ball_position] === segment) {
      const stroke = hitOrBounce === "bounce" ? "red" : "orange";
      return {
        fill: "rgba(255, 255, 0, 0.2)",
        stroke: stroke,
        strokeWidth: 2,
      };
    }
    return {
      stroke: "transparent",
    };
  };

  return (
    <>
      {/* to Render clickable wall grid sections */}
      {wallGrids &&
        Object.values(wallGrids).map((segment, index) => (
          <Line
            key={index}
            points={segment}
            closed
            stroke="transparent"
            strokeWidth={2}
            onClick={() => handleWallGridClick(index + 1)}
          />
        ))}

      {/* to Render clickable floor ball Hit grid sections */}
      {floorGrids &&
        hitOrBounce === "hit" &&
        Object.values(floorGrids).map((segment, index) => (
          <Line
            key={index}
            points={segment}
            closed
            {...propsGenrator(segment)}
            onClick={() => handleFloorHitGridClick(index + 1)}
          />
        ))}

      {/* to Render clickable bounce ball Hit grid sections */}
      {floorGrids &&
        hitOrBounce === "bounce" &&
        Object.values(floorGrids).map((segment, index) => (
          <Line
            key={index}
            points={segment}
            closed
            {...propsGenrator(segment)}
            onClick={() => handleFloorBounceGridClick(index + 1)}
          />
        ))}

      {/* Highlight the Selected wall Grid */}
      {wallGrids && selectedShot?.front_wall_position !== null && (
        <Line
          points={wallGrids[selectedShot?.front_wall_position]}
          closed
          fill="rgba(255, 255, 0, 0.2)"
          stroke="orange"
          strokeWidth={2}
        />
      )}
    </>
  );
};

export default GridSegmentSelector;
