 /* eslint-disable */
import React, { useEffect, useState } from "react";
import "./BottomSection.scss";
import { useDispatch, useSelector } from "react-redux";
import { InputNumber,Radio, } from "antd";

import { selectSelectedVideo } from "../../../store/selectors/Match";
import { selectAnnotation } from "../../../store/selectors/ReviewDetails";
import { setSelectedAnnotation } from "../../../store/actions/ReviewDetails";

const BottomSection = (props) => {
  const { formik, handleInputChange, handleActivePlayer } = props;
  const dispatch = useDispatch();
  const match = useSelector(selectSelectedVideo);
  const selectedShot = useSelector(selectAnnotation);
  const [setId, setSetId] = useState(0);
  const activePlayer = selectedShot?.player;

  useEffect(() => {
    const time = selectedShot?.start_time;
    if (match?.sets && match?.sets.length > 0) {
      const set1 = match?.sets[0];
      const set2 = match?.sets[1];
      const set3 = match?.sets[2];
      const set4 = match?.sets[3];
      const set5 = match?.sets[4];
      if (time >= set5 && set5 !== null) {
        setSetId(5);
      } else if (time >= set4 && set4 !== null) {
        setSetId(4);
      } else if (time >= set3 && set3 !== null) {
        setSetId(3);
      } else if (time >= set2 && set2 !== null) {
        setSetId(2);
      } else if (time > set2 && set2 !== null) {
        setSetId(1);
      } else {
        setSetId(1);
      }
    }
  }, [selectedShot?.start_time, match]);

  const handleSetChange = (increment = 0) => {
    const updatedSet = setId + increment;
    if (updatedSet > 0 && updatedSet < 6) {
      setSetId(updatedSet);
      dispatch(
        setSelectedAnnotation({
          ...selectedShot,
          set: updatedSet,
        })
      );
    }
  };
  return (
    <div className="bottom-section">
      <div className="scoring">
        <div className="scoring-group">
          <div className="shot-id">
            <label htmlFor="inputValue">Shot ID</label>
            <div className="shot-id-container">
              <input
                className="shot-id-input"
                id="shot-idInput"
                name="shot-id"
                value={formik?.values?.shot_id ? formik.values?.shot_id : "-" }
                readOnly
                disabled
              />
            </div>
          </div>
          <div className="set-point_id">
            <label htmlFor="point_id">Rally ID</label>
            <div className="set-point_id-container">
              <InputNumber
                type="number"
                className="set-point_id-input"
                id="point_id"
                name="point_id"
                value={formik.values?.point_id}
                onChange={(value) => handleInputChange("point_id", value)}
              />
            </div>
          </div>
          <div className="set-no">
            <label htmlFor="inputValue">Set No.</label>
            <div className="set-no-container">
              {/* <button
                className="decrement-btn"
                type="button"
                onClick={() => handleSetChange(-1)}
              >
                -
              </button> */}
              <input
                className="set-no-input"
                id="setNoInput"
                name="set"
                value={setId}
                readOnly
                disabled
              />
              {/* <button
                className="increment-btn"
                type="button"
                onClick={() => handleSetChange(1)}
              >
                +
              </button> */}
            </div>
          </div>
          <div className="current-score-container">
            <div className="player-name-label"></div>
            <div className="score-input-fields">
              <div className="score-input-field">
                <label htmlFor="inputValue ellipsis-text">
                  {match?.player1}
                </label>
                <InputNumber
                  className="score1-input"
                  min={0}
                  max={1000}
                  value={
                    Number.isInteger(formik.values?.current_score?.[0])
                      ? formik.values?.current_score?.[0]
                      : ""
                  }
                  onChange={(value) =>
                    handleInputChange("current_score", value, 0)
                  }
                />
              </div>
              <div className="score-input-field">
                <label htmlFor="inputValue ellipsis-text">
                  {match?.player2}
                </label>
                <InputNumber
                  className="score2-input"
                  min={0}
                  max={1000}
                  value={
                    Number.isInteger(formik.values?.current_score?.[1])
                      ? formik.values?.current_score?.[1]
                      : ""
                  }
                  onChange={(value) =>
                    handleInputChange("current_score", value, 1)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="active-player">
        <div className="active-player-label">Striker</div>
        <Radio.Group
          onChange={(e) => handleActivePlayer(e.target.value)}
          buttonStyle="solid"
          value={activePlayer}
        >
          <Radio.Button
            className="radio-button ellipsis-text"
            value={1}
            style={{
              backgroundColor: activePlayer === 1 ? "#ECFDF3" : "initial",
              border:
                activePlayer === 1 ? "1px solid #037847" : "1px solid initial",
            }}
          >
            {match?.player1}
          </Radio.Button>
          <Radio.Button
            className="radio-button ellipsis-text"
            value={2}
            style={{
              backgroundColor: activePlayer === 2 ? "#ECFDF3" : "initial",
              border:
                activePlayer === 2 ? "1px solid #037847" : "1px solid initial",
            }}
          >
            {match?.player2}
          </Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

export default BottomSection;
