import { selectUser } from "../store/selectors/User";
import { store } from "../store/store";

// permissions.js
const permissions = {
  admin: {
    canUpdateMatch: true,
    canCreateMatch: true,
    canUpdateReviewStatus: true,
    canUpdateMatchStatus:true,
    canUpdateShot:true,
    // TODO: Add Permissions
  },
  expert: {
    canUpdateMatch: true,
    canUpdateReviewStatus: false,
    canUpdateMatchStatus:true,
    canUpdateShot:true,
    // TODO: Add Permissions
  },
  developer: {
    canUpdateMatch: false,
    canPatchMatch: true,
    // TODO: Add Permissions
  },
  viewer: {
    canUpdateMatch: false,
    canViewAdminComponent: false,
    canViewUserComponent: true,
    // TODO: Add Permissions
  },
  guest: {
    // TODO: Add Permissions
  },
};

const roleHierarchy = ["admin", "expert", "developer", "viewer", "guest"];

export const checkPermissions = (action) => {
  const state = store.getState();
  const user = selectUser(state);
  let userRoles = ["guest"];

  if (user && user.groups) {
    userRoles = user.groups;
  }

  // Determine the highest priority role
  let highestPriorityRole = "guest";
  for (let role of roleHierarchy) {
    if (userRoles.includes(role)) {
      highestPriorityRole = role;
      break;
    }
  }

  // Check permissions for the highest priority role and all higher roles
  let hasPermission = false;
  for (let i = roleHierarchy.indexOf(highestPriorityRole); i < roleHierarchy.length; i++) {
    const currentRole = roleHierarchy[i];
    if (permissions[currentRole] && permissions[currentRole][action] !== undefined) {
      hasPermission = permissions[currentRole][action];
      break;
    }
  }

  return hasPermission;
};
