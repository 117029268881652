 /* eslint-disable */
import React, { useEffect } from "react";
import { Radio } from "antd";
import "../CreateShotDataFormFields.scss";

const CustomField = ({ label, fieldName, options, type, formik, drawerOpen }) => {
  const handleShortcut = (e, keyCode, targetFieldName) => {
    if (drawerOpen && ((e.shiftKey || e.ctrlKey) && e.keyCode === keyCode && fieldName === targetFieldName)) {
      e.preventDefault();
      const currentIndex = options.findIndex(
        (option) => option.value === formik.values[fieldName]
      );
      const nextIndex = (currentIndex + 1) % options.length;
      const nextOption = options[nextIndex];
      formik.setFieldValue(fieldName, nextOption.value);
    }
    if (
      drawerOpen &&
      e.ctrlKey &&
      e.shiftKey &&
      e.keyCode === keyCode &&
      fieldName === targetFieldName
    ) {
      e.preventDefault();
      const currentIndex = options.findIndex(
        (option) => option.value === formik.values[fieldName]
      );
      let previousIndex = (currentIndex - 1) % options.length;
      if (previousIndex < 0) {
        previousIndex += options.length; // Ensure positive index
      }
      const previousOption = options[previousIndex];
      formik.setFieldValue(fieldName, previousOption.value);
    }
  };

  const handleKeyDown = (e) => {
    handleShortcut(e, 81, "quality"); // Shift + Q (KeyCode for Q is 81)
    handleShortcut(e, 83, "shot_type"); // Shift + S (KeyCode for S is 83)
    handleShortcut(e, 69, "tightness"); // Shift + E (KeyCode for E is 69)
    handleShortcut(e, 68, "speed"); // Shift + D (KeyCode for D is 68)
    handleShortcut(e, 82, "risk_factor"); // Shift + R (KeyCode for R is 82)
    handleShortcut(e, 70, "receiver_foot_position"); // Shift + F (KeyCode for F is 70)
    handleShortcut(e, 90, "result"); // Shift + Z (KeyCode for Z is 90)
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown); // Attach event listener to window
    return () => {
      window.removeEventListener("keydown", handleKeyDown); // Clean up event listener
    };
  }, [formik.values[fieldName], drawerOpen]);

  switch (type) {
    case "RADIO_FIELD":
      return (
        <div className="field">
          <label style={{ marginTop: "0px", marginBottom: "0px" }}>
            {label}
          </label>
          <Radio.Group
            style={{ textAlign: "start" }}
            onChange={(e) => {
              formik.handleChange({
                target: { name: fieldName, value: e.target.value },
              });
            }}
            value={formik.values[fieldName]}
          >
            {options.map((option) => (
              <Radio.Button
                key={option.value}
                value={option.value}
                onClick={(e) => {
                  // unselect the click button
                  const selectedValue = formik.values[fieldName];
                  if (selectedValue === e.target.value) {
                    formik.handleChange({
                      target: { name: fieldName, value: null },
                    });
                  }
                }}
              >
                {option.title || option[fieldName + "_type"]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      );
    default:
      return null;
  }
};

export default CustomField;
