import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "semantic-ui-css/semantic.min.css"
import { HashRouter as Router } from "react-router-dom"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { Provider } from "react-redux"
import { store } from "./store/store"

const root = ReactDOM.createRoot(document.getElementById("root"))
const clientID = process.env.REACT_APP_CLIENT_ID
root.render(
  <GoogleOAuthProvider clientId={clientID}>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </GoogleOAuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
