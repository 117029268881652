import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "./StatusLabel.scss";
import { statusTag } from "../../../libs/constants";

/**
 * StatusLabel component displays a status label with optional additional information.
 * @param {object} props - The component props.
 * @param {string} props.status - The status to display.
 * @returns {JSX.Element} - The rendered StatusLabel component.
 */
const StatusLabel = React.memo(({ status }) => {
  // Memoize the style properties based on the status
  const { dotColor, textColor, backgroundColor } = useMemo(
    () => statusTag[status] || statusTag.default,
    [status],
  );


  return (
    <div className="status-tag-label" style={{ backgroundColor }}>
      {/* Render the dot with the appropriate color */}
      <div className="dot" style={{ backgroundColor: dotColor }}></div>
      {/* Render the status text with the appropriate color */}
      <div className="text" style={{ color: textColor }}>
        {status}
      </div>
    </div>
  );
});

// Set display name for better debugging experience
StatusLabel.displayName = "StatusLabel";

// Define prop types for type checking
StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusLabel;
