import {
  GET_PLAYER_1_CHART_STATS,
  GET_PLAYER_2_CHART_STATS,
} from "../actionTypes/Charts";

const DEFAULT_CHART_STATS = {
  player1_stats: {},
  player2_stats: {},
};

export const ChartReducer = (state = DEFAULT_CHART_STATS, action) => {
  switch (action.type) {
    case GET_PLAYER_1_CHART_STATS:
      return {
        ...state,
        player1_stats: action.payload.data,
      };
    case GET_PLAYER_2_CHART_STATS:
      return {
        ...state,
        player2_stats: action.payload.data,
      };
    default:
      return state;
  }
};
