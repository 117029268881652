import React, { useEffect } from "react";
import * as echarts from "echarts";

const ShotTypeQualityBar = ({ data }) => {
  useEffect(() => {
    // Initialize bar graph container
    const chartContainer = document.getElementById("shot-quality-bar-graph");
    const chart = echarts.init(chartContainer);

    // Define bar graph options
    const options = {
      title: {
        text: " Categorized Shot Quality",
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: ["High", "Medium", "Low"],
      },
      xAxis: {
        type: "category",
        name: "Shot Types",
        data: [
          "Serve",
          "Straight Drive",
          "Drop",
          "Lob",
          "Boast",
          "Reverse Boast",
          "Volley Drive",
          "Volley Kill",
          "Volley Lob",
          "Cross Drive",
          "Cross Drop",
          "Cross Lob",
          "Cross Volley Lob",
          "Cross Volley Kill",
        ],
        axisLabel: {
          interval: 0,
          rotate: 45,
          formatter: (value) => {
            return value !== " " ? value : "Shot Types"; // Show "Shot Types" at the last category position
          },
        },
      },
      yAxis: {
        type: "value",
        name: "Shots",
      },
      series: [
        {
          name: "High",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            data?.Serve?.High,
            data && data["Straight Drive"] ? data["Straight Drive"].High : 0,
            data?.Drop?.High,
            data?.Lob?.High,
            data?.Boast?.High,
            data && data["Reverse Boast"] ? data["Reverse Boast"].High : 0,
            data && data["Volley Drive"] ? data["Volley Drive"].High : 0, // Check if 'Volley Drive' exists
            data && data["Volley Kill"] ? data["Volley Kill"].High : 0,
            data && data["Volley Lob"] ? data["Volley Lob"].High : 0,
            data && data["Cross Drive"] ? data["Cross Drive"].High : 0,
            data && data["Cross Drop"] ? data["Cross Drop"].High : 0,
            data && data["Cross Lob"] ? data["Cross Lob"].High : 0,
            data && data["Cross Volley Lob"]
              ? data["Cross Volley Lob"].High
              : 0,
            data && data["Cross Volley Kill"]
              ? data["Cross Volley Kill"].High
              : 0,
          ],
          color: "#1fd137",
        },
        {
          name: "Medium",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            data?.Serve?.Medium,
            data && data["Straight Drive"] ? data["Straight Drive"].Medium : 0,
            data?.Drop?.Medium,
            data?.Lob?.Medium,
            data?.Boast?.Medium,
            data && data["Reverse Boast"] ? data["Reverse Boast"].Medium : 0,
            data && data["Volley Drive"] ? data["Volley Drive"].Medium : 0, // Check if 'Volley Drive' exists
            data && data["Volley Kill"] ? data["Volley Kill"].Medium : 0,
            data && data["Volley Lob"] ? data["Volley Lob"].Medium : 0,
            data && data["Cross Drive"] ? data["Cross Drive"].Medium : 0,
            data && data["Cross Drop"] ? data["Cross Drop"].Medium : 0,
            data && data["Cross Lob"] ? data["Cross Lob"].Medium : 0,
            data && data["Cross Volley Lob"]
              ? data["Cross Volley Lob"].Medium
              : 0,
            data && data["Cross Volley Kill"]
              ? data["Cross Volley Kill"].Medium
              : 0,
          ],
          color: "#faf74d",
        },
        {
          name: "Low",
          type: "bar",
          stack: "Ad",
          emphasis: {
            focus: "series",
          },
          data: [
            data?.Serve?.Low,
            data && data["Straight Drive"] ? data["Straight Drive"].Low : 0,
            data?.Drop?.Low,
            data?.Lob?.Low,
            data?.Boast?.Low,
            data && data["Reverse Boast"] ? data["Reverse Boast"].Low : 0,
            data && data["Volley Drive"] ? data["Volley Drive"].Low : 0, // Check if 'Volley Drive' exists
            data && data["Volley Kill"] ? data["Volley Kill"].Low : 0,
            data && data["Volley Lob"] ? data["Volley Lob"].Low : 0,
            data && data["Cross Drive"] ? data["Cross Drive"].Low : 0,
            data && data["Cross Drop"] ? data["Cross Drop"].Low : 0,
            data && data["Cross Lob"] ? data["Cross Lob"].Low : 0,
            data && data["Cross Volley Lob"] ? data["Cross Volley Lob"].Low : 0,
            data && data["Cross Volley Kill"]
              ? data["Cross Volley Kill"].Low
              : 0,
          ],
          color: "#ff6259",
        },
      ],
    };

    // Set options and render chart
    chart.setOption(options);

    // Cleanup function to dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      <div
        id="shot-quality-bar-graph"
        style={{ width: "100%", height: "500px" }}
      ></div>
    </>
  );
};

export default ShotTypeQualityBar;
