import * as Yup from "yup";
import UserGroupsUpdater from "./UserGroupUpdater";
// Options for the user groups
const groupsOptions = [
  { label: "Admin", value: "admin" },
  { label: "Expert", value: "expert" },
  { label: "Developer", value: "developer" },
  { label: "Viewer", value: "viewer" },
];

// Shared properties for the user group selector component
export const sharedProps = {
  mode: "multiple", // Allows multiple selections
  style: { width: "100%", maxWidth: "26rem" }, // Sets the width of the selector
  options: groupsOptions, // Provides options for the selector
  placeholder: "Update Group", // Placeholder text for the selector
  maxTagCount: "responsive", // Adjusts the number of tags displayed responsively
};

// Column definitions for the user list table
export const userListColumns = [
  {
    title: "Email", // Column title
    dataIndex: "email", // Data index in the data source
    key: "email", // Unique key for the column
  },
  {
    title: "User name", // Column title
    dataIndex: "username", // Data index in the data source
    key: "username", // Unique key for the column
  },
  {
    title: "Groups", // Column title
    dataIndex: "groups", // Data index in the data source
    key: "groups", // Unique key for the column
    render: (groups, record) => <UserGroupsUpdater data={record} />, // Custom render function for the column
    width: "30rem", // Sets the width of the column
  },
];


export const userFormInitialValues = {
  username: "",
  first_name: "",
  last_name: "",
  password: "",
  email: "",
  is_staff: false,
  groups: [],
};

export const userFormValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string(),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
  //   .required('Password confirmation is required'),
  email: Yup.string().email("Invalid email").required("Email is required"),
  // confirmEmail: Yup.string()
  //   .oneOf([Yup.ref('email'), null], 'Emails must match')
  //   .required('Email confirmation is required'),
  groups: Yup.array().of(Yup.string()),
});
