import { useCallback, useEffect, useRef, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import "./CreateShotDataDrawer.scss";
import CreateShotDataFormFields from "./CreateShotDataFormFields/CreateShotDataFormFields";

const CreateShotDataDrawer = (props) => {
  const { formik } = props;
  const contentDivRef = useRef(null);
  const [contentStyle, setContentStyle] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(false);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((prevState) => !prevState);
  }, []);

  const getSize = useCallback(() => {
    if (contentDivRef.current) {
      const height = contentDivRef.current.offsetHeight;
      const heightOfWrapper = height;
      setContentHeight(heightOfWrapper);
      if (!drawerOpen) {
        const newStyle = {
          bottom: "0px",
          height: "0px",
          overflow: "hidden",
          transition: "height 0.3s ease",
        };
        setContentStyle(newStyle);
      } else {
        const totalHeight = contentHeight ;
        const newStyle = {
          transition: "height 0.3s ease",
          bottom: "0",
          height: totalHeight,
        };
        setContentStyle(newStyle);
      }
    }
  }, [contentHeight, drawerOpen]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.shiftKey && e.keyCode === 32) {
        // Shift + Space bar pressed
        toggleDrawer();
      }
    },
    [toggleDrawer]
  );

  useEffect(() => {
    getSize();
    window.addEventListener("resize", getSize);
    window.addEventListener("keydown", handleKeyDown); // Attach event listener to window
    return () => {
      window.removeEventListener("resize", getSize);
      window.removeEventListener("keydown", handleKeyDown); // Clean up event listener
    };
  }, [drawerOpen, getSize, handleKeyDown]); // Adding drawerOpen to dependencies to re-attach event listener when drawer state changes

  return (
    <div
      className={`grid-bottom-drawer ${
        drawerOpen ? "drawer_open" : ""
      }`}
    >
      <div
        style={contentStyle}
        className={`grid-drawer-wrapper ${
          drawerOpen ? "grid-drawer-wrapper-visible" : ""
        }`}
      >
        <div
          className={`bottom-drawer-button ${drawerOpen ? "collapse" : ""}`}
          style={{bottom : contentStyle?.height}}
          onClick={toggleDrawer}
        >
          {drawerOpen ? <DownOutlined /> : "Shot Data"}
        </div>
        <div className="bottom-drawer-content" ref={contentDivRef}>
          <CreateShotDataFormFields formik={formik} drawerOpen={drawerOpen} />
        </div>
      </div>
    </div>
  );
};

export default CreateShotDataDrawer;
