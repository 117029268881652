import React from "react";
import { Header, Menu } from "semantic-ui-react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./GridConfigPage.scss";
import { selectSelectedVideo } from "../../store/selectors/Match";
import { setSelectedGrid } from "../../store/actions/GridConfig";
import UserProfile from "../ReviewPage/ReviewTopNavigation/UserProfile";

const GridConfigTopNavigation = () => {
  ;
  const dispatch = useDispatch();
  const selectedMatch = useSelector(selectSelectedVideo);


  const handleHeaderClick = () => {
    dispatch(setSelectedGrid({}));
  };
  return (
    <>
      <Menu secondary attached="top" className="grid-config-menu">
        <Menu.Item>
          <Link to={"/matches"}>
            <Header
              as={"h2"}
              className="grid-menu-header"
              onClick={handleHeaderClick}
            >
              Rally Vision
            </Header>
          </Link>
        </Menu.Item>
        <Menu.Item className="video-url">
          <input
            className="video-url-input"
            value={selectedMatch?.url || "videoUrl"}
            readOnly
            style={{ width: "40rem" }}
          />
        </Menu.Item>
        <Menu.Item position="right" className="power-icon">
          <UserProfile />
        </Menu.Item>
      </Menu>
    </>
  );
};

export default GridConfigTopNavigation;
