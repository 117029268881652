import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmButton.scss';
import { Button, Popconfirm } from 'antd';

const ConfirmButton = ({ onClick, title, buttonText, confirmText, ...props }) => {
    return (
        <Popconfirm
            title={title}
            description={confirmText}
            onConfirm={onClick}
            okText="Yes"
            cancelText="No"
        >
            <Button {...props} className="primary-button">
                {buttonText}
            </Button>
        </Popconfirm>
    );
};

ConfirmButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    confirmText: PropTypes.string,
};

ConfirmButton.defaultProps = {
    title: 'Confirm',
    buttonText: 'Delete',
    confirmText: 'Are you sure to perform this task?',
};

export default ConfirmButton;
