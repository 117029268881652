import React, { useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ShotRow.scss";
import {
  addBulkSelect,
  clearBulkSelect,
  deleteBulkSelect,
} from "../../../../store/actions/bulkSelectActions";
import {
  selectBulkSelectStatus,
  selectBulkSelectValues,
} from "../../../../store/selectors/BulkSelectSelectors";
import { ANNOTATIONS_TAB } from "../../../../constants";
import { CheckOutlined } from "@ant-design/icons";
const ShotRow = ({
  selectedAnnotation,
  annotation,
  handleRowClick,
  annotationsRef,
  index,
}) => {
  // Ref to store the timeout for long press detection
  const pressTimeout = useRef(null);
  // State to track whether a long press is detected
  const [isLongPress, setIsLongPress] = useState(false);

  // Redux hooks for dispatching actions and selecting state
  const dispatch = useDispatch();

  // Selectors to get bulk select status and values from Redux state
  const isBulkSelectActive = useSelector((state) =>
    selectBulkSelectStatus(state, ANNOTATIONS_TAB)
  );
  const bulkSelectValues = useSelector((state) =>
    selectBulkSelectValues(state, ANNOTATIONS_TAB)
  );

  const isItemSelected = bulkSelectValues?.includes(annotation.id);

  const score = annotation.current_score;

  // Callback function to dispatch the addBulkSelect action
  const handleUpdate = useCallback(() => {
    dispatch(addBulkSelect(ANNOTATIONS_TAB, annotation.id));
  }, [dispatch, annotation.id]);

  // Callback function for the start of a press event
  const handlePressStart = useCallback(() => {
    // Set a timeout to detect a long press
    pressTimeout.current = setTimeout(() => {
      setIsLongPress(true);
      // If not in bulk select mode, perform the update
      if (!isBulkSelectActive) {
        handleUpdate();
      }
    }, 1800);
  }, [handleUpdate, isBulkSelectActive]);

  // Callback function for the end of a press event
  const handlePressEnd = useCallback(
    (event) => {
      // Prevent default and stop propagation for touch events
      event.preventDefault();
      event.stopPropagation();

      // Clear the long press timeout
      clearTimeout(pressTimeout.current);

      // Handle different actions based on bulk select mode
      if (isBulkSelectActive) {
        if (!isLongPress) {
          if (isItemSelected) {
            dispatch(
              bulkSelectValues?.length === 1
                ? clearBulkSelect(ANNOTATIONS_TAB)
                : deleteBulkSelect(ANNOTATIONS_TAB, annotation.id)
            );
          } else {
            handleUpdate();
          }
        }
      } else {
        // If not in bulk select mode, handle the row click
        handleRowClick(annotation, index);
      }

      // Reset the long press state
      setIsLongPress(false);
    },
    [
      isBulkSelectActive,
      isLongPress,
      isItemSelected,
      dispatch,
      bulkSelectValues?.length,
      annotation,
      handleUpdate,
      handleRowClick,
      index,
    ]
  );

  // Define the CSS classes based on different conditions
const classNames = [
  "shot-row",
  selectedAnnotation?.id === annotation?.id && "annotations-row-selected-player",
  annotation?.is_shortlisted && "shortlisted-annotation-row",
  annotation?.is_erroneous && "erroneous-annotation-row",
  annotation?.is_visited && "is_visited-annotation-row",
  annotation.is_expert_added && "is_expert_added_row",
  bulkSelectValues?.includes(annotation.id) && "bulkSelected",
].filter(Boolean);

  
  // Render the component
  return (
    <div
      className={classNames.join(" ")}
      onMouseDown={handlePressStart}
      onMouseUp={handlePressEnd}
      onTouchStart={handlePressStart}
      onTouchEnd={handlePressEnd}
      onTouchMove={(event) => event.preventDefault()}
      ref={(el) => {
        // Set the annotation ref for external use
        annotationsRef.current = el;
      }}
    >
      {isItemSelected ? (
        <div className="annotation-bulk-selected">
          {" "}
          <CheckOutlined />{" "}
        </div>
      ) : (
        <></>
      )}
      <div className="row-cell">
        <div className="name-time">
          <div className="player-name">{annotation.name} </div>
          <div className="start-time">
            {annotation.start_time}
            </div>
        </div>
        <div className="row-right-section">
          <div className="rally-id">Rally ID: {annotation.point_id}</div>
          <div className="player-score">
            {score ? (
              <>
                {score[0]} - {score[1]}
              </>
            ) : (
              <> &nbsp; - &nbsp;&nbsp;</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShotRow;
