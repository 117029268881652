import React from "react";
import "./FrontWallGridPointsInput.scss";
import CircularGridPoint from "../CircularGridPoint/CircularGridPoint";
import { selectedGridIndex } from "../../../../../store/actions/GridConfig";
import {
  selectWallInput,
  getGridIndex,
} from "../../../../../store/selectors/GridConfig";
import { useSelector, useDispatch } from "react-redux";

const FrontWallGridPointsInput = () => {
  const dispatch = useDispatch();
  const floorInput = useSelector(selectWallInput);
  const gridIndex = useSelector(getGridIndex);
  const isWithinInputRange = (id) => !!floorInput && floorInput[id-1]?.length;
  const nextInputPoint = (id) => !!floorInput && floorInput[id-1] === null && gridIndex === id-1;

  const handleIndex = (event, index) => {
    dispatch(selectedGridIndex(index));
  };

  return (
    <div className="frontWall-GridPointsInput-container">
      <div className="frontWall-main-box">
        <CircularGridPoint
          id={5}
          onClick={(e) => handleIndex(e, 4)}
          style={{
            background: isWithinInputRange(5)
              ? "#00A524"
              : nextInputPoint(5)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 4 ? "solid" : "none",
            borderColor: gridIndex === 4 ? "red" : "",
          }}
        />
        <CircularGridPoint
          id={6}
          onClick={(e) => handleIndex(e, 5)}
          style={{
            background: isWithinInputRange(6)
              ? "#00A524"
              : nextInputPoint(6)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 5 ? "solid" : "none",
            borderColor: gridIndex === 5 ? "red" : "",
          }}
        />
        <CircularGridPoint
          id={1}
          onClick={(e) => handleIndex(e, 0)}
          style={{
            background: isWithinInputRange(1)
              ? "#00A524"
              : nextInputPoint(1)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 0 ? "solid" : "none",
            borderColor: gridIndex === 0 ? "red" : "",
          }}
        />
        <CircularGridPoint
          id={2}
          onClick={(e) => handleIndex(e, 1)}
          style={{
            background: isWithinInputRange(2)
              ? "#00A524"
              : nextInputPoint(2)
              ? "#FF7A00"
              : "",
            borderStyle: gridIndex === 1 ? "solid" : "none",
            borderColor: gridIndex === 1 ? "red" : "",
          }}
        />
        <div className="frontWall-top-box">
          <CircularGridPoint
            id={3}
            onClick={(e) => handleIndex(e, 2)}
            style={{
              background: isWithinInputRange(3)
                ? "#00A524"
                : nextInputPoint(3)
                ? "#FF7A00"
                : "",
              borderStyle: gridIndex === 2 ? "solid" : "none",
              borderColor: gridIndex === 2 ? "red" : "",
            }}
          />
          <CircularGridPoint
            id={4}
            onClick={(e) => handleIndex(e, 3)}
            style={{
              background: isWithinInputRange(4)
                ? "#00A524"
                : nextInputPoint(4)
                ? "#FF7A00"
                : "",
              borderStyle: gridIndex === 3 ? "solid" : "none",
              borderColor: gridIndex === 3 ? "red" : "",
            }}
          />
        </div>
        <div className="frontWall-bottom-box"></div>
      </div>
    </div>
  );
};

export default FrontWallGridPointsInput;
