import {
  GET_MATCHES_SUCCESS,
  SET_MATCH_LIST,
  SET_SELECTED_MATCH,
} from "../actionTypes/Match";

const DEFAULT_MATCH_STATE = {
  matchList: [],
  selectedMatch: null,
};

export const matchReducer = (state = DEFAULT_MATCH_STATE, action) => {
  switch (action.type) {
    case GET_MATCHES_SUCCESS:
      return {
        ...state,
        matchList: action.payload.data.results,
      };
    case SET_SELECTED_MATCH:
      return {
        ...state,
        selectedMatch: action.payload,
      };
    case SET_MATCH_LIST:
      return {
        ...state,
        matchList: action.payload,
      };
    default:
      return state;
  }
};
