import React from "react";
import { Modal, Checkbox, Button, Collapse, Popconfirm } from "antd";
import "./MatchEditModal.scss";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteMatch } from "../../../store/actions/Match";
import StyledField from "../../Admin/StyledField";
import { checkPermissions } from "../../../libs/permissions";
const { Panel } = Collapse;

const MatchEditModal = ({
  formik,
  matchModalOpen,
  closeModal,
  handleMatchAddAndUpdate,
  toAddNewMatch,
}) => {
  const dispatch = useDispatch();

  const deleteMatchHandler = () => {
    dispatch(deleteMatch(formik?.values?.id));
    closeModal(false);
  };

  const getFieldProps = (fieldName) => ({
    field: formik.getFieldProps(fieldName),
    meta: formik.getFieldMeta(fieldName),
    helpers: formik.getFieldHelpers(fieldName),
  });

  return (
    <div>
      <Modal
        title={formik.values?.title || "Add Match Details"}
        open={matchModalOpen}
        onCancel={() => closeModal(false)}
        centered
        width={700}
        footer={[
          <Button key="cancel" onClick={() => closeModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleMatchAddAndUpdate(formik.values)}
            disabled={!formik.isValid}
          >
            {toAddNewMatch ? "Create" : "Update"}
          </Button>,
        ]}
      >
        {/* Formik form */}
        <form onSubmit={formik.handleSubmit}>
          <div className="match-form">
            <div className="top-margin">
              <StyledField
                label="Title:"
                {...getFieldProps("title")}
                required
              />
            </div>
            <div className="top-margin">
              <StyledField label="URL" {...getFieldProps("url")} required />
            </div>
            <div className="players-name top-margin">
              <div className="player1-name">
                <StyledField
                  label="Player A:"
                  {...getFieldProps("player1")}
                  required
                />
              </div>
              <div className="player2-name">
                <StyledField
                  label="Player B:"
                  {...getFieldProps("player2")}
                  required
                />
              </div>
            </div>
            <div className="match-time top-margin">
              <div className="start-time">
                <StyledField
                  label="Start Time (HH:MM:SS):"
                  {...getFieldProps("start_time")}
                />
              </div>
              <div className="end-time">
                <StyledField
                  label="End Time (HH:MM:SS):"
                  {...getFieldProps("end_time")}
                />
              </div>
            </div>
            {!toAddNewMatch && (
              <div className="is-complete top-margin">
                <label htmlFor="is_complete">Completed </label>
                <Checkbox
                  checked={formik?.values?.is_complete}
                  {...formik.getFieldProps("is_complete")}
                />
              </div>
            )}
            {checkPermissions("canUpdateMatchStatus") && (
              <Collapse className="top-margin">
                <Panel header="Advanced Options" key="1">
                  <label className="sets-label">Sets Start Time&ensp;(HH:MM:SS.ms):</label>
                  <div className="sets top-margin">
                    <div className="set-inputs">
                      {[1, 2, 3, 4, 5].map((setNumber) => (
                        <div key={setNumber} className="set-input">
                          <StyledField
                            label={`Set ${setNumber}:`}
                            {...getFieldProps(`set_${setNumber}`)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  {!toAddNewMatch && <div className="delete-match-button">
                    <Popconfirm
                      placement="leftTop"
                      title="Delete the Match"
                      description="Are you sure to delete this Match?"
                      onConfirm={deleteMatchHandler}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        danger
                      >
                        Delete Match
                      </Button>
                    </Popconfirm>
                  </div>}
                </Panel>
              </Collapse>
            )}
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default MatchEditModal;
