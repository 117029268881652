import { Button, Collapse, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";
import { capitalizeFirstWord } from "../../../libs/utils";
import {
  clearGridData,
  clearShotData,
  patchShotData,
  replaceDataWithinRange,
  updateShotData,
} from "../../../store/actions/devActions";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import "./DevelopersModal.scss";

const Result = ({ status, statusMessage }) => {
  if (!status) return null;

  const iconMap = {
    success: <CheckCircleFilled />,
    error: <CloseCircleFilled />,
    loading: <LoadingOutlined />,
  };

  const messageClassMap = {
    success: "dev-success-message",
    error: "dev-error-message",
    loading: "dev-loading-message",
  };
  let limitedStatusMessage = capitalizeFirstWord(status);

  if (typeof statusMessage === "string" && statusMessage?.length < 250) {
    limitedStatusMessage = statusMessage;
  }

  const icon = iconMap[status] || null;
  const messageClass = messageClassMap[status] || "";

  return (
    <div className={messageClass}>
      {icon}
      {limitedStatusMessage}
    </div>
  );
};

const FileUpload = ({ message, onSubmit }) => {
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState({ status: null, data: null });
  const loading = status.status === "loading";

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", file);
    onSubmit(formData, setStatus);
  };
  return (
    <form>
      <Result status={status?.status} statusMessage={status?.data} />
      {message}
      <label class="label">
        <input
          type="file"
          name="file"
          className="file_input"
          onChange={handleFileChange}
          accept=".json"
        />
      </label>
      <Button
        type="primary"
        className="dev-form-button"
        onClick={handleSubmit}
        loading={loading}
        disabled={!file}
      >
        Submit
      </Button>
    </form>
  );
};

const ClearData = ({ message, confirmText, onConfirm }) => {
  const [status, setStatus] = useState({ status: null, data: null });
  return (
    <div>
      <Result status={status?.status} statusMessage={status?.data} />
      {message}
      <div className="dev-button-container">
        <ConfirmButton
          buttonText="Clear"
          confirmText={confirmText}
          onClick={() => onConfirm(setStatus)}
        />
      </div>
    </div>
  );
};
const DevelopersModal = ({ open, setOpen }) => {
  const { isOpen, additionalData: match } = open;
  const dispatch = useDispatch();

  const closeModal = () => setOpen({ isOpen: false, additionalData: null });

  const uploadActions = [
    {
      id: 1,
      label: "Upload shot data",
      action: updateShotData,
      message: "Upload shot data",
    },
    {
      id: 2,
      label: "Replace data within range",
      action: replaceDataWithinRange,
      message: "Replace data within range",
    },
    {
      id: 3,
      label: "Patch shot data",
      action: patchShotData,
      message: "Patch shot data",
    },
  ];

  const clearActions = [
    {
      id: 4,
      label: "Clear shot data",
      action: clearShotData,
      message: "Clear shot Data",
    },
    {
      id: 5,
      label: "Clear grid data",
      action: clearGridData,
      message: "Clear Grid Data",
    },
  ];

  const collapseItems = [
    ...uploadActions.map(({ label, action, id, message }) => ({
      key: id.toString(),
      label,
      children: (
        <FileUpload
          message={`${message} of ${match.title} match`}
          onSubmit={(data, setStatusHandler) =>
            dispatch(
              action(match, data, (status) => setStatusHandler(status, id))
            )
          }
        />
      ),
    })),
    ...clearActions.map(({ label, action, id, message }) => ({
      key: id.toString(),
      label,
      children: (
        <ClearData
          message={`${message} of ${match.title} match`}
          confirmText={`Are you sure to clear ${message.toLowerCase()} of ${
            match.title
          }`}
          onConfirm={(setStatusHandler) => {
            dispatch(action(match, (status) => setStatusHandler(status, id)));
          }}
        />
      ),
    })),
  ];

  return (
    <Modal
      title="Developer Options"
      open={isOpen}
      onCancel={closeModal}
      footer={null}
    >
      <Collapse accordion>
        {collapseItems.map(({ key, label, children }) => (
          <Collapse.Panel key={key} header={label}>
            {children}
          </Collapse.Panel>
        ))}
      </Collapse>
    </Modal>
  );
};

export default DevelopersModal;
