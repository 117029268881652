import { useEffect } from "react";

export const useKeyboardNavigation = ({
  isOpen,
  highlightedIndex,
  setHighlightedIndex,
  options,
  handleChange,
}) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isOpen) return;
      let newIndex = highlightedIndex;
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          newIndex =
            highlightedIndex === null
              ? options.length - 1
              : (highlightedIndex - 1 + options.length) % options.length;
          break;
        case "ArrowDown":
          e.preventDefault();
          newIndex =
            highlightedIndex === null
              ? 0
              : (highlightedIndex + 1) % options.length;
          break;
        case "Enter":
          if (highlightedIndex !== null)
            handleChange(options[highlightedIndex].value);
          break;
        default:
          return;
      }
      setHighlightedIndex(newIndex);
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, highlightedIndex, options, handleChange, setHighlightedIndex]);
};
