 /* eslint-disable */
import React, { useEffect } from "react";
import * as echarts from "echarts";
import { useSelector } from "react-redux";
import { selectSelectedVideo } from "../../../store/selectors/Match";
const CommonGraphs = React.memo(({ data,id,title,chartType,xLabelName,yLabelName,divWidth,divHeight}) => {
  const matchData = useSelector(selectSelectedVideo);
  // const seriesHandler = series => {
  //   return series.map((serie, index) => {
  //       if (index === series.length - 1) {
  //           return {
  //               ...serie,
  //               label: {
  //                   normal: {
  //                       show: true,
  //                       position: 'top',
  //                       formatter: params => {
  //                           let total_data = "";
  //                           let total =0;
                            
  //                           series.forEach(s => {
  //                             total+= s.data[params.dataIndex];
  //                             total_data += s.name + ' : ' + s.data[params.dataIndex]+ '\n'
  //                           })
  //                           return total > 0 ? total_data : ''
  //                       }
  //                   }
  //               }
  //           }
  //       } else {
  //           return serie
  //       }
  //   })
  // }
  useEffect(() => {

    // Initialize pie chart container
    const chartContainer = document.getElementById(id);
    const chart = echarts.init(chartContainer);
    const player1Data = [{ name: "Points", data: data[matchData?.player1] }];

    const player2Data = [{ name: "Points", data:  data[matchData?.player2] }];
    let options={};
    if(chartType === 'bar'){
   let xValues=[];
   let index=0;
   let legendArr= [];
   for (let key in data) {
    if (data.hasOwnProperty(key)) {
      xValues.push(key);
    }
    if(index === 0 && Object.keys(data[key]).length>0){
      for (let k in data[key]){
        legendArr.push(k)
      }
    }
    index++;
}
let colorArr=["#1fd137","#faf74d","#ff6259"];
let dataArr=[];
let showGraph=false;
if(legendArr?.length){
  for( let i=0;i<legendArr.length;i++){
    let colArr=[];
   let obj={name: legendArr[i],type: "bar",stack: "Ad",emphasis: {focus: "series"},color: colorArr[i]};
   for (let key in data) {
    if(data.hasOwnProperty(key) && Object.keys(data[key]).length>0){
        if(data[key][legendArr[i]] > 0)
          showGraph=true;
        colArr.push(data[key][legendArr[i]])
    }
  }
  obj={...obj,data:colArr}
  dataArr.push(obj);
  }
}

if(showGraph){
    //Define bar graph options
     options = {
      title: {
        text: title,
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        // Custom tooltip formatter to include percentage
        // formatter: function (params) {
        //   let tooltip = params[0].name + "<br/>";
        //   params.forEach(function (item) {
        //     let totalSum;
        //     if (item.seriesName === "Tight") {
        //       totalSum =
        //         qualityData?.Fast?.Tight +
        //         qualityData?.Fast?.Medium +
        //         qualityData?.Fast?.Loose;
        //     } else if (item.seriesName === "Medium") {
        //       totalSum =
        //         qualityData?.Medium?.Tight +
        //         qualityData?.Medium?.Medium +
        //         qualityData?.Medium?.Loose;
        //     } else if (item.seriesName === "Loose") {
        //       totalSum =
        //         qualityData?.Slow?.Tight +
        //         qualityData?.Slow?.Medium +
        //         qualityData?.Slow?.Loose;
        //     }
        //     tooltip +=
        //       item.seriesName +
        //       ": " +
        //       item.value +
        //       " (" +
        //       ((item.value / totalSum) * 100).toFixed(2) +
        //       "%)<br/>";
        //   });
        //   return tooltip;
        // },
      },
      legend: {
        data: legendArr,
        selectedMode:false,
        top: 25, // the size of title + margin
        left: 'center'
      },
      xAxis: {
        type: "category",
        data: xValues,
        name: xLabelName,
        axisLabel: {
          interval: 0,
          rotate: 35,
          formatter: (value) => {
            return value !== " " ? value : "Shot Types"; // Show "Shot Types" at the last category position
          },
        },
      },
      yAxis: {
        type: "value",
        name: yLabelName,
      },
      series: dataArr
      //series:seriesHandler(dataArr)
    };
  }
  else{
    var newtitle= "No data to display for " + title;
    options={
      title: {
        show: true,
        textStyle:{
          color:'grey',
          fontSize:20
        },
        text: newtitle,
        left: 'center',
        top: 'center'
      },
      legend: {
        show:true,
        data: legendArr,
        selectedMode:false,
        top: 25, // the size of title + margin
        left: 'center'
      },
    xAxis: {
        show: true,
        
        name: xLabelName
    },
    yAxis: {
        show: true,
        
        name: yLabelName
    },
    series: []
    }
  }
  }
  else if(chartType === 'pei'){

    // Define pie chart options
    let modifiedData = [];
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        modifiedData.push( { value: data[key], name: key,label: { show: false } });
      }
  }
 
    // Calculate total shots
    let totalShots = modifiedData.reduce(
      (acc, current) => acc + current.value,
      0
    );
    const getSeries = (dataName, chart) =>{
      var currentSeries = chart.getModel().getSeries()[0];
      var dataRow = currentSeries.get('data').filter(attr => attr.name === dataName)[0]
      return dataRow
    }
    // Define custom colors for slices
    const colors = ["#5470C6", "#91CC75", "#EE6666"];
     options = {
      title: {
        text: `${title} (Total Shots: ${totalShots})`,
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        selectedMode:false,
        formatter: name => `${name} ${getSeries(name, chart).value}`,
        textStyle: {
          fontWeight: 'bold'
        }
      },
      series: [
        {
          name: `${title} Type`,
          type: "pie",
          radius: "65%",
          center: ["50%", "60%"],
          data: modifiedData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          // Set custom colors
          color: colors,
        },
      ],
    };
  }
  else if(chartType === 'line'){
    options = {
      title: {
        text: title,
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        selectedMode:false,
        data: [`${matchData.player1} - Points`, `${matchData.player2} - Points`],
      },
      xAxis: {
        type: "category",
        name: xLabelName,
        boundaryGap: false,
        data: Array.from(
          { length: data[matchData.player1].length },
          (_, index) => index + 1
        ),
      },
      yAxis: {
        type: "value",
        name: yLabelName,
      },
      series: [
        ...player1Data.map((item) => ({
          name: `${matchData.player1} - ${item.name}`,
          type: "line",
          stack: matchData.player1,
          data: item.data,
        })),
        ...player2Data.map((item) => ({
          name: `${matchData.player2} - ${item.name}`,
          type: "line",
          stack: matchData.player2,
          data: item.data,
        })),
      ],
    };
  }
  else{
    let xValues=[];
   for (let key in data) {
    if (data.hasOwnProperty(key)) {
      xValues.push(key);
    }
}
    options = {
      title: {
        text: title,
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        // formatter: function (params) {
        //   const shotType = params[0].axisValue;
        //   const shotData = dataSets[shotType];
        //   const totalShots = shotData.Low + shotData.Medium + shotData.High;
        //   const lowPercentage = ((shotData.Low / totalShots) * 100).toFixed(2);

        //   return `
        //     ${shotType}<br/>
        //     Total Shots: ${totalShots}<br/>
        //     Weak Shots %: ${lowPercentage}% (${shotData.Low})
        //   `;
        // },
      },
      legend: {
        selectedMode:false,
        data: ["Shots", "Weak Shots"],
      },
      xAxis: {
        type: "category",
        name: xLabelName,
        data: xValues,
        axisLabel: {
          interval: 0,
          rotate: 35,
          formatter: (value) => {
            return value !== " " ? value : "Shot Types"; // Show "Shot Types" at the last category position
          },
        },
        axisPointer: {
          type: "shadow",
        },
      },
      yAxis: {
        type: "value",
        name: yLabelName,
      },
      series: [
        {
          name: "Shots",
          type: "bar",
          data: xValues.map((type) => {
            const { Low, Medium, High } = data[type];
            return Low + Medium + High;
          }),
          itemStyle: {
            color: "#306bff",
          },
        },
        {
          name: "Weak Shots",
          type: "line",
          data: xValues.map((type) => data[type].Low),
          itemStyle: {
            color: "#ffac38",
          },
        },
      ],
    };
  }
  options = {...options,backgroundColor:"#FFFFFF"};
    // Set options and render chart
    chart.setOption(options);

    // Cleanup function to dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      <div id={id} style={{ width: !!divWidth? divWidth:"50%", height: !!divHeight ? divHeight : "500px" }}></div>
    </>
  );
})

export default CommonGraphs;