import { combineReducers } from "redux";
import { matchReducer } from "./reducers/Match";
import { adminReducer } from "./reducers/Admin";
import {
  annotationListReducer,
  annotationReducer,
} from "./reducers/ReviewDetails";
import { annotationModal } from "./reducers/AnnotationModal";
import { loadingReducer, userReducer } from "./reducers/User";
import { settingsReducer } from "./reducers/Settings";
import { bulkSelectReducer } from "./reducers/BulkSelect";
import { GridConfigReducer } from "./reducers/GridConfig";
import { ChartReducer } from "./reducers/Charts";

export const rootReducer = combineReducers({
  matchReducer: matchReducer,
  annotationReducer: annotationReducer,
  annotationModal: annotationModal,
  userReducer: userReducer,
  annotationListReducer: annotationListReducer,
  loadingReducer: loadingReducer,
  settingsReducer: settingsReducer,
  bulkSelectReducer: bulkSelectReducer,
  GridConfigReducer: GridConfigReducer,
  ChartReducer: ChartReducer,
  admin:adminReducer
});
