import { ALL_USERS, SELECTED_USER ,CLEAR_SELECTED_USER} from "../actionTypes/Admin";


const initialState = {
    users: [],
    selected_user: null,
}

export const adminReducer = (state = initialState, action) => {
const {type, payload} = action;

switch (type){
    case ALL_USERS:
        return {
           ...state,
           users: payload
        }
    case SELECTED_USER:
        return {
           ...state,
            selected_user: payload
        }
    case CLEAR_SELECTED_USER:
        return {
           ...state,
            selected_user: null
        }
    default:
        return state;
}

}