export const selectGridViewEnable = (state) => {
  return state.GridConfigReducer.grid_enable;
};

export const selectWallInput = (state) => {
  return state.GridConfigReducer?.selected_grid?.wall_input;
};

export const selectFloorInput = (state) => {
  return state.GridConfigReducer?.selected_grid?.floor_input;
};

export const selectGridDuration = (state) => {
  return state.GridConfigReducer?.selected_grid?.duration;
};

export const selectGeneratedWallGrid = (state) => {
  return state.GridConfigReducer?.selected_grid?.wall_output;
};

export const selectGeneratedFloorGrid = (state) => {
  return state.GridConfigReducer?.selected_grid?.floor_output;
};

export const selectGeneratedBackwallGrid = (state) => {
  return state.GridConfigReducer?.selected_grid?.backwall_output;
};

export const selectSelectedTab = (state) => {
  return state.GridConfigReducer.selected_tab;
};

export const selectGridList = (state) => {
  return state.GridConfigReducer?.grid_list;
};

export const selectSelectedGrid = (state) => {
  return state.GridConfigReducer?.selected_grid;
};

export const selectInitialDimension = (state) => {
  return state.GridConfigReducer?.selected_grid?.resolution;
};

export const selectNewDimension = (state) => {
  return state.GridConfigReducer?.video_resolution;
};

export const selectHitOrBounce = (state) => {
  return state.GridConfigReducer?.hit_or_bounce;
};

export const getGridIndex = (state) => {
  return state.GridConfigReducer.selected_grid_index;
};
