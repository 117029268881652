import React from "react";
import "./TimeStampList.scss";
import TimeStampInput from "./TimeStampInput/TimeStampInput";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedGrid } from "../../../../store/selectors/GridConfig";
import { setGridDuration } from "../../../../store/actions/GridConfig";

const TimeStampList = (props) => {
  const dispatch = useDispatch();
  const durationArray = useSelector(selectSelectedGrid)?.duration || [];

  const { GridConfigPlayerRef } = props;
  const handleAddMore = () => {
    dispatch(setGridDuration([...durationArray, [0, 0]]));
  };
  const onClose = (index) => {
    const newDurationArray = [...durationArray];
    newDurationArray.splice(index, 1);
    dispatch(setGridDuration(newDurationArray));
  };
  return (
    <div className="timeStamp-container">
      <div className="timestamp-title">Select Timestamp</div>
      <div className="timestamp-list">
        {durationArray.map((timestamp, index) => (
          <TimeStampInput
            GridConfigPlayerRef={GridConfigPlayerRef}
            timestamp={timestamp}
            index={index}
            durationArray={durationArray}
            onClose={onClose}
          />
        ))}
      </div>
      <div className="timestamp-add-button" onClick={handleAddMore}>
        Add More
      </div>
    </div>
  );
};

export default TimeStampList;
