import React from "react";
import { Tabs } from "antd";
import "./GridTabs.scss";
import FloorGridPointsInput from "./FloorGridPointsInput/FloorGridPointsInput";
import FrontWallGridPointsInput from "./FrontWallGridPointsInput/FrontWallGridPointsInput";
import TimeStampList from "../TimeStamp/TimeStampList";
import { useDispatch } from "react-redux";
import {
  setSelectedTab,
  selectedGridIndex,
} from "../../../../store/actions/GridConfig";

const { TabPane } = Tabs;

const GridTabs = (props) => {
  const { GridConfigPlayerRef } = props;
  const dispatch = useDispatch();
  const onChange = (key) => {
    dispatch(setSelectedTab(key));
    dispatch(selectedGridIndex(null));
  };

  return (
    <div className="gridTabs-container">
      <Tabs defaultActiveKey="Floor" onChange={onChange} centered>
        <TabPane tab="Floor" key="floor">
          <div className="grid-points-container">
            <div className="grid-points-banner">
              Please select the grid point first
            </div>
            <FloorGridPointsInput />
          </div>
        </TabPane>
        <TabPane tab="Front Wall" key="front-wall">
          <div className="grid-points-container">
            <div className="grid-points-banner">
            Please select the grid point first
            </div>
            <FrontWallGridPointsInput />
          </div>
        </TabPane>
        <TabPane tab="Durations" key="durations">
          <TimeStampList GridConfigPlayerRef={GridConfigPlayerRef} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default GridTabs;
