export const selectDeltaTime = (state) => {
  return state.settingsReducer.deltaTime;
};

export const selectEndDeltaTime = (state) => {
  return state.settingsReducer.endDeltaTime;
};

export const isLoopEnable = (state) => {
  return state.settingsReducer.loop;
};

export const selectCurrentPage = (state) => {
  return state.settingsReducer.current_page;
};
