import { Col, Row, Button, Tooltip, Switch, message } from "antd";
import GridConfigTopNavigation from "./GridConfigTopNavigation";
import VideoPlayerSection from "./VideoPlayerSection";
import GridConfigSection from "./GridConfigSection/GridConfigSection";
import { InfoCircleFilled } from "@ant-design/icons";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGirdViewEnable,
  setSelectedGrid,
  setSelectedTab,
} from "../../store/actions/GridConfig";
import {
  selectGridViewEnable,
  selectSelectedGrid,
} from "../../store/selectors/GridConfig";
import { useNavigate } from "react-router-dom";
import { selectSelectedVideo } from "../../store/selectors/Match";
import { setCurrentPage } from "../../store/actions/Settings";

const GridConfigContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const GridConfigPlayerRef = useRef(null);
  const gridEnable = useSelector(selectGridViewEnable);
  const selectedGrid = useSelector(selectSelectedGrid);
  const selectedMatch = useSelector(selectSelectedVideo);

  const handleGridView = (checked) => {
    if (selectedGrid && selectedGrid.floor_output && selectedGrid.wall_output) {
      dispatch(setGirdViewEnable(checked));
    } else {
      message.warning("Grid View is not generated.");
    }
  };

  const handleNext = () => {
    navigate(`/match/${selectedMatch?.id}/review-details`);
    dispatch(setSelectedTab("floor"));
    dispatch(setSelectedGrid({}));
  };

  useEffect(() => {
    dispatch(setCurrentPage("config"));
    dispatch(setGirdViewEnable(false));
  }, [dispatch]);

  const handleVideoProgress = () => {};
  return (
    <div>
      <GridConfigTopNavigation />
      <div className="second-navigation">
        <h4 className="grid-selection-header">
          Grid Selection &nbsp;&nbsp;&nbsp;
          <Tooltip placement="bottom" title="Info text">
            <InfoCircleFilled />
          </Tooltip>
        </h4>
        <div className="gird-view-switch-container">
          Grid View
          <Switch
            className="gird-view-switch"
            checked={gridEnable}
            onChange={handleGridView}
          />
        </div>
        <Button className="next-button" onClick={handleNext}>
        Annotations
        </Button>
      </div>
      <div>
        <Row>
          <Col xs={18} sm={18} md={18} lg={18} xl={19}>
            <VideoPlayerSection
              videoPlayerRef={GridConfigPlayerRef}
              handleVideoProgress={handleVideoProgress}
            />
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={5}>
            <GridConfigSection GridConfigPlayerRef={GridConfigPlayerRef} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GridConfigContainer;
