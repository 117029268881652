export const SET_SELECTED_ANNOTATION = "SET_SELECTED_ANNOTATION";
export const SET_ANNOTATIONS = "SET_ANNOTATIONS";
export const SET_SHOT_LIST = "SET_SHOT_LIST";

export const SET_PERVIOUS_ANNOTATION = "SET_PERVIOUS_ANNOTATION";
export const SET_ANNOTATION_ROW_INDEX = "SET_ANNOTATION_ROW_INDEX";
export const SET_PARTIALLY_UPDATED_ANNOTATION =
  "SET_PARTIALLY_UPDATED_ANNOTATION";

export const UPDATE_ANNOTATION =
  "UPDATE_ANNOTATION";
