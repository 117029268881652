import {
  SET_USER_DETAILS,
  USER_LOGOUT,
  SET_VIDEOS_LISTING_LOADING,
  SET_ANNOTATIONS_LISTING_LOADING,
} from "../actionTypes/User";

const initialState = {
  user_details: {},
};

const loadingInitialState = {
  videos_listing_loading: false,
  annotations_listing_loading: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return { ...state, user_details: action.payload };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export const loadingReducer = (state = loadingInitialState, action) => {
  switch (action.type) {
    case SET_VIDEOS_LISTING_LOADING:
      return { ...state, videos_listing_loading: action.payload };
    case SET_ANNOTATIONS_LISTING_LOADING:
      return { ...state, annotations_listing_loading: action.payload };
    default:
      return state;
  }
};
