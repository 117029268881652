import React from "react";
import { Modal } from "antd";
import { useFormik } from "formik";
import { sharedProps, userFormValidationSchema } from "./adminConstant";
import { addNewUser } from "../../store/actions/Admin";
import StyledField from "./StyledField";
import { useDispatch } from "react-redux";

const UserFormModal = ({ modalState, onClose }) => {
  const dispatch = useDispatch();

  const { open, type, payload } = modalState;

  const toAddNewUser = type === "add";

  const additionalPropsForGroup = {
    ...sharedProps,
    placeholder: toAddNewUser ? "Add in new Group" : "Update Groups",
  };

  const handleFormSubmitting = (response) => {
    if (response.status === "success") {
      formik.setSubmitting(false)
      onClose();
    }
    else {
      formik.setSubmitting(false)
      formik.setErrors(response?.data)
    }
    
  }

  const formik = useFormik({
    initialValues: payload,
    validationSchema: userFormValidationSchema,
    onSubmit: (values) => {
      type === "add" && dispatch(addNewUser(values,handleFormSubmitting));
    },
  });
  const getFieldProps = (fieldName) => ({
    field: formik.getFieldProps(fieldName),
    meta: formik.getFieldMeta(fieldName),
    helpers: formik.getFieldHelpers(fieldName),
  });


  return (
    <Modal
      title={toAddNewUser ? "Add User" : "Edit User"}
      open={open}
      width={700}
      centered
      onCancel={onClose}
      onOk={formik.handleSubmit}
      okText={toAddNewUser ? "Create" : "Update"}
      okButtonProps={{
        loading: formik.isSubmitting,
        disabled: !formik.isValid || formik.isSubmitting,
      }}
    >
      <form onSubmit={formik.handleSubmit} className="user-form">
        <StyledField label="First Name" {...getFieldProps("first_name")} />
        <StyledField label="Last Name" {...getFieldProps("last_name")} />
        <StyledField label="Username" {...getFieldProps("username")} />
        <StyledField label="Email" {...getFieldProps("email")} />
        <StyledField
          label="Password"
          type="password"
          {...getFieldProps("password")}
        />
        <StyledField
          label="Groups"
          type="select"
          {...additionalPropsForGroup}
          {...getFieldProps("groups")}
        />
      </form>
    </Modal>
  );
};

export default UserFormModal;
