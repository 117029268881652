export const SelectVideoURL = (state) =>
  state.matchReducer.selectedMatch?.url || "";

export const selectMatchList = (state) => {
  return state.matchReducer.matchList;
};

export const selectSelectedVideo = (state) => {
  return state.matchReducer.selectedMatch;
};
