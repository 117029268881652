import { ANNOTATIONS_TAB } from "../../constants";
import { actionFactory } from "../../libs/actionFactory";
import {
  ADD_BULK_SELECT,
  DELETE_BULK_SELECT,
  CLEAR_BULK_SELECT,
  DELETE_MODAL_OPEN,
  SET_BULK_SELECT
} from "../actionTypes/BulkSelectionType";
import { listAnnotations } from "./ReviewDetails";
import { setAnnotationListingLoading } from "./User";

// Action Creators
export const addBulkSelect = (id, value) => ({
  type: ADD_BULK_SELECT,
  payload: {
    id,
    value,
  },
});

export const deleteBulkSelect = (id, valueToRemove) => ({
  type: DELETE_BULK_SELECT,
  payload: { id, valueToRemove },
});

export const setIsDeleteModalOpen = (open) => ({
  type: DELETE_MODAL_OPEN,
  payload: open,
});

export const clearBulkSelect = (id) => ({
  type: CLEAR_BULK_SELECT,
  payload: {
    id,
  },
});

export const setBulkSelect = (id, value) => ({
  type: SET_BULK_SELECT,
  payload: {
    id,
    value,
  },
});

export const deleteBulkSelected = (matchId, values, handleCloseDeleteModal) => {
  return actionFactory({
    api: `delete/api/matches/${matchId}/annotations/bulk_delete/`,
    actionBody: {
      body: {
        annotation_ids: values,
      },
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(clearBulkSelect(ANNOTATIONS_TAB));
          dispatch(setAnnotationListingLoading(true));
          dispatch(listAnnotations(matchId));
          handleCloseDeleteModal();
        })
        .catch((err) => {});
    },
  });
};
