 /* eslint-disable */
import "./MatchList.scss";
import { useDispatch } from "react-redux";
import { Button, Popover, Table, Tooltip, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setSelectedVideo, updateMatch } from "../../../store/actions/Match";
import { setAnnotationListingLoading } from "../../../store/actions/User";
import { useCallback, useEffect } from "react";
import {
  setAnnotationRowIndex,
  setSelectedAnnotation,
  setShotList,
} from "../../../store/actions/ReviewDetails";
import { Checkbox } from "antd";
import {
  CopyOutlined,
  EditOutlined,
  FileTextOutlined,
  LineOutlined,
} from "@ant-design/icons";
import StatusLabel from "../../common/StatusLabel/StatusLabel";
import { checkPermissions } from "../../../libs/permissions";
import ThreeDotMenu from "../../common/ThreeDotMenu/ThreeDotMenu";

const MatchList = ({ handleEditMatch, matchListItems, handleDevModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;

  const handleMatchClick = (match) => {
    dispatch(setSelectedVideo(match));
    dispatch(setAnnotationListingLoading(true));
    navigate(`/match/${match.id}/review-details`);
    dispatch(setSelectedAnnotation({}));
    dispatch(setAnnotationRowIndex(0));
    dispatch(setShotList([]));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []);

  const handleCopyMatchID = (matchID) => {
    navigator.clipboard.writeText(matchID); // Copy to clipboard
    message.success(`Match ID "${matchID}" copied to clipboard`); // Show success message
  };

  const handleStatusChange = (e, field, match) => {
    const updatedMatch = { id: match?.id, [field]: e.target.checked };
    dispatch(updateMatch(updatedMatch));
  };

  const VideoUrl = ({ link, match }) => (
    <div className="url-copy-button">
      <Tooltip title={link}>
        <div>{link.length > 20 ? link.slice(0, 20) + "..." : link}</div>
      </Tooltip>
      <Tooltip title="Copy">
        <Button
          type="text"
          onClick={() => {
            navigator.clipboard.writeText(link);
            message.success(`Match Url "${link}" copied to clipboard`);
          }}
          icon={<CopyOutlined />}
          shape="circle"
        ></Button>
      </Tooltip>
    </div>
  );

  const MatchStatus = ({ match }) => {
    return (
      <div className="match-status-popover">
        <div className="match-status-popover-title ">
          Update Match Status :{" "}
        </div>
        <Checkbox
          checked={match?.is_complete}
          onChange={(e) => handleStatusChange(e, "is_complete", match)}
        >
          Completed
        </Checkbox>
        <div></div>
        {checkPermissions("canUpdateReviewStatus") && (
          <Checkbox
            checked={match?.is_reviewed}
            onChange={(e) => handleStatusChange(e, "is_reviewed", match)}
          >
            Reviewed
          </Checkbox>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      render: (text, record) => (
        <div
          onClick={() => handleMatchClick(record)}
          className="ellipsis-text max-w-36 pointer link"
        >
          {text}
        </div>
      ),
    },
    {
      title: <div style={{ paddingLeft: "4px" }}>Video URL</div>,
      dataIndex: "url",
      key: "url",
      render: (text, record) => <VideoUrl link={text} match={record} />,
    },
    {
      title: "Match ID",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <div className="no-wrap match-id-col">
          <Tooltip title={text} style={{ width: "700px" }}>
            <div className="match-id-text">
              {" "}
              {text.length > 10 ? text.slice(0, 10) + "..." : text}
            </div>
          </Tooltip>
          <Button
            type="text"
            onClick={() => handleCopyMatchID(text)}
            icon={<CopyOutlined />}
            shape="circle"
          ></Button>
        </div>
      ),
    },
    {
      title: "Player A",
      dataIndex: "player1",
      key: "player1",
      render: (text) => <div className="ellipsis-text max-w-36 ">{text}</div>,
    },
    {
      title: "Player B",
      dataIndex: "player2",
      key: "player2",
      render: (text) => <div className="ellipsis-text max-w-36 ">{text}</div>,
    },
    {
      title: <div className="no-wrap">Created On</div>,
      key: "created_at",
      dataIndex: "created_at",
      render: (text) =>
        new Date(text).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }),
    },
    {
      title: "Match status",
      key: "is_complete",
      dataIndex: "is_complete",
      render: (text, record) => (
        <Popover
          className="match-status-popover"
          arrow={false}
          content={
            checkPermissions("canUpdateMatchStatus") ? (
              <MatchStatus match={record} />
            ) : null
          }
        >
          <>
            <StatusLabel
              status={
                record.is_reviewed
                  ? "Reviewed"
                  : text
                  ? "Completed"
                  : "Incomplete"
              }
            />
          </>
        </Popover>
      ),
    },
    {
      title: <div className="no-wrap">Visited %</div>,
      dataIndex: "percent_visited",
      key: "percent_visited",
      render: (Percentage) => {
        if (Percentage !== null && Percentage !== undefined) {
          return `${Percentage.toFixed(2)}%`;
        } else {
          return <LineOutlined style={{ marginLeft: "12px" }} />;
        }
      },
    },

    {
      title: "Action",
      fixed: "right",
      width: 50,
      render: (text, record) => (
        <>
          <ThreeDotMenu
            items={[
              {
                label: "Edit",
                className: "edit-button",
                icon: <EditOutlined />,
                onClick: () => handleEditMatch(record),
              },
              {
                label: "Dev-Tool",
                icon: <FileTextOutlined />,
                onClick: () =>
                  handleDevModal({ isOpen: true, additionalData: record }),
                protected: !checkPermissions("canPatchMatch"),
              },
            ]}
          />
        </>
      ),
    },
  ];

  const handlePageChange = useCallback(
    (newPage) => {
      setSearchParams((prevParams) => {
        const params = new URLSearchParams(prevParams);
        params.set("page", Math.max(1, newPage));
        return params;
      });
    },
    [setSearchParams]
  );

  return (
    <>
      <div className="match-table">
        <Table
          columns={columns}
          dataSource={matchListItems}
          pagination={{
            pageSize: 8,
            current: page,
            onChange: handlePageChange,
          }}
          scroll={{
            x: true,
          }}
        />
      </div>
    </>
  );
};

export default MatchList;
