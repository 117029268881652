import { Button, Modal, Select, Form, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { matchOrSetItems, tableItems } from "./CourtChart/FilterConstants";
import { typesOfCharts } from "../../libs/constants";
import { useState } from "react";
import "./ChartFilterPanel.scss";


// StyledField component for rendering filter fields
const StyledField = ({
  label,
  rules,
  onSelectAll,
  onClear,
  allSelected,
  ...props
}) => {
  return (
    <Form.Item label={label} rules={rules} name={props?.name}>
      <div className="styled-field-container">
        <Select
          placeholder={label}
          {...props}
          className="select-field"
          maxTagCount={allSelected ? 0 : "responsive"}
          maxTagPlaceholder={
            allSelected
              ? (omittedValues) => (
                  <Tooltip
                    title={omittedValues.map(({ label }) => label).join(", ")}
                  >
                    <span className="select-tag">
                      All {label.split(" ").slice(1).join(" ")}
                    </span>
                  </Tooltip>
                )
              : undefined
          }
        />

        {!allSelected && (
          <Button onClick={onSelectAll} className="select-all-button">
            Select All
          </Button>
        )}
        {allSelected && (
          <Button onClick={onClear} className="clear-button">
            Clear
          </Button>
        )}
      </div>
    </Form.Item>
  );
};

// Shared properties for all Select components
const sharedProps = {
  mode: "multiple",
  style: { minWidth: 200, maxWidth: 600 },
};

// Function to map options to the format needed by Select
const optionsMapper = (options) =>
  options.map((props) => ({ ...props, value: props.key }));

const ChartFilterPanel = ({
  open,
  playerStats,
  selectedFilter,
  setSelectedFilter,
  viewHandler,
}) => {
  const [error, setError] = useState("");

  // Handle form submission
  const handleSubmit = () => {
    const { set, charts, courtArea } = selectedFilter;
    if (set.length === 0 || (charts.length === 0 && courtArea.length === 0)) {
      setError("Please make sure to select the required options.");
    } else {
      viewHandler("preview");
    }
  };

  // Extract sets from playerStats and map options for each filter
  const sets = playerStats?.analysed_data?.set_data;
  const matchOrSetOptions = optionsMapper(matchOrSetItems).slice(
    0,
    sets?.length + 1
  );
  const courtsAreaOptions = optionsMapper(tableItems);
  const chartsOptions = typesOfCharts;

  // Handle change in selection for a filter
  const handleChange = (type, value) => {
    setSelectedFilter((prev) => ({ ...prev, [type]: value }));
    setError("");
  };

  // Handle Select All action for a filter
  const handleSelectAll = (type, options) => {
    const allValues = options.map((option) => option.value);
    setSelectedFilter((prev) => ({ ...prev, [type]: allValues }));
    setError("");
  };

  // Handle Clear action for a filter
  const handleClear = (type) => {
    setSelectedFilter((prev) => ({ ...prev, [type]: [] }));
    setError("");
  };

  // Handle Reset action for the form
  const handleReset = () => {
    setSelectedFilter({
      set: [],
      charts: [],
      courtArea: [],
    });
    setError("");
  };

  // Create configuration for each filter field
  const createFilterConfig = (name, label, options) => ({
    name,
    label,
    type: "select",
    options,
    rules: [{ required: true }],
    value: selectedFilter?.[name] || [],
    onChange: (value) => handleChange(name, value),
    onSelectAll: () => handleSelectAll(name, options),
    onClear: () => handleClear(name),
    allSelected: selectedFilter?.[name]?.length === options.length,
    ...sharedProps,
  });

  // Define all filters using createFilterConfig
  const filters = [
    createFilterConfig("set", "Select Sets", matchOrSetOptions),
    createFilterConfig("charts", "Select Charts", chartsOptions),
    createFilterConfig("courtArea", "Select Court Area", courtsAreaOptions),
  ];

  return (
    <Modal
      title="Chart Filter"
      className="chart-filter-modal"
      open={open}
      maskClosable={false}
      footer={null}
      onCancel={() => viewHandler("")}
    >
      <Form layout="vertical" className="chart-filter">
        {/* Display error message if any */}
        {error && (
          <div className="error-message">
            <InfoCircleFilled /> {error}
          </div>
        )}
        {/* Render each filter field */}
        {filters.map((props, index) => (
          <StyledField key={index} {...props} />
        ))}
        {/* Buttons for Reset and Show Preview */}
        <div className="button-container">
          <Button onClick={handleReset}>Reset</Button>
          <Button type="primary" onClick={handleSubmit}>
            Show Preview
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChartFilterPanel;
