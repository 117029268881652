 /* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { Radio } from "antd";
import "./ScoreGraph.scss";
import { useSelector } from "react-redux";
import { selectSelectedVideo } from "../../../store/selectors/Match";

const ScoreGraph = ({ data, disableSetsButton }) => {
  const match = useSelector(selectSelectedVideo);
  const chartRef = useRef(null);

  const [selectedSet, setSelectedSet] = useState(
    data ? data[0].score_progression : null
  );

  const onSetChange = (value) => {
    if (data && data.length > value && data[value].score_progression) {
      setSelectedSet(data[value].score_progression);
    } else {
      setSelectedSet({
        [match?.player1]: [],
        [match?.player2]: [],
      });
    }
  };

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const player1Data = [{ name: "Points", data: selectedSet[match?.player1] }];

    const player2Data = [{ name: "Points", data: selectedSet[match?.player2] }];

    const options = {
      title: {
        text: "Point Progression",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: [`${match.player1} - Points`, `${match.player2} - Points`],
      },
      xAxis: {
        type: "category",
        name: "Rally",
        boundaryGap: false,
        data: Array.from(
          { length: selectedSet[match.player1].length },
          (_, index) => index + 1
        ),
      },
      yAxis: {
        type: "value",
        name: "Points",
      },
      series: [
        ...player1Data.map((item) => ({
          name: `${match.player1} - ${item.name}`,
          type: "line",
          stack: match.player1,
          data: item.data,
        })),
        ...player2Data.map((item) => ({
          name: `${match.player2} - ${item.name}`,
          type: "line",
          stack: match.player2,
          data: item.data,
        })),
      ],
    };

    chart.setOption(options);

    return () => {
      chart.dispose(); // Clean up chart when component unmounts
    };
  }, [selectedSet]);

  return (
    <>
      <div className="set-radio-buttons">
        <Radio.Group
          onChange={(e) => onSetChange(e.target.value)}
          defaultValue={0}
        >
          <Radio.Button value={0} disabled={disableSetsButton[0]}>
            Set 1
          </Radio.Button>
          <Radio.Button value={1} disabled={disableSetsButton[1]}>
            Set 2
          </Radio.Button>
          <Radio.Button value={2} disabled={disableSetsButton[2]}>
            Set 3
          </Radio.Button>
          <Radio.Button value={3} disabled={disableSetsButton[3]}>
            Set 4
          </Radio.Button>
          <Radio.Button value={4} disabled={disableSetsButton[4]}>
            Set 5
          </Radio.Button>
        </Radio.Group>
      </div>
      <div
        ref={chartRef}
        className="score-graph"
        style={{ width: "100%", height: "500px" }}
      />
    </>
  );
};

export default ScoreGraph;
