import { message } from "antd";
import { actionFactory } from "../../libs/actionFactory";
import {
  DELETE_GRIDS,
  GENERATE_GRIDS,
  GET_GRIDS,
  GRID_SELECTOR_ENABLE,
  SELECTED_GRID,
  SELECTED_GRID_POINT_INDEX,
  SELECTED_TAB,
  SET_FLOOR_INPUT,
  SET_GRID_DURATION,
  SET_GRID_LIST,
  SET_HTI_OR_BOUNCE,
  SET_NEW_GRID,
  SET_VIDEO_RESOLUTION,
  SET_WALL_INPUT,
  UPDATE_GRIDS,
} from "../actionTypes/GridConfig";

export const setGirdViewEnable = (enable) => (dispatch) => {
  dispatch({
    type: GRID_SELECTOR_ENABLE,
    payload: enable,
  });
};

export const setGirdConfigVideoResolution = (obj) => (dispatch) => {
  dispatch({
    type: SET_VIDEO_RESOLUTION,
    payload: obj,
  });
};

export const setWallCoordinatesInput = (obj) => (dispatch) => {
  dispatch({
    type: SET_WALL_INPUT,
    payload: obj,
  });
};

export const setFloorCoordinatesInput = (obj) => (dispatch) => {
  dispatch({
    type: SET_FLOOR_INPUT,
    payload: obj,
  });
};

export const setGridDuration = (duration) => (dispatch) => {
  dispatch({
    type: SET_GRID_DURATION,
    payload: duration,
  });
};

export const setSelectedTab = (tab) => (dispatch) => {
  dispatch({
    type: SELECTED_TAB,
    payload: tab,
  });
};

export const setSelectedGrid = (grid) => (dispatch) => {
  dispatch({
    type: SELECTED_GRID,
    payload: grid,
  });
};

export const setNewGrid = (grid) => (dispatch) => {
  dispatch({
    type: SET_NEW_GRID,
    payload: grid,
  });
};

export const setGridList = (grid) => (dispatch) => {
  dispatch({
    type: SET_GRID_LIST,
    payload: grid,
  });
};

export const generateGridCoordinates = (matchId, gridCoordinates) => {
  return actionFactory({
    api: `post/api/matches/${matchId}/grids/generate-grids/`,
    actionBody: {
      body: gridCoordinates,
    },
    failureToast: false,
    actionBase: GENERATE_GRIDS,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const data = resp["data"].data;
            dispatch(getGridList(data.match));
            message.success("Grid generated successfully.");
          }
        })
        .catch((err) => {
          message.error("Fail to generate grid.");
        });
    },
  });
};

export const updateGenerateGridCoordinates = (grid) => {
  return actionFactory({
    api: `patch/api/matches/${grid.match}/grids/${grid.id}/`,
    actionBody: {
      body: grid,
    },
    failureToast: false,
    actionBase: UPDATE_GRIDS,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const data = resp["data"].data;
            dispatch(getGridList(data.match));
            message.success("Grid saved successfully.");
          }
        })
        .catch((err) => {
          message.error("Fail to save grid.");
        });
    },
  });
};

export const getGridList = (id) => {
  return actionFactory({
    api: `get/api/matches/${id}/grids/`,
    failureToast: false,
    actionBase: GET_GRIDS,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
          }
        })
        .catch((err) => {
          message.error(err?.response?.data?.status?.status_message || err?.response?.data?.detail || "Something went wrong");
        });
    },
  });
};

export const deleteGridCoordinates = (grid) => {
  return actionFactory({
    api: `delete/api/matches/${grid?.match}/grids/${grid?.id}/`,
    failureToast: false,
    actionBase: DELETE_GRIDS,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(setSelectedGrid({}));
          dispatch(getGridList(grid.match));
          message.success("Grid deleted successfully.");
        })
        .catch((err) => {
          dispatch(setSelectedGrid({}));
          dispatch(getGridList(grid?.match));
          message.error("Fail to delete grid.");
        });
    },
  });
};

export const setHitOrBounce = (string) => (dispatch) => {
  dispatch({
    type: SET_HTI_OR_BOUNCE,
    payload: string,
  });
};

export const selectedGridIndex = (value) => (dispatch) => {
  dispatch({
    type: SELECTED_GRID_POINT_INDEX,
    payload: value,
  });
};
