import { actionFactory } from "../../libs/actionFactory";
import {
  SET_SELECTED_ANNOTATION,
  SET_ANNOTATIONS,
  SET_PERVIOUS_ANNOTATION,
  SET_PARTIALLY_UPDATED_ANNOTATION,
  SET_ANNOTATION_ROW_INDEX,
  SET_SHOT_LIST,
  UPDATE_ANNOTATION,
} from "../actionTypes/ReviewDetails";
import { setAnnotationListingLoading } from "./User";
import { message } from "antd";
import { setIsDeleteModalOpen } from "./bulkSelectActions";

export const setSelectedAnnotation = (selectedAnnotation) => {
  return {
    type: SET_SELECTED_ANNOTATION,
    payload: selectedAnnotation,
  };
};

export const setAnnotations = (annotations) => {
  return {
    type: SET_ANNOTATIONS,
    payload: annotations,
  };
};

export const setShotList = (shotList) => {
  return {
    type: SET_SHOT_LIST,
    payload: shotList,
  };
};

export const UpdateShotList = (annotation) => {
  return {
    type: UPDATE_ANNOTATION,
    payload: annotation,
  };
};

export const setPreviousAnnotation = (annotation) => {
  return {
    type: SET_PERVIOUS_ANNOTATION,
    payload: annotation,
  };
};

export const addAnnotation = (matchId, annotation) => {
  return actionFactory({
    api: `post/api/matches/${matchId}/annotations/`,
    actionBody: {
      body: annotation,
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          const data = resp["data"].data;
          dispatch(setSelectedAnnotation(data));
          dispatch(setAnnotationListingLoading(true));
          dispatch(listAnnotations(matchId, "", true));
        })
        .catch((err) => {});
    },
  });
};

export const listAnnotations = (matchId, filters = "", newAdded = false) => {
  return actionFactory({
    api: `get/api/matches/${matchId}/annotations/?${filters}`,
    failureToast: false,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          const data = resp.data?.data?.results || resp.data?.data || [];
          dispatch(setAnnotations(data));
          !newAdded && dispatch(setSelectedAnnotation(data[0]));
          dispatch(setAnnotationListingLoading(false));
        })
        .catch((err) => {
          dispatch(setAnnotationListingLoading(false));
          message.error("Fail to get the Shot list.");
        });
    },
  });
};

export const setPartiallyUpdatedAnnotation = (annotation) => {
  return {
    type: SET_PARTIALLY_UPDATED_ANNOTATION,
    payload: annotation,
  };
};

export const setAnnotationRowIndex = (annotation) => {
  return {
    type: SET_ANNOTATION_ROW_INDEX,
    payload: annotation,
  };
};

export const updateAnnotation = (
  annotation,
  partialAnnotationChanges,
  updateAnnotationRowIndex,
  callback,
  showUpdateMessage = true
) => {
  return actionFactory({
    api: `patch/api/matches/${annotation?.match}/annotations/${annotation?.id}/`,
    actionBody: {
      body: { ...partialAnnotationChanges, start_time: annotation?.start_time },
    },
    failureToast: false,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          showUpdateMessage && message.success("Annotation is updated.");
          const data = resp.data.data;
          // dispatch(setSelectedAnnotation(data));
          callback(data, updateAnnotationRowIndex);
          dispatch(setPartiallyUpdatedAnnotation(null));
        })
        .catch((err) => {
          message.error("Annotation is not updated.");
          dispatch(setPartiallyUpdatedAnnotation(null));
          dispatch(setAnnotationListingLoading(false));
          // message.warning("Fail to update Annotation.");
        });
    },
  });
};

export const getAnnotation = (id) => {
  return actionFactory({
    api: `get/api/annotations/${id}/`,
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(setSelectedAnnotation(resp["data"].data));
          }
        })
        .catch((err) => {});
    },
  });
};

export const deleteAnnotation = (annotation) => {
  return actionFactory({
    api: `delete/api/matches/${annotation.match}/annotations/${annotation.id}`,
    failureToast: false,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(setIsDeleteModalOpen(false));
          dispatch(setAnnotationListingLoading(true));
          dispatch(listAnnotations(annotation.match));
          if (resp["data"].status.status_type === "FAILED") {
            dispatch(setIsDeleteModalOpen(false));
            dispatch(listAnnotations(annotation.match));
          }
        })
        .catch((err) => {
          dispatch(setIsDeleteModalOpen(false));
          dispatch(listAnnotations(annotation.match));
        });
    },
  });
};

export const downloadCsvFile = (match_id, successcallback = () => {}) => {
  return actionFactory({
    api: `get/api/matches/${match_id}/generate-csv/`,
    failureToast: false,
    blobResponseType: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp?.status === 200) {
            const contentDisposition = resp.headers["content-disposition"];
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            let filename = "rally-vision-report.xlsx"; // default filename

            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
            message.success("File downloaded successfully!");
            successcallback(resp.data);
            const file = new Blob([resp.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const fileUrl = window.URL.createObjectURL(file);
            const downloadLink = document.createElement("a");
            downloadLink.href = fileUrl;
            downloadLink.setAttribute("download", filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        })
        .catch((err) => {
          successcallback(err);
          message.error("File download failed!");
        });
    },
  });
};

export const bulkUpdateAnnotation = (matchId, updates) => {
  return actionFactory({
    api: `patch/api/matches/${matchId}/annotations/update_status/`,
    actionBody: {
      body: updates,
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch(listAnnotations(matchId, "", false));
          message.success("Annotations is updated.");
        })
        .catch((err) => {
          dispatch(listAnnotations(matchId, "", false));
        });
    },
  });
};

export const reMapPointId = (matchId, updates) => {
  return actionFactory({
    api: `patch/api/matches/${matchId}/annotations/map_point_id/`,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          dispatch(listAnnotations(matchId, "", false));
        })
        .catch((err) => {
          dispatch(setAnnotationListingLoading(false));
          message.error(
            err?.response?.data?.status?.status_message ||
            err?.response?.data?.detail ||
              "Something went Wrong"
          );
        });
    },
  });
};
