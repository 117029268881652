import React from "react";
import { Header, Menu } from "semantic-ui-react";
import "./TopNavigation.scss";
import { Link, } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/selectors/User";
import UserProfile from "../ReviewPage/ReviewTopNavigation/UserProfile";

const TopNavigation = () => {

  const user = useSelector(selectUser)


  return (
    <>
      <Menu inverted attached="top" size="tiny">
        <Menu.Item className="menu-header">
          <Link to={"/matches"}>
            <Header as={"h2"} className="menu-header">
              Rally Vision
            </Header>
          </Link>
        </Menu.Item>

        <Menu.Item position="right" className="power-icon">
          <UserProfile />
        </Menu.Item>
        {user?.is_staff &&
          <Menu.Item className="admin-icon">
            <Link to="/admin/users/">Admin</Link>
          </Menu.Item>}
      </Menu>
    </>
  );
};

export default TopNavigation;
